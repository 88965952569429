import moment from 'moment';

const validate = (values) => {
  const errors = {};

  if (!values.images || values.images.length === 0) {
    errors.images = 'この項目は必須です。';
  }
  if (!values.eventTitle) {
    errors.eventTitle = 'この項目は必須です。';
  }
  if (!values.position) {
    errors.position = 'この項目は必須です。';
  }
  if (!values.eventArea) {
    errors.eventArea = 'この項目は必須です。';
  }
  if (!values.restaurantName) {
    errors.restaurantName = 'この項目は必須です。';
  }
  if (!values.recruitmentNumbers) {
    errors.recruitmentNumbers = 'この項目は必須です。';
  }
  if (!values.eventDetail) {
    errors.eventDetail = 'この項目は必須です。';
  }
  if (!values.idealCandidate) {
    errors.idealCandidate = 'この項目は必須です。';
  }
  if (!values.expectedSkill) {
    errors.expectedSkill = 'この項目は必須です。';
  }
  if (!values.expectedDate) {
    errors.expectedDate = 'この項目は必須です。';
  } else if (moment().diff(moment(values.expectedDate).endOf('month'), 'days') > 0) {
    errors.expectedDate = '無効な日付です';
  }

  return errors;
};

export default validate;
