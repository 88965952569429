import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
const { Footer } = Layout;

class CustomFooter extends React.PureComponent {
  render() {
    if (this.props.location && this.props.location.pathname.includes('/event/create')) {
      return null;
    }
    
    return (
      <Footer style={{ textAlign: 'center' }}>
        <div className="custom-layout-footer">
          <a href="/">Terms of Service </a>
          <a href="/">Privacy Policy</a>
          <a href="/">Help</a>
        </div>
      </Footer>
    );
  }
}

CustomFooter.propTypes = {
  location: PropTypes.any
};

export default CustomFooter;