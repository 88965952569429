import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import BigPageHeader from 'components/PageHeader';
import CreateSeekerForm from './CreateSeekerForm';
import './styles.scss';

class CreateSeekerPage extends React.PureComponent {
  handleSubmit = async values => {
    this.props.createSeeker(values);
  }

  render() {
    const pageTitle = 'Tenshoku Dashboard | Create Seeker';

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <BigPageHeader
          title='アカウントを作成する'
          goBack={true}
        />

        <div id="create-seeker-page">
          <CreateSeekerForm 
            onSubmit={this.handleSubmit} 
            {...this.props} 
          />
        </div>
      </React.Fragment>
    );
  }
}

CreateSeekerPage.propTypes = {
  createSeeker: PropTypes.func
};

export default CreateSeekerPage;