import { all } from 'redux-saga/effects';
import watchAuth from 'providers/AuthProvider/saga';
import watchPopup from 'providers/PopupProvider/saga';
import watchCompany from 'providers/CompanyProvider/saga';
import watchSeeker from 'providers/SeekerProvider/saga';
import watchEvent from 'providers/EventProvider/saga';
import watchRequest from 'providers/RequestProvider/saga';
import watchResource from 'providers/ResourceProvider/saga';
import watchMessage from 'providers/MessageProvider/saga';
import watchNotification from 'providers/NotificationProvider/saga';
import watchPlan from 'providers/PlanProvider/saga';

export default function* rootSaga() {
  yield all([
    watchCompany(),
    watchSeeker(),
    watchEvent(),
    watchRequest(),
    watchAuth(),
    watchPopup(),
    watchResource(),
    watchMessage(),
    watchNotification(),
    watchPlan()
  ]);
}