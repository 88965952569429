import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WhiteLockIcon from 'assets/icons/white-lock.svg';
import { SetPopup } from 'providers/PopupProvider/actions';


import './style.scss';

class LockEventButton extends React.PureComponent {
  render() {
    const { disabled, openPopup, noneJoined, active } = this.props;
    return (
      <div className='button-container'>
        <button 
          onClick={!disabled && !noneJoined ? openPopup : undefined}
          className={`${active && 'active'}`}
        >
          <div className='lock-button'>
            <img
              alt='lock-icon'
              className='lock-icon'
              src={WhiteLockIcon}/>
            <p className='lock-button-text'>イベントをロックする</p>
          </div>
        </button>
        {disabled &&
          <div className='disabled-message'>イベントをロックする前に[応募者一覧]を確認してください</div>
        }
        {noneJoined &&
          <div className='disabled-message'>ロックには1人以上の参加確定者が必要です</div>
        }
      </div>
    );
  }
}

LockEventButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  noneJoined: PropTypes.bool,
  openPopup: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  openPopup: () => dispatch(SetPopup({name: 'lockEventPopup', show: true}))
});

export default connect(null, mapDispatchToProps)(LockEventButton);