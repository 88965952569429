import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form } from 'antd';

import Label from '../Label';

const RadioButton = (props) => {
  const {
    label, input, options = [], required = false, className = '',
    meta: { touched, invalid, error }
  } = props;

  return (
    <Form.Item
      className={className}
      label={<Label label={label} required={required} />}
      colon={false}
      help={touched && invalid && error}
    >
      <Checkbox.Group onChange={input.onChange} value={input.value} {...props}>
        {options.map((option) => (<Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>))}
      </Checkbox.Group>
    </Form.Item>
  );
};

RadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.any,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  meta: PropTypes.object.isRequired
};

export default RadioButton;
