import {
  FETCH_REQUEST_LIST,
  FETCH_REQUEST_LIST_SUCCESS,
  FETCH_REQUEST_PROFILE,
  FETCH_REQUEST_PROFILE_SUCCESS,
  SET_LOADING_SEARCH_REQUEST,
  UNSET_REQUEST_PROFILE,

  DENY_TENSHOKUSHA,
  DENY_TENSHOKUSHA_SUCCESS,
  DENY_TENSHOKUSHA_FAILURE,

  ACCEPT_TENSHOKUSHA,
  ACCEPT_TENSHOKUSHA_SUCCESS,
  ACCEPT_TENSHOKUSHA_FAILURE
} from './constants';

export function fetchRequestList(payload = {}) {
  return {type: FETCH_REQUEST_LIST, payload};
}

export function fetchRequestListSuccess(payload = {}) {
  return {type: FETCH_REQUEST_LIST_SUCCESS, payload};
}

export function fetchRequestProfile(payload = {}) {
  return {type: FETCH_REQUEST_PROFILE, payload};
}

export function fetchRequestProfileSuccess(payload = {}) {
  return {type: FETCH_REQUEST_PROFILE_SUCCESS, payload};
}

export function setSearchRequestLoading(payload = {}) {
  return {type: SET_LOADING_SEARCH_REQUEST, payload};
}

export function unsetRequestProfile() {
  return {type: UNSET_REQUEST_PROFILE};
}

export function denyTenshokusha(payload) {
  return {
    type: DENY_TENSHOKUSHA,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function denyTenshokushaSuccess({ meta, payload }) {
  return {
    type: DENY_TENSHOKUSHA_SUCCESS,
    payload,
    meta
  };
}

export function denyTenshokushaFailure({ meta, payload }) {
  return {
    type: DENY_TENSHOKUSHA_FAILURE,
    payload,
    meta,
    error: true
  };
}

export function acceptTenshokusha(payload) {
  return {
    type: ACCEPT_TENSHOKUSHA,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function acceptTenshokushaSuccess({ meta, payload }) {
  return {
    type: ACCEPT_TENSHOKUSHA_SUCCESS,
    payload,
    meta
  };
}

export function acceptTenshokushaFailure({ meta, payload }) {
  return {
    type: ACCEPT_TENSHOKUSHA_FAILURE,
    payload,
    meta,
    error: true
  };
}
