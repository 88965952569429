import request from 'utils/request';
import { API_URL } from './config';
import moment from 'moment';

export async function uploadFile(file) {
  if (file) {
    try {
      return await request(`${API_URL}/files/ar.jpeg`, file, 'POST', {
        headers: { 'Content-Type': file.type }
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  return null;
}

export function createImage({ imageObject, type, name }) {
  return request(`${API_URL}/classes/Image`, {
    file: { ...imageObject, __type: 'File' },
    type,
    name
  }, 'POST');
}

export function isToday(date) {
  if (moment(date).isSame(moment(), 'd')) {
    return true;
  }

  return false;
}