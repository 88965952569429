import _isEmpty from 'lodash/isEmpty';

const validate = (values) => {
  const errors = {};
  if (!values.allSeeker) {
    if (_isEmpty(values.interestingJob)) {
      errors.interestingJob = 'この項目は必須です。';
    }
  }
  if (!values.eventId) {
    errors.eventId = 'この項目は必須です。';
  } else {
    if (!values.eventId.trim()) {
      errors.eventId = 'この項目は必須です。';
    }
  }
  if (!values.content) {
    errors.content = 'この項目は必須です。';
  } else {
    if (!values.content.trim()) {
      errors.content = 'この項目は必須です。';
    }
  }
  return errors;
};

export default validate;
