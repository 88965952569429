import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import _ from 'lodash';

class popup extends Component {  
  submit = async() => {
    const confirm = _.get(this.props.data, 'confirm', () => {});
    await confirm();
    this.props.closePopup();
  }

  render() {
    const title = _.get(this.props.data, 'title', 'Confirm Popup');
    const content = _.get(this.props.data, 'content', '');
    const okText = _.get(this.props.data, 'okText', 'OK');
    const cancelText = _.get(this.props.data, 'cancelText', 'Cancel');
    const okType = _.get(this.props.data, 'okType', 'primary');
    const okButtonProps = _.get(this.props.data, 'okButtonProps', {});
    const cancelButtonProps = _.get(this.props.data, 'cancelButtonProps', {});

    return (
      <Modal
        title={title}
        visible={!!this.props.data}
        destroyOnClose={true}
        onCancel={this.props.closePopup}
        onOk={this.submit}
        okText={okText}
        cancelText={cancelText}
        cancelButtonProps={cancelButtonProps}
        okType={okType}
        okButtonProps={okButtonProps}
        centered
        maskClosable={false}
      >
        <div>{content}</div>
      </Modal>
    );
  }
}

popup.propTypes = {
  data: PropTypes.any,
  closePopup: PropTypes.func
};

export default popup;