import {
  FETCH_SEEKER_LIST,
  FETCH_SEEKER_LIST_SUCCESS,
  FETCH_SEEKER_PROFILE,
  FETCH_SEEKER_PROFILE_SUCCESS,
  CREATE_SEEKER,
  CREATE_SEEKER_SUCCESS,
  SET_LOADING_SEARCH_SEEKER,
  UNSET_SEEKER_PROFILE
} from './constants';

export function fetchSeekerList(payload = {}) {
  return {type: FETCH_SEEKER_LIST, payload};
}

export function fetchSeekerListSuccess(payload = {}) {
  return {type: FETCH_SEEKER_LIST_SUCCESS, payload};
}

export function fetchSeekerProfile(payload = {}) {
  return {type: FETCH_SEEKER_PROFILE, payload};
}

export function fetchSeekerProfileSuccess(payload = {}) {
  return {type: FETCH_SEEKER_PROFILE_SUCCESS, payload};
}

export function createSeeker(payload = {}) {
  return {type: CREATE_SEEKER, payload};
}

export function createSeekerSuccess(payload = {}) {
  return {type: CREATE_SEEKER_SUCCESS, payload};
}

export function setSearchSeekerLoading(payload = {}) {
  return {type: SET_LOADING_SEARCH_SEEKER, payload};
}

export function unsetSeekerProfile() {
  return {type: UNSET_SEEKER_PROFILE};
}