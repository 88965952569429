import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Switch, Icon
} from 'antd';
import _ from 'lodash';
import Helmet from 'react-helmet';

import BigPageHeader from 'components/PageHeader';

import TabProfile from './TabProfile';
import TabApplicant from './TabApplicant';

import './styles.scss';

class SeekerDetail extends React.PureComponent {
  componentDidMount() {
    this.props.fetchSeekerProfile(this.props.match.params.seekerId);
  }

  componentWillUnmount() {
    this.props.unsetSeekerProfile();
    this.props.destroyForm('changeEmail');
    this.props.destroyForm('changePassword');
  }

  handleClickSwitch = () => {
    const { profile } = this.props;
    const isActive = _.get(profile, 'status', '') === 'ACTIVE';
    const status = isActive ? 'INACTIVE' : 'ACTIVE';
    const statusText = isActive ? '非アクティブ' : 'アクティブ';

    this.props.openPopup({
      title: '',
      content: `本当にこの転職者を${statusText}にしますか？`,
      okText: 'はい',
      cancelText: '戻る',
      confirm: () => {
        this.props.updateUserStatus({
          userId: _.get(profile, 'objectId', ''), 
          role: _.get(profile, 'role', ''), 
          status
        });
      }
    });
  }

  render() {
    const { TabPane } = Tabs;
    const { profile, match, history } = this.props;
    const pageTitle = 'Tenshoku Dashboard | Seeker';
    const link = /^(.+)\/detail\/.+$/.exec(history.location.pathname)
    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <BigPageHeader
          title={_.isEmpty(profile) ? '...' : (
            <div id='title-seeker-name'>
              <div>{_.get(profile, 'kanjiLastName')} {_.get(profile, 'kanjiFirstName')}</div>
              <div className='kata-name'>[{_.get(profile, 'kataLastName')}{_.get(profile, 'kataFirstName')}]</div>
            </div>
          )}
          goBack={link && link[1] ? link[1] : true}
        />

        <div className="page-container" id="nailist-detail-page">
          <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            <TabPane tab="転職者プロファイル" key="1">
              <TabProfile profile={profile} />
            </TabPane>
            <TabPane tab="応募イベント" key="2">
              <TabApplicant seekerId={match.params.seekerId} />
            </TabPane>
          </Tabs>

          <div id='user-status'>
            <div>アクティブ</div>

            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={_.get(profile, 'status', '') === 'ACTIVE'}
              onClick={this.handleClickSwitch}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SeekerDetail.propTypes = {
  destroyForm: PropTypes.func,
  updateUserStatus: PropTypes.func,
  openPopup: PropTypes.func,
  fetchSeekerProfile: PropTypes.func,
  unsetSeekerProfile: PropTypes.func,
  match: PropTypes.any,
  profile: PropTypes.object
};

export default SeekerDetail;