import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SendMessage from 'assets/icons/send-message.svg';
import MessageFileInput from './MessageFileInput';
import './style.scss';

class MessageInput extends Component {
  constructor(props) {
    super(props);
    this.textarea = React.createRef();
  }

  componentDidMount() {
    this.textarea.current.focus();
  }

  sendImage = url => {
    const { eventId, sendMessage } = this.props;
    sendMessage({ eventId, content: url, contentType: 'image' });
  }

  sendText = () => {
    const { eventId, sendMessage } = this.props;
    const { current = {} } = this.textarea;

    if (current.value.trim()) {
      sendMessage({ eventId, content: current.value, contentType: 'text' });
    }

    this.textarea.current.value = '';
  }

  keyPress = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.sendText();
    }
  }

  render() {
    return (
      <div id='message-input'>
        <MessageFileInput sendImage={this.sendImage} />

        <textarea ref={this.textarea} onKeyDown={this.keyPress} rows='1' placeholder='メッセージを入力してください' />
        
        <div className='send-button' onClick={this.sendText}>
          <img alt='SendMessage' src={SendMessage} />
        </div>
      </div>
    );
  }
}

MessageInput.propTypes = {
  sendMessage: PropTypes.func,
  eventId: PropTypes.string
};

export default MessageInput;
