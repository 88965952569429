/* eslint-disable react/display-name */
import React from 'react';
import { Avatar, Typography } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Tags from 'components/Preset/Tags';

import './style.scss';

const { Paragraph } = Typography;

const Columns = {};
Columns.eventImage = {
  dataIndex: 'image',
  key: 'eventImage',
  width: 120,
  render: (record) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar 
        icon='picture'
        shape="square"
        src={_.get(record, '[0].thumbSmall')} 
        size={44}
        style={{width: 80, height: 44 }}
      />
    </div >
  )
};
Columns.roundedAvatar = {
  key: 'avatar',
  dataIndex: 'avatar',
  width: 72,
  render: (record) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar icon='user' src={_.get(record, 'thumbSmall')} size='large' />
      </div >
    );
  }
};
Columns.companyName = {
  title: '企業名',
  key: 'companyName',
  width: 380,
  sorter: true,
  render: (record) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{record.companyName || record.name}</div>
      </div >
    );
  }
};
Columns.email = {
  title: 'メールアドレス',
  dataIndex: 'email',
  key: 'email',
  width: 260
};
Columns.activeStatus = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  width: 160,
  render: (record) => (
    <Tags status={record} />
  )
};
Columns.createdAt = {
  title: '登録日',
  dataIndex: 'createdAt',
  key: 'createdAt',
  sorter: true,
  width: 150,
  render: (record) => (
    record ? moment(record).format('YYYY/MM/DD HH:mm') : '--'
  ),
  ellipsis: true
};
Columns.lastLogin = {
  title: '最終ログイン',
  dataIndex: 'lastLogin',
  key: 'lastLogin',
  sorter: true,
  width: 150,
  render: (record) => (
    record ? moment(record).format('YYYY/MM/DD HH:mm') : '--'
  ),
  ellipsis: true
};
Columns.seekerName = {
  title: '応募者名',
  key: 'kanjiFullName',
  sorter: true,
  width: 220,
  render: (record) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          {_.get(record, 'kanjiLastName', '') + ' ' + _.get(record, 'kanjiFirstName', '')}
          <br/>
          [{_.get(record, 'kataLastName', '') + _.get(record, 'kataFirstName', '')}]
        </div>
      </div >
    );
  }
};
Columns.eventTitle = {
  title: 'イベント名',
  key: 'eventTitle',
  dataIndex: 'eventTitle',
  sorter: true,
  width: 380,
  render: (record) => {
    return (
      <Paragraph ellipsis={{ rows: 2, expandable: false }}>
        {record}
      </Paragraph>
    );
  }
};
Columns.eventArea = {
  title: 'eventArea',
  key: 'eventArea',
  dataIndex: 'eventArea.name'
};
Columns.position = {
  title: '募集職種',
  key: 'position',
  dataIndex: 'position.name',
  width: 150
};
Columns.requestedDate = {
  title: '応募日',
  dataIndex: 'requestDate',
  key: 'requestDate',
  sorter: true,
  width: 250,
  render: (record) => (
    record ? moment(record).format('YYYY/MM/DD(ddd) HH:mm') : '--'
  )
};
Columns.eventDate = {
  title: '開催日',
  dataIndex: 'eventDate',
  key: 'eventDate',
  sorter: true,
  width: 250,
  render: (record) => (
    record ? moment(record).format('YYYY/MM/DD(ddd) HH:mm') : '--'
  )
};
Columns.requestStatus = {
  title: '応募者ステータス',
  dataIndex: 'status',
  key: 'reqstatus',
  width: 200,
  render: (record) => (
    <Tags status={record} />
  )
};
Columns.eventStatus = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'eventStatus',
  width: 150,
  render: (record) => (
    <Tags status={record === 'ACTIVE' ? 'ACTIVE_EVENT' : record} />
  )
};
Columns.requestSeekerAvatar = {
  ...Columns.roundedAvatar,
  dataIndex: 'seekerImage'
};
Columns.requestEventImage = {
  ...Columns.eventImage,
  dataIndex: 'eventImage'
};
Columns.requestEventDate = {
  ...Columns.eventDate,
  dataIndex: 'eventDate.iso'
};
Columns.createdAtEvent = {
  ...Columns.createdAt,
  title: '作成日'
};
Columns.requestSeekerName = {
  ...Columns.seekerName,
  render: (record) => (
    <Link to={`/dashboard/seeker/status/ACTIVE/detail/${record.seekerId}`} className="table-cell-link" target="_blank">
      <div>
        {Columns.seekerName.render(record)}
      </div>
    </Link>
  )
};
Columns.requestEventTitle = _.omit({
  ...Columns.eventTitle,
  render: (record) => (
    <Link to={`/dashboard/event/detail/${record.eventId}`} className="table-cell-link" target="_blank">
      <div>
        {Columns.eventTitle.render(record.eventTitle)}
      </div>
    </Link>
  )
}, ['dataIndex']);
Columns.eventCompanyName = {
  ...Columns.companyName,
  dataIndex: 'jCreatedBy',
  key: 'jCreatedBy.companyName',
  width: 180,
  render: (record) => (
    <Link to={`/dashboard/company/detail/${_.get(record, 'objectId')}`} className="table-cell-link" target="_blank">
      <div>
        {Columns.companyName.render(record || {})}
      </div>
    </Link>
  )
};

export default Columns;