import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { stopListenGetNewAddedMessage } from 'utils/fireBase';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import './style.scss';

function Messages({ 
  eventId,
  getMessage,
  sendMessage,
  resetMessage
}) {
  useEffect(() => {
    getMessage(eventId);

    return () => {
      resetMessage();
      stopListenGetNewAddedMessage(eventId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <div id='message-container'>
      <MessageList eventId={eventId} />
      <MessageInput eventId={eventId} sendMessage={sendMessage} />
    </div>
  );
}

Messages.propTypes = {
  eventId: PropTypes.string,
  getMessage: PropTypes.func,
  sendMessage: PropTypes.func,
  resetMessage: PropTypes.func
};

export default Messages;
