import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestApproveCompanyAccount } from 'providers/CompanyProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';


class ApprovingManagement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inProccess: false
        }
    }

    requestApproveCompanyAccount = async () => {
        const { requestApproveCompanyAccount, companyId, afterHandeSuccessfully } = this.props
        this.setState({ inProccess: true })
        try {
            await requestApproveCompanyAccount(companyId)
            if (afterHandeSuccessfully) {
                afterHandeSuccessfully()
            }
        } catch (error) {
            console.log('[requestApproveCompanyAccount]::error:', error)
        }
        this.setState({ inProccess: false })
    };

    confirm = () => {
        this.props.openPopup({
            title: '承認の確認',
            content: 'このアカウントを承認してもよろしいですか？ ',
            okText: '承認',
            cancelText: '取 消',
            okType: 'primary',
            confirm: async() => {
              this.requestApproveCompanyAccount()
            }
          })
    }

    render() {
        const { inProccess } = this.state
        return (
            <Fragment>
                {this.props.render(this.confirm, inProccess)}
            </Fragment>
        );
    }
}

ApprovingManagement.propTypes = {
    render: PropTypes.func,
    companyId: PropTypes.string,
    requestApproveCompanyAccount: PropTypes.func,
    afterHandleSuccessfully: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    requestApproveCompanyAccount: companyId => dispatch(requestApproveCompanyAccount({ companyId })),
    openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
});

export default connect(null, mapDispatchToProps)(ApprovingManagement);