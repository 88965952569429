import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Input,
  Select,
  Avatar
} from 'antd';
import _ from 'lodash';
import LightboxGallery from 'components/LightboxGallery';
import AccountManagement from '../../AccountManagement';
import './styles.scss';
import moment from 'moment';

class TabProfile extends React.Component {
  renderWeb = (item) => {
    const { image = '' } = item;

    return (
      <Card cover={(
        <img alt="webImage" src={image.startsWith('https://') ? image : `https://www.${item.host_name}${image}`} />
      )}>
        <Card.Meta title={item.host_name} description={item.title} />
      </Card>
    );
  }

  render() {
    const { profile } = this.props;
    const establishmentAt = _.get(profile, 'establishmentAt');

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <>
        <AccountManagement profile={profile} />

        <Form {...formItemLayout}>
          <Card className="company-profile-tab">
            <div className="company-profile-tab-title">会社紹介情報の編集</div>

            <Form.Item colon={false} label="アバター" className='company-profile-avatar'>
              <Avatar size={100} icon="picture" src={_.get(profile, 'companyImage.thumbSmall')} />
            </Form.Item>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>企業名</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'companyName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>Webサイト</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'companyWebsite')} disabled />
            </Form.Item>

            <Form.Item colon={false} label="タグ">
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'tag', []).map(e => e.name)}
                disabled
              />
            </Form.Item>
            
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>会社紹介</span></span>)}>
              <Input.TextArea style={{ width: 468 }} autoSize value={_.get(profile, 'companyIntroduction')} disabled />
            </Form.Item>
          </Card >
          
          <Card className="company-profile-tab">
            <div className="company-profile-tab-title">会社基本情報の編集</div>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>本社所在地</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'headOffice.name')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>設立</span></span>)}>
              <Input 
                style={{ width: 468 }} 
                value={establishmentAt && moment(establishmentAt).format('YYYY年MM月')} 
                disabled 
              />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>資本金</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'capital')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>売上高</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'aofSales')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>社員数</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'nofEmployees.range')} disabled />
            </Form.Item>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>事業所</span></span>)}>
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'branchOffice', []).map(e => e.name)}
                disabled
              />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>業界</span></span>)}>
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'industry', []).map(e => e.name)}
                disabled
              />
            </Form.Item>
          </Card >

          <Card className="company-profile-tab">
            <div className="company-profile-tab-title">社長情報の編集</div>
            
            <Form.Item colon={false} label="アバター" className='company-profile-avatar'>
              <Avatar size={100} icon="picture" src={_.get(profile, 'presidentImage.thumbSmall')} />
            </Form.Item>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>社長名</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'presidentName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>メールアドレス</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'email')} disabled />
            </Form.Item>

            <Form.Item colon={false} label={(
              <span><span className='required-icon'>*</span><span>社長キャッチコピー</span></span>
            )}>
              <Input.TextArea style={{ width: 468 }} autoSize value={_.get(profile, 'presidentSlogan')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>自己紹介</span></span>)}>
              <Input.TextArea style={{ width: 468 }} autoSize value={_.get(profile, 'presidentIntroduction')} disabled />
            </Form.Item>

            <Form.Item colon={false} label="Web記事/Youtube動画">
              <div className="company-profile-web-container">
                {_.get(profile, 'mediaArticles', []).map(item => this.renderWeb(item))}
              </div>
            </Form.Item>

            <Form.Item colon={false} label="画像（雑誌記事など）">
              <LightboxGallery>
                <div className="company-profile-image-container">
                  {_.get(profile, 'mediaImages', []).map(item => (
                    <img key={item.objectId} alt="mediaImage" src={item.thumbSmall} className="company-profile-image" />
                  ))}
                </div>
              </LightboxGallery>
            </Form.Item>

            <Form.Item colon={false} label="ブログ">
              <Input style={{ width: 468 }} value={_.get(profile, 'blogURL')} disabled />
            </Form.Item>
          </Card >
        </Form>
      </>
    );
  }
}

TabProfile.propTypes = {
  profile: PropTypes.object
};

export default TabProfile;