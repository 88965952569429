import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Collapse } from 'antd';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import ConfirmPassword from './ConfirmPassword';
import IconArrowUp from 'assets/icons/icon-arrow-up.svg';
import IconArrowDown from 'assets/icons/icon-arrow-down.svg';
import './style.scss';

const AccountManagement = props => {
  const { profile } = props;
  const { email, emailVerified, objectId, newEmail } = profile;
  const initialValues = { currentEmail: email };

  const changeEmail = () => {
    props.openConfirmPasswordPopup({ form: 'changeEmail' });
  };

  const changePassword = () => {
    props.openConfirmPasswordPopup({ form: 'changePassword' });
  };
  
  const resetPassword = () => {
    props.resetPassword(profile.email)
  };

  return (
    <Card className="account-management-tab">
      <Collapse 
        bordered={false} 
        expandIconPosition='right' 
        expandIcon={(panelProps) => (panelProps.isActive 
          ? (<img src={IconArrowUp} alt='Icon' />) 
          : (<img src={IconArrowDown} alt='Icon' />)
        )}
      >
        <Collapse.Panel header="アカウント設定" key="1">
          <Row id='account-management'>
            <Col span={12}>
              <ChangeEmailForm 
                initialValues={initialValues} 
                onSubmit={changeEmail} 
                email={email}
                emailVerified={emailVerified}
                newEmail={newEmail}
              />
            </Col>
            <Col span={12}>
              <ChangePasswordForm onSubmit={changePassword} resetPassword={resetPassword}/>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>

      <ConfirmPassword userId={objectId} role={profile.role} />
    </Card>
  );
};

AccountManagement.propTypes = {
  profile: PropTypes.object,
  openConfirmPasswordPopup: PropTypes.func
};

export default AccountManagement;
