import {
  SET_ACTIVE_MENU,
  TOGGLE_COLLAPSE_MENU
} from './constants';

export function setActiveMenu(payload = {newActiveMenu: '/dashboard'}) {
  return {type: SET_ACTIVE_MENU, payload};
}

export function toggleCollapseMenu() {
  return {type: TOGGLE_COLLAPSE_MENU};
}