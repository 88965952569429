import React, { Fragment } from 'react';
import _ from 'lodash';
import { Layout, Menu, Icon, Affix } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuRoutes from './SideMenuRoutes';
import { compose } from 'redux';
import './styles.scss';
import WIPIcon from 'components/WIPIcon';

import { makeCollapseMenu } from 'providers/LayoutProvider/selectors';

import logoImg from 'assets/icons/logo.svg';

const { SubMenu } = Menu;
const { Sider } = Layout;


class CustomSideMenu extends React.Component {
  renderTitle = menu => (
    <Fragment>
      {menu.icon && <Icon type={menu.icon} style={{ fontSize: '20px' }}/>}
      {menu.iconCus && <Icon component={menu.iconCus} style={menu.smallIcon ? { transform: 'translate(0px, 0px)'} : { fontSize: '24px' }}/>}
      <span>{menu.text}</span>
      {menu.disabled && <WIPIcon/>}
    </Fragment>
  )

  renderMenuItems = (menuItems) => {
    const resultJSX = [];
    menuItems.forEach(menu => {
      if (_.has(menu, 'subMenu')) {
        resultJSX.push(
          <SubMenu
            key={menu.key || menu._to}
            disabled={menu.disabled}
            title={
              this.renderTitle(menu)
            }
          >
            {this.renderMenuItems(menu.subMenu)}
          </SubMenu>
        );
      } else {
        resultJSX.push(
          <Menu.Item key={menu._to} disabled={menu.disabled}>
            {this.renderTitle(menu)}
          </Menu.Item>
        );
      }
    });

    return resultJSX;
  }

  onMenuItemClick = ({ keyPath }) => {
    const revkeyPath = keyPath.reverse();
    const pathString = revkeyPath.join('/');
    if (pathString) {
      this.props.history.replace('/dashboard' + pathString);
    }
  };

  render() {
    return (
      <Affix offsetTop={0} style={{zIndex: 100}} className="menu-wrapper">
        <Sider
          width={this.props.width}
          className="aside-menu"
          trigger={null}
          collapsible
          collapsed={this.props.collapseMenu}
        >
          <div className="side-menu-logo">
            <img
              width='100%'
              height='100%'
              style={{objectFit: 'contain'}}
              src={!this.props.collapseMenu ? logoImg : '/logo192.png'}
              alt='logo'
            />
          </div>
          <Menu
            mode='inline'
            selectedKeys={this.props.location.pathname}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ borderRight: 0 }}
            onSelect={this.onMenuItemClick}
          >
            {
              this.renderMenuItems(MenuRoutes)
            }
          </Menu>
        </Sider>
      </Affix>
    );
  }
}

CustomSideMenu.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  collapseMenu: PropTypes.bool,
  toggleCollapseMenu: PropTypes.func,
  width: PropTypes.number
};

const mapStateToProps = (state) => ({
  collapseMenu: makeCollapseMenu(state)
});

export default compose(
  connect(mapStateToProps, null),
  withRouter
)(CustomSideMenu);