import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';
import _ from 'lodash';
import BigPageHeader from 'components/PageHeader';
import CreateEventForm from './CreateEventForm';
import './styles.scss';

class CreateEventPage extends React.PureComponent {
  componentDidMount() {
    const { eventId } = this.props.match.params;
    if (eventId) {
      this.props.fetchEventDetail(eventId);
    }
    this.props.fetchAllActiveCompany();
  }

  componentWillUnmount() {
    this.props.unsetEventDetail();
  }

  formatValues = values => {
    const {
      companyId, startDate, startTime, images, ...data
    } = values;

    if (companyId) {
      data.companyId = companyId.split(',')[0];
    }
    
    if (startDate) {
      const time = moment(startTime);
      const eventDate = moment(startDate).set({
        hour: startTime ? time.get('hour') : 7, minute: startTime ? time.get('minute') : 0, second: 0, millisecond: 0
      });
      data.eventDate = eventDate.toISOString();
    } else {
      data.eventDate = null;
    }

    if (images && images.length) {
      data.image = _.map(images, image => image.objectId);
    }

    return data;
  }

  submitEvent = async data => {
    if (data.eventId) {
      await this.props.updateEvent(data);
    } else {
      await this.props.createEvent(data);
    }
  }

  handleSubmit = async values => {
    const data = this.formatValues(values);
    data.status = 'ACTIVE';
    await this.submitEvent(data);
    this.props.history.push('/dashboard/event/published');
  }

  handleDraft = async() => {
    const { values } = window.store.getState().form.createEvent;
    const data = this.formatValues(values);
    data.status = 'DRAFT';
    await this.submitEvent(data);
    this.props.history.push('/dashboard/event/draft');
  }

  render() {
    const pageTitle = 'Tenshoku Dashboard | Create Event';
    const { detail, match } = this.props;
    const { eventId } = match.params;
    const initialValues = {
      startTime: moment({ hour: 7, minute: 0 })
    };

    if (eventId) {      
      initialValues.images = _.get(detail, 'image', []);
      initialValues.eventTitle = _.get(detail, 'eventTitle');
      initialValues.position = _.get(detail, 'position.objectId');
      initialValues.eventArea = _.get(detail, 'eventArea.objectId');
      initialValues.restaurantName = _.get(detail, 'restaurantName');
      initialValues.recruitmentNumbers = _.get(detail, 'recruitmentNumbers');
      initialValues.eventDetail = _.get(detail, 'eventDetail');
      initialValues.idealCandidate = _.get(detail, 'idealCandidate');
      initialValues.expectedSkill = _.get(detail, 'expectedSkill');
      initialValues.employmentType = _.get(detail, 'employmentType.objectId');
      initialValues.expectedDate = _.get(detail, 'expectedDate');
      initialValues.contractType = _.get(detail, 'contractType.objectId');
      initialValues.annualIncome = _.get(detail, 'annualIncome.objectId');
      initialValues.bonus = _.get(detail, 'bonus');
      initialValues.allowance = _.get(detail, 'allowance');
      initialValues.annualLeave = _.get(detail, 'annualLeave');
      initialValues.benefit = _.get(detail, 'benefit');
      initialValues.trainingProgram = _.get(detail, 'trainingProgram');
      initialValues.officeHours = _.get(detail, 'officeHours');
      initialValues.workLocation = _.get(detail, 'workLocation.objectId');
      initialValues.canTransfer = _.get(detail, 'canTransfer');
      initialValues.housingAllowance = _.get(detail, 'housingAllowance');
      initialValues.companyHousing = _.get(detail, 'companyHousing');
    }

    if (detail.status === 'DRAFT') {
      if (_.get(detail, 'jCreatedBy')) {
        initialValues.companyId = `${_.get(detail, 'jCreatedBy.objectId')},${_.get(detail, 'jCreatedBy.companyName')}`;
      }
      initialValues.startDate = _.get(detail, 'eventDate');
      initialValues.startTime = _.get(detail, 'eventDate');
      initialValues.duration = _.get(detail, 'duration');
      initialValues.eventId = _.get(detail, 'objectId');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <BigPageHeader
          title='イベント新規作成'
          goBack={true}
        />

        <div id="create-event-page">
          <CreateEventForm 
            onSubmit={this.handleSubmit} 
            onDraft={this.handleDraft} 
            initialValues={initialValues} 
            {...this.props} 
          />
        </div>
      </React.Fragment>
    );
  }
}

CreateEventPage.propTypes = {
  fetchEventDetail: PropTypes.func,
  fetchAllActiveCompany: PropTypes.func,
  unsetEventDetail: PropTypes.func,
  createEvent: PropTypes.func,
  updateEvent: PropTypes.func,
  history: PropTypes.any,
  match: PropTypes.any,
  detail: PropTypes.object
};

export default CreateEventPage;