/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card, Rate, Avatar, Table, List, Tag } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { customWithRouter } from 'components/HOC';
import IconArrowRight from 'assets/icons/icon-arrow-right.svg';

import { makeEventReview } from 'providers/EventProvider/selectors';
import { fetchReviewedEvent } from 'providers/EventProvider/actions';

const TabReview = ({ fetchReviewedEvent, reviewEvent, isTabReview, match }) => {
  const [currentPage, setCurrentPage] = useState(2);
  const [isBottom, setIsBottom] = useState(false);
  const eventReview = _.get(reviewEvent, 'eventReview');
  const seekerReviews = _.get(reviewEvent, 'seekerReviews');

  useEffect(() => {
    if (isTabReview) window.addEventListener('scroll', handleScroll);
    else window.removeEventListener('scroll', handleScroll);
  }, [isTabReview]);

  useEffect(() => {
    if (isBottom) {
      if (!reviewEvent.hasFetchAllReview) {
        setCurrentPage(currentPage + 1);
        fetchReviewedEvent({
          eventId: match.params.eventId,
          page: currentPage
        });
      } 
      setIsBottom(!isBottom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  const handleScroll = () => {
    const scrollTop = (document.documentElement
      && document.documentElement.scrollTop)
      || document.body.scrollTop;
    const scrollHeight = (document.documentElement
      && document.documentElement.scrollHeight)
      || document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 20 >= scrollHeight) {
      setIsBottom(true);
    }
  };

  const getAttandanceMsg = (attendance) => {
    if (attendance === 'ATTEND') return '出席';
    if (attendance === 'ABSENCE') return '欠席';
    if (attendance === 'DELAY') return '遅刻';
  };

  const columns = [
    {
      title: '参加者名',
      key: 'seekerDetail',
      width: 229,
      render: (record) => (
        <div className='table-cell'>
          <div className='seeker-container'>
            <div className='seeker-info'>
              <Avatar size={40} shape='circle' icon='user' src={record.avatar} />
              <div>
                <div className='seeker-name'>{record.kanjiFullName}</div>
                <div className='seeker-name'>{record.kataFullName}</div>
              </div>
            </div>
            <Link to={`/dashboard/seeker/status/ACTIVE/detail/${record.objectId}`} target="_blank">
              <img src={IconArrowRight} alt='arrow-right'/>
            </Link>
          </div>
        </div> 
      )
    },
    {
      title: '参加者レビュー',
      key: 'seekerReview',
      width: 589,
      render: (record) => (
        <List 
          itemLayout='horizotal'
          dataSource={[{...record.eventReview}]}
          renderItem={(item) => (
            _.isEmpty(item) ?
              <div className='table-cell-centered unreview-event'>まだレビューをしていません</div> :
              <>
                <List.Item className={!item.hasJoin && 'table-cell-centered'}>
                  <div className='tab-review-message'>
                  イベントに参加しましたか？
                    <Tag color={item.hasJoin ? '#084887' : '#c02425'}>{item.hasJoin ? 'はい' : 'いいえ'}</Tag>
                  </div>
                </List.Item>
                {
                  item.hasJoin &&
                <>
                  <List.Item>
                    <div className='tab-review-message'>
                      社長への評価 
                      <Rate className='star-rating' value={item.presidentRating} disabled/>
                    </div>
                    {
                      _.get(item, 'comment') && 
                      <div className='company-comment'>
                        イベントへのコメント
                        <span className='comment'>{_.get(item, 'comment')}</span>
                      </div>
                    }
                  </List.Item>
                  <List.Item>
                    <div>イベントへの評価: <Rate className='star-rating' value={item.eventRating} disabled/></div>
                    {
                      _.get(item, 'feedback') && 
                      <div className='company-comment'>
                        イベントへのコメント
                        <span className='comment'>{_.get(item, 'feedback')}</span>
                      </div>
                    }
                  </List.Item>
                  <List.Item>
                    <div className='tab-review-message'>
                      インターンに参加したいですか？
                      <Tag color={item.hasApplyCompany ? '#084887' : '#c02425'}>
                        {item.hasApplyCompany ? 'はい' : 'いいえ'}
                      </Tag>
                    </div>
                  </List.Item>
                </>
                }
              </>
          )}
        />
      )
    },
    {
      title: '主催者レビュー',
      dataIndex: 'companyReview',
      width: 252,
      render: (record) => (
        _.isEmpty(record) ?
          <div className='table-cell-centered unreview-event'>まだレビューをしていません</div> :
          <div className='table-cell'>
            <div className='tab-review-message'>
            出欠登録
              <Tag color={record.attendance === 'ATTEND' ? '#084887' : '#c02425'}>
                {getAttandanceMsg(record.attendance)}
              </Tag>
            </div>
            {record.attendance !== 'ABSENCE' && 
            <div>学生への評価 <Rate className='star-rating' value={record.rating} disabled/></div>
            }
          </div>
      )
    }
  ];
  return (
    <>
      <Card className='event-review-tab'>
        <div className='tab-review-title'>主催者レビュー</div>
        <div className='company-review-content'>
          <Avatar 
            size={64} 
            icon='picture' 
            src={_.get(eventReview, 'presidentImage.thumbLarge')} 
            className='president-avatar' 
          />
          <div className='company-detail'>
            <div className='company-name'>{eventReview.presidentName}</div>
            <div className='company-rating'>
              イベントへの評価
              <Rate
                className='star-rating'
                value={eventReview.rating}
                disabled
              />  
            </div>
            {
              _.get(eventReview, 'comment') && 
              <div className='company-comment'>
                イベントへのコメント
                <span className='comment'>{_.get(eventReview, 'comment')}</span>
              </div>
            }
          </div>
        </div>
      </Card>

      <Card className='event-review-tab'>
        <div className='tab-review-title'>イベント参加者レビュー</div>
        <Table
          className='tab-review-table'
          scroll={{x: 1000}}
          rowKey='objectId'
          pagination={false}
          columns={columns}
          dataSource={seekerReviews}
        />
      </Card>
    </>
  );
};

TabReview.propTypes = {
  reviewEvent: PropTypes.object,
  isTabReview: PropTypes.bool,
  fetchReviewedEvent: PropTypes.func,
  match: PropTypes.object
};

const mapStateToProps = (state) => ({
  reviewEvent: makeEventReview(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchReviewedEvent: (params) => dispatch(fetchReviewedEvent(params))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  customWithRouter,
  withRouter
)(TabReview);
