import React from 'react';
import Logo from 'assets/icons/icon-logo.svg';
import './style.scss';

const LoadingLogo = () => (
  <div className='loading-logo'>
    <img src={Logo} alt='loading-logo' />
  </div>
);

export default LoadingLogo;
