import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import LoginPage from 'containers/LoginPage';
import NotFoundPage from 'containers/NotFoundPage';

// import UserExample from 'containers/UserExample';
import Company from 'containers/Company';
import Seeker from 'containers/Seeker';
import EventContainer from 'containers/EventContainer';
import Notifications from 'containers/Notifications';
import Request from 'containers/Request';

import BreadcrumbConst from 'components/Breadcrumb/Bread';

const RedirectToDashboard = () => <Redirect to="/dashboard/company" />;

const indexRoutes = [
  { path: '/login', component: LoginPage, requireLogin: false },
  { path: '/change-password', component: NotFoundPage, requireLogin: true },
  
  
  { path: '/', component: RedirectToDashboard, requireLogin: true, exact: true },
  { path: '/seeker', component: Seeker, requireLogin: true },
  { path: '/company', component: Company, requireLogin: true },
  { path: '/event', component: EventContainer, requireLogin: true },
  { path: '/notifications', component: Notifications, requireLogin: true },
  { path: '/request', component: Request, requireLogin: true },
  { path: '', component: RedirectToDashboard }
];

indexRoutes.map(route => {
  if (route.breadcrumb) {
    route.breadcrumb = _.get(BreadcrumbConst, route.breadcrumb);
  }
  return route;
});

export default indexRoutes;
