/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

let store = null;

export default function configureStore(initialState = {}, history) {
  const middlewares = [thunkMiddleware, sagaMiddleware, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false
    })
    : compose;
  /* eslint-enable */

  store = createStore(rootReducer(history), initialState, composeEnhancers(...enhancers));

  
  sagaMiddleware.run(rootSaga);
  return store;
}

//return a function that return redux store current state
export function getStore() {
  return store.getState || {};
}