import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import SearchRequestPage from './SearchRequestPage';
import RequestDetailPage from './RequestDetailPage';

import './styles.scss';

class UserExample extends React.PureComponent {
  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/search`} component={SearchRequestPage}/>
          <Route path={`${match.path}/detail/:requestId`} component={RequestDetailPage}/>
          <Redirect to={`${match.path}/search`} />
        </Switch>
        
      </React.Fragment>
    );
  }
}

UserExample.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.any
};

export default UserExample;