import produce from 'immer';

import {
  SET_ACTIVE_MENU,
  TOGGLE_COLLAPSE_MENU
} from './constants';

const initialState = {
  activeMenu: '/dashboard',
  collapseMenu: false
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      state.activeMenu = action.payload.newActiveMenu;
      return state;
    case TOGGLE_COLLAPSE_MENU:
      state.collapseMenu = !state.collapseMenu;
      return state;
    default:
      return state;
  }
});

export default reducer;