import { put, takeLeading, call } from 'redux-saga/effects';
import _ from 'lodash';

import request from 'utils/request';
import { GET_NUMBER_OF_SEEKER_REQUEST, SEND_NOTIFICATION_REQUEST } from './constants';
import {
  getNumberOfSeekerSuccess,
  sendNotificationSuccess,
  setNotificationErrors
} from './actions';

function * getNumberOfSeeker(action) {
  try {
    const params = _.pick(action.payload, ['allSeeker', 'interestingJob']);
    const data = yield call(request, '/functions/pushNotification', { count: true, ...params }, 'POST');
    yield put(getNumberOfSeekerSuccess(data));
  } catch (err) {
    yield put(setNotificationErrors());
  }
}

function * sendNotification(action) {
  try {
    const params = _.pick(action.payload, ['allSeeker', 'interestingJob', 'eventId', 'content']);
    yield call(request, '/functions/pushNotification', { count: false, ...params }, 'POST');
    yield put(sendNotificationSuccess());
  } catch (err) {
    yield put(setNotificationErrors());
  }
}

export default function* watchMessage() {
  yield takeLeading(GET_NUMBER_OF_SEEKER_REQUEST, getNumberOfSeeker);
  yield takeLeading(SEND_NOTIFICATION_REQUEST, sendNotification);
}