import _ from 'lodash';

export const makeEventList = (state) => {
  return _.get(state, 'event.search');
};

export const makeEventDetail = (state) => {
  return _.get(state, 'event.detail');
};

export const makeAllActiveCompany = (state) => {
  return _.get(state, 'event.company');
};

export const makeEventReview = (state) => {
  return _.get(state, 'event.review');
};