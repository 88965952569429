import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Form, Button } from 'antd';
import { Row } from 'antd';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

const { Content } = Layout;

class FilterForm extends React.PureComponent {
  render() {
    return (
      <Content style={{}}>
        <Form layout="inline" onSubmit={this.props.handleSubmit}>
          <Row>
            {
              !_.isEmpty(this.props.filterFields) && 
              this.props.filterFields.map((field, key) => React.cloneElement(field.JSX, {key : `filter-${key}`}))
            }
          </Row>

          <Form.Item className="submit-search-bar">
            <Button type="primary" htmlType="submit" >
              検索
            </Button>
          </Form.Item>
        </Form>
      </Content>
    );
  }
}

FilterForm.propTypes = {
  handleSubmit: PropTypes.any,
  filterFields: PropTypes.any
};

export default reduxForm({
  form: 'nailist-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
})(FilterForm);