import React from 'react';
import { Field } from 'redux-form';
import { Select as AntSelect } from 'antd';
import { Select, DatePicker, TextField } from 'components/Form';

import {
  makeSelectPrefecture,
  makeSelectPosition
} from 'providers/ResourceProvider/selectors';

const { Option } = AntSelect;


/*
  [i] - READ ME!
    in case select option does not update into view after resource updated, use HOC withResource with array if resource name
    example:
    ```
      export default compose(
        connect(mapStateToProps, mapDispatchToProps),
        customWithRouter,
        withResource(['Position'])
      )(SearchCompanyPage);
    ```
*/

class FilterFields {
  static get companyName() {
    return {
      JSX: (
        <Field
          name="companyName"
          label="企業名"
          placeholder="企業名"
          component={TextField}
        />
      )
    };
  }

  static get email() {
    return {
      JSX: (
        <Field
          name="email"
          label="メールアドレス"
          placeholder="メールアドレス"
          component={TextField}
        />
      )
    };
  }

  static get createdAt() {
    return {
      JSX: (
        <Field
          name="createdAt"
          label="登録日"
          placeholder="登録日"
          component={DatePicker}
        />
      )
    };
  }

  static get activeStatus() {
    return {
      JSX: (
        <Field
          name="status"
          label="ステータス"
          placeholder={'すべて'}
          component={Select}
        >
          <Option value="ACTIVE">アクティブ</Option>
          <Option value="INACTIVE">非アクティブ</Option>
        </Field>
      )
    };
  }

  static get applicantName() {
    return {
      JSX: (
        <Field
          name="applicantName"
          label="企業名"
          placeholder="企業名"
          component={TextField}
        />
      )
    };
  }

  static get eventTitle() {
    return {
      JSX: (
        <Field
          name="eventTitle"
          label="イベント名"
          placeholder="イベント名"
          component={TextField}
        />
      )
    };
  }

  static get eventArea() {
    const state = window.store.getState();
    const options = makeSelectPrefecture(state);

    return {
      JSX: (
        <Field
          name="eventAreaId"
          label="eventArea"
          placeholder={'すべて'}
          component={Select}
        >
          {options.map(otp => <Option value={otp.objectId} key={otp.objectId}>{otp.name}</Option>)}
        </Field>
      )
    };
  }

  static get position() {
    const state = window.store.getState();
    const options = makeSelectPosition(state);

    return {
      JSX: (
        <Field
          name="positionId"
          label="募集職種"
          placeholder={'すべて'}
          component={Select}
        >
          {options.map(otp => <Option value={otp.objectId} key={otp.objectId}>{otp.name}</Option>)}
        </Field>
      )
    };
  }

  static get eventStatus() {
    return {
      JSX: (
        <Field
          name="status"
          label="ステータス"
          placeholder={'すべて'}
          component={Select}
        >
          <Option value="ACTIVE">募集中</Option>
          <Option value="LOCKED">確定済み</Option>
          <Option value="DRAFT">下書き</Option>
          <Option value="FINISHED">終了</Option>
          <Option value="CANCELED">キャンセル</Option>
        </Field>
      )
    };
  }

  static get eventDate() {
    return {
      JSX: (
        <Field
          name="eventDate"
          label="開催日"
          placeholder="開催日"
          component={DatePicker}
        />
      )
    };
  }

  static get seekerName() {
    return {
      JSX: (
        <Field
          name="name"
          label="転職者名"
          placeholder="転職者名"
          component={TextField}
        />
      )
    };
  }

  static get requestedDate() {
    return {
      JSX: (
        <Field
          name="requestDate"
          label="応募日"
          placeholder="応募日"
          component={DatePicker}
        />
      )
    };
  }

  static get requestStatus() {
    return {
      JSX: (
        <Field
          name="requestStatus"
          label="応募者ステータス"
          placeholder={'すべて'}
          component={Select}
        >
          <Option value="REQUEST">応募中</Option>
          <Option value="ACCEPTED">参加決定</Option>
          <Option value="MET">イベント終了</Option>
          <Option value="DENIED">お断り済み</Option>
        </Field>
      )
    };
  }

  static get requestKeyword() {
    return {
      JSX: (
        <Field
          name="keyword"
          label="名前/キーワード検索"
          placeholder="名前/キーワード検索"
          component={TextField}
        />
      )
    };
  }
}

export default FilterFields;