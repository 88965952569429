import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from 'antd';
import { TextField } from 'components/Form';
import validate from './validate';
import Password from 'assets/icons/password.svg';

const ChangePasswordForm = props => {
  const { error, submitting, invalid, pristine, resetPassword } = props;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };

  return (
    <Form onSubmit={props.handleSubmit} {...formItemLayout} id='change-password-form'>
      <div className="account-management-title with-button">
        <img src={Password} alt='Icon' />
        <span>パスワードの変更</span>
        <div onClick={resetPassword}>パスワードの更新を通知</div>
      </div>

      <Field
        name="newPassword"
        type="password"
        placeholder="新しいパスワード"
        component={TextField}
        label="新しいパスワード"
        style={{ maxWidth: 320 }}
        autoComplete="new-password"
      />
      
      <Field
        name="confirmPassword"
        type="password"
        placeholder="新しいパスワード(確認用)"
        component={TextField}
        label="新しいパスワード(確認用)"
        style={{ maxWidth: 320 }}
      />

      <div className='account-management-button-container'>
        <button 
          type='submit' 
          className='ten-ad-btn primary-button' 
          disabled={error || submitting || invalid || pristine}
        >
          更新
        </button>
      </div>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  error: PropTypes.any,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  resetPassword: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default reduxForm({ 
  form: 'changePassword', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(ChangePasswordForm);
