import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Form, Row, Col, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Select as AntSelect } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { TextField, DatePicker, Select } from 'components/Form';
import validate from './validate';
import './styles.scss';

const { Option } = AntSelect;

class UpdatePlanForm extends Component {
  constructor(props) {
    super(props);
    this.getMenus = this.getMenus.bind(this);
  }

  getMenus = () => {
    const list = [];
    this.props.optionMenus.forEach((option) => {
      if (option && option.price > 0) {
        list.push(option);
      }
    });
    return list;
  }

  render() {
    const optionMenus = this.getMenus();
    const isPremium = this.props.typeOfPlan === 'PREMIUM-PLAN';

    return (
      <Form
        layout="vertical"
        className="update-plan-container"
        onSubmit={this.props.handleSubmit}
      >
        <Field
          name="name"
          type="text"
          component={TextField}
          label="プラン名"
          required
        />
        <Field
          name="total"
          type="number"
          min={0}
          required
          component={TextField}
          label="プラン枠数"
        />
        <Field
          name={this.props.isEdit ? 'endDate' : 'startDate'}
          component={DatePicker}
          label={this.props.isEdit ? '終了日' : '開始日'}
          required
          formatDatePicker="YYYY/MM/DD"
          disabledDate={(currentDate) => {
            if (this.props.isEdit) {
              return (
                moment(currentDate).diff(
                  moment(this.props.initialValues.startDate).startOf('day'),
                  'days'
                ) < 1
              );
            }
            return moment(currentDate).diff(moment(), 'days') < 0;
          }}
        />
        <Field name="typeOfPlan" label="契約タイプ" component={Select} required>
          <Option value="SHACHO-PLAN">社長プラン</Option>
          <Option value="STANDARD-PLAN">スタンダードプラン</Option>
          <Option value="PREMIUM-PLAN">プレミアムプラン</Option>
        </Field>
        {this.props.isEdit && (
          <Field
            name="menus"
            label="オプション"
            component={Select}
            mode="multiple"
            allowClear={false}
            {...(isPremium ? {
              input: { value: optionMenus.map((option) => option._id) },
              disabled: true
            } : {})}
          >
            {optionMenus.map((option) => {
              const name = option.name.split('：');
              return <Option key={option._id} value={option._id}>{name[1]}</Option>;
            })}
          </Field>
        )}
        <Row type="flex" justify="end" className="footer-group">
          <Col>
            <Button onClick={this.props.onCancel}>キャンセル</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              更新
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

UpdatePlanForm.propTypes = {
  valid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  optionMenus: PropTypes.array,
  setFieldValue: PropTypes.func,
  typeOfPlan: PropTypes.string
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('updatePlan');
  return {
    typeOfPlan: selector(state, 'typeOfPlan')
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFieldValue: (field, value) => dispatch(change('updatePlan', field, value))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'updatePlan',
    destroyOnUnmount: true,
    validate
  })
)(UpdatePlanForm);
