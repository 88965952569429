import { connect } from 'react-redux';
import { lockEventRequest } from 'providers/EventProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeLockEventPopup } from 'providers/PopupProvider/selectors';

import LockEventPopup from './LockEventPopup';

const mapStateToProps = (state) => ({
  popupState: makeLockEventPopup(state)
});

const mapDispatchToProps = (dispatch) => ({
  lockEventRequest: (params) => dispatch(lockEventRequest(params)),
  closePopup: () => dispatch(SetPopup({name: 'lockEventPopup', show: false}))
});

export default connect(mapStateToProps, mapDispatchToProps)(LockEventPopup);