import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Dot from 'assets/icons/dot-gray.svg';
import './style.scss';

const MessageCell = ({ message, sender, messageCellStyle, showLess, ordinalMessage, children }) => {
  const { updatedAt, contentType, content } = message;

  return (
    <div className={`message-cell ${ordinalMessage}`} style={messageCellStyle}>
      <div className='message-wrapper'>
        {!showLess && (<div className='sender-image'>
          <img src={_.get(sender, 'avatar.thumbSmall')} alt='avatar' />
        </div>)}

        <div className={`message ${showLess ? 'showLess' : ''}`}>
          <div className='message-info'>
            {!showLess && (
              <>
                <div className='sender-name'>{_.get(sender, 'displayName')}</div>

                <img src={Dot} alt='dot' />

                <div className='message-time'>
                  {moment(updatedAt).locale('ja').format('HH:mm')}
                </div>
              </>
            )}
          </div>
          
          <div title={moment(updatedAt).locale('ja').format('YYYY/MM/DD HH:mm')}>
            {contentType === 'text' && (<div className='message-text'>{content.trim()}</div>)}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

MessageCell.propTypes = {
  children: PropTypes.any,
  showLess: PropTypes.bool,
  ordinalMessage: PropTypes.string,
  message: PropTypes.object,
  sender: PropTypes.object,
  messageCellStyle: PropTypes.object
};

export default MessageCell;
