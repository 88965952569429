import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'antd';
import { withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';
import Bread from 'components/Breadcrumb/Bread';
import Back from 'assets/icons/back.svg';
import _ from 'lodash';
import './style.scss';

class PageHeaderCustom extends React.Component {
  static Bread = Bread;
  
  render() {
    let props = {...this.props};
    let { breadcrumb } = props;

    if (this.props.breadcrumb) {
      delete props.breadcrumb;
    }
    if (_.isFunction(this.props.goBack)) {
      props.onBack = this.props.goBack;
    } else if (_.isString(this.props.goBack)) {
      props.onBack = () => this.props.history.push(this.props.goBack);
    } else if (this.props.goBack) {
      props.onBack = this.props.history.goBack;
    }
    
    return (
      <div className="content-header" id="page-header">
        <Breadcrumb breadcrumb={breadcrumb}/>
        <PageHeader backIcon={(<img alt='icon' src={Back} />)} {...props}/>
      </div>
      
    );
  }
}

PageHeaderCustom.propTypes = {
  breadcrumb: PropTypes.any,
  goBack: PropTypes.any, // string link, bool true to use browser back, function
  history: PropTypes.object
};

export default withRouter(PageHeaderCustom);