import {
  GET_NUMBER_OF_SEEKER_REQUEST,
  GET_NUMBER_OF_SEEKER_SUCCESS,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_ERRORS,
  RESET_NOTIFICATION_STATE
} from './constants';

export const getNumberOfSeekerRequest = (payload = {}) => ({
  type: GET_NUMBER_OF_SEEKER_REQUEST,
  payload
});

export const getNumberOfSeekerSuccess = (payload = {}) => ({
  type: GET_NUMBER_OF_SEEKER_SUCCESS,
  payload
});

export const sendNotificationRequest = (payload = {}) => ({
  type: SEND_NOTIFICATION_REQUEST,
  payload
});

export const sendNotificationSuccess = (payload = {}) => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload
});

export const setNotificationErrors = () => ({
  type: SET_NOTIFICATION_ERRORS
});

export const resetNotificationState = () => ({
  type: RESET_NOTIFICATION_STATE
});