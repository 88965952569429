import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Card, Icon } from 'antd';
import { useQuery } from 'utils/hooks';
import RecommendEventForm from './form';
import './styles.scss';

const RecommendEvent = (props) => {
  const query = useQuery();
  const { 
    jobResource, 
    setFieldValue,
    fetchEventDetail,
    eventDetail,
    allSeekerFieldValue = true,
    interestingJobFieldValue = [],
    getNumberOfSeeker,
    numberOfSeeker,
    sendNotification,
    resetForm,
    sendNotificationSucceed,
    resetNotificationState,
    unsetEventDetail
  } = props;

  const handleSubmit = (values) => {
    sendNotification(values);
    query.delete('eventId');
    unsetEventDetail();
    resetForm();
  };

  useEffect(() => {
    setFieldValue('interestingJob', []);
    getNumberOfSeeker({ allSeeker: allSeekerFieldValue, interestingJob: interestingJobFieldValue });
    // eslint-disable-next-line
  }, [allSeekerFieldValue]);

  useEffect(() => {
    if (interestingJobFieldValue) {
      getNumberOfSeeker({ allSeeker: allSeekerFieldValue, interestingJob: interestingJobFieldValue });
    }
    // eslint-disable-next-line
  }, [interestingJobFieldValue]);

  useEffect(() => {
    if (query.get('eventId')) {
      fetchEventDetail(query.get('eventId'));
    }
    return () => {
      resetNotificationState();
      unsetEventDetail();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Tenshoku Dashboard | Notifications | Event</title>
      </Helmet>

      <div className="page-container" id="recommend-event-page">
        <Card className="recommend-event-section">
          <div className="section-title">プッシュ通知</div>
          {sendNotificationSucceed ? (
            <div className="success-message">
              <div className="success-title">
                <Icon type="check-circle" />イベントPR
              </div>
              {sendNotificationSucceed}人の転職希望者へプッシュ通知の送信が完了しました！
            </div>
          ) : null}
          <RecommendEventForm
            onSubmit={handleSubmit}
            jobResource={jobResource}
            setFieldValue={setFieldValue}
            allSeekerFieldValue={allSeekerFieldValue}
            numberOfSeeker={numberOfSeeker}
            eventDetail={eventDetail}
            initialValues={{
              allSeeker: true,
              interestingJob: [],
              eventId: query.get('eventId') || '',
              content: ''
            }}
          />
        </Card>
      </div>
        
    </Fragment>
  );
};

RecommendEvent.propTypes = {
  jobResource: PropTypes.array,
  setFieldValue: PropTypes.func,
  allSeekerFieldValue: PropTypes.bool,
  interestingJobFieldValue: PropTypes.array,
  numberOfSeeker: PropTypes.number,
  getNumberOfSeeker: PropTypes.func,
  sendNotification: PropTypes.func,
  resetForm: PropTypes.func,
  sendNotificationSucceed: PropTypes.number,
  resetNotificationState: PropTypes.func,
  eventDetail: PropTypes.object,
  fetchEventDetail: PropTypes.func,
  unsetEventDetail: PropTypes.func
};

export default RecommendEvent;