import {
  LOGIN_FIREBASE_SUCCESS,
  LOGOUT_FIREBASE_SUCCESS,
  FETCH_MESSAGE,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MORE_MESSAGE,
  FETCH_MORE_MESSAGE_SUCCESS,
  RECEIVE_NEW_MESSAGE,
  SEND_MESSAGE,
  READ_NEW_MESSAGE,
  RESET_MESSAGE
} from './constants';

export function loginFirebaseSuccess() {
  return {type: LOGIN_FIREBASE_SUCCESS};
}

export function logOutFirebaseSuccess() {
  return {type: LOGOUT_FIREBASE_SUCCESS};
}

export function fetchMessage(payload = {}) {
  return {type: FETCH_MESSAGE, payload};
}

export function fetchMessageSuccess(payload = {}) {
  return {type: FETCH_MESSAGE_SUCCESS, payload};
}

export function fetchMoreMessage(payload = {}) {
  return {type: FETCH_MORE_MESSAGE, payload};
}

export function fetchMoreMessageSuccess(payload = {}) {
  return {type: FETCH_MORE_MESSAGE_SUCCESS, payload};
}

export function receiveNewMessage(payload = {}) {
  return {type: RECEIVE_NEW_MESSAGE, payload};
}

export function sendMessage(payload = {}) {
  return {type: SEND_MESSAGE, payload};
}

export function readNewMessage() {
  return {type: READ_NEW_MESSAGE};
}

export function resetMessage() {
  return {type: RESET_MESSAGE};
}