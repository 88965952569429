import {
  GET_RESOURCE,
  GET_RESOURCE_SUCCESS
} from './constants';

export function getResource() {
  return {
    type: GET_RESOURCE
  };
}

export function getResourceSuccess(payload = {}) {
  return {
    type: GET_RESOURCE_SUCCESS,
    payload
  };
}