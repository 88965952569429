import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change, reset } from 'redux-form';
import _get from 'lodash/get';

import { makeSelectJob } from 'providers/ResourceProvider/selectors';
import { fetchEventDetail, unsetEventDetail } from 'providers/EventProvider/actions';
import { 
  getNumberOfSeekerRequest,
  sendNotificationRequest, 
  resetNotificationState 
} from 'providers/NotificationProvider/actions';
import { makeSelectNumberOfSeeker, makeSelectSendNotificationSucceed } from 'providers/NotificationProvider/selectors';
import RecommendEvent from './RecommendEvent';

const mapStateToProps = (state) => {
  const selector = formValueSelector('recommendEvent');
  return {
    jobResource: makeSelectJob(state),
    numberOfSeeker: makeSelectNumberOfSeeker(state),
    allSeekerFieldValue: selector(state, 'allSeeker'),
    interestingJobFieldValue: selector(state, 'interestingJob'),
    sendNotificationSucceed: makeSelectSendNotificationSucceed(state),
    eventDetail: _get(state, 'event.detail', {})
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFieldValue: (field, value) => dispatch(change('recommendEvent', field, value)),
  resetForm: () => dispatch(reset('recommendEvent')),
  fetchEventDetail: (eventId) => dispatch(fetchEventDetail(eventId)),
  unsetEventDetail: () => dispatch(unsetEventDetail()),
  getNumberOfSeeker:
    ({ allSeeker, interestingJob }) => dispatch(getNumberOfSeekerRequest({ allSeeker, interestingJob })),
  sendNotification: (values) => dispatch(sendNotificationRequest(values)),
  resetNotificationState: () => dispatch(resetNotificationState())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(RecommendEvent);