import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Input } from 'antd';
import Label from '../Label';

const { Option } = Select;

class ExtraSelect extends React.PureComponent {
  handleChangeSelect = (e) => {
    this.props.input.onChange({ extra: '', value: e });
  }

  handleChangeText = e => {
    this.props.input.onChange({ ...this.props.input.value, extra: e.target.value });
  }

  render() {
    const {
      label,
      placeholder,
      input,
      options,
      style,
      required,
      disabled,
      maxLength,
      meta: { touched, invalid, error }
    } = this.props;
    
    const optionElements = (this.props.children) || (options
    && options.map((option) => (
      <Option key={option.value} value={option.value}>
        {option.name}
      </Option>
    )));
      
    return (
      <Form.Item colon={false} label={<Label label={label} required={required} />} help={touched && invalid && error}>
        <Select
          {...input}
          allowClear
          placeholder={placeholder}
          value={input.value.value || undefined}
          onChange={this.handleChangeSelect}
          onBlur={this.handleChangeSelect}
          style={style}
          disabled={disabled}
        >
          {optionElements}
        </Select>
        {input.value.value === 'yes' && (
          <div>
            <Input
              type='text'
              value={input.value.extra}
              style={style}
              onChange={this.handleChangeText}
              disabled={disabled}
              maxLength={maxLength}
            />
          </div>
        )}
      </Form.Item>
    );
  }
}

ExtraSelect.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  meta: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default ExtraSelect;
