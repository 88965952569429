import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import Label from '../Label';

class TextArea extends React.PureComponent {
  render() {
    const {
      label, placeholder, input, type, size, prefix, style,
      required, disabled, maxLength, autoSize = true, className = '',
      meta: { touched, invalid, error }
    } = this.props;

    return (
      <Form.Item 
        colon={false}
        className={className}
        label={<Label label={label} required={required} />} 
        help={touched && invalid && error} 
        validateStatus={touched && invalid && error && 'error'}
      >
        <Input.TextArea
          {...input}
          placeholder={placeholder}
          type={type}
          value={input.value}
          size={size}
          prefix={prefix}
          style={style}
          disabled={disabled}
          maxLength={maxLength}
          autoSize={autoSize}
        />
      </Form.Item>
    );
  }
}

TextArea.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.any,
  prefix: PropTypes.any,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  autoSize: PropTypes.any,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default TextArea;
