import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/database';
import _ from 'lodash';
import request from 'utils/request';
import { API_URL } from './config';
import { receiveNewMessage } from 'providers/MessageProvider/actions';

export function initFireBase() {
  const firebaseConfig = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
  };

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

export const loginFirebase = () => new Promise(async(resolve, reject) => {
  try {
    firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        const customToken = await request(`${API_URL}/functions/createFireBaseToken`, {}, 'POST');
        const signInUser = await firebase.auth().signInWithCustomToken(_.get(customToken, 'result.token'));
        return resolve(signInUser);
      }

      return resolve(user);
    });
  } catch (err) {
    return reject(err);
  }
});

export const logoutFirebase = () => new Promise(async(resolve, reject) => {
  try {
    await firebase.auth().signOut();
    await firebase.database().ref().onDisconnect().cancel();
    await firebase.database().ref().off();
    return resolve();
  } catch (err) {
    return reject(err);
  }
});

export const getMessageList = eventId => new Promise(async(resolve, reject) => {
  try {
    const messageOrder = [];
    const data = await firebase.database()
      .ref('eventMessages')
      .child(eventId)
      .orderByChild('updatedAt')
      .limitToLast(10)
      .once('value');
    data.forEach(message => {
      messageOrder.push(message.key);
    });
    return resolve({
      messageList: messageOrder,
      data: data.val() || {}
    });
  } catch (err) {
    return reject(err);
  }
});

export const listenGetNewAddedMessage = eventId => {
  firebase.database()
    .ref('eventMessages')
    .child(eventId)
    .orderByChild('updatedAt')
    .limitToLast(1)
    .on('child_added', message => {
      window.store.dispatch(receiveNewMessage({
        key: message.key,
        data: { [message.key]: message.val() }
      }));
    });
};

export const stopListenGetNewAddedMessage = eventId => {
  firebase.database()
    .ref('eventMessages')
    .child(eventId)
    .off();
};

// for get more message
export const getMoreMessageList = (eventId, lastMessageTimestamp) => new Promise(async(resolve, reject) => {
  try {
    const messageOrder = [];
    const data = await firebase.database()
      .ref('eventMessages')
      .child(eventId)
      .orderByChild('updatedAt')
      .endAt(lastMessageTimestamp - 1)
      .limitToLast(10)
      .once('value');
    data.forEach(message => {
      messageOrder.push(message.key);
    });
    return resolve({
      messageList: messageOrder,
      data: data.val() || {}
    });
  } catch (err) {
    return reject(err);
  }
});
