import _ from 'lodash';

const makeSelectPosition = (state) => {
  return _.get(state, 'resource.Position');
};

const makeSelectJob = (state) => {
  return _.get(state, 'resource.Job');
};

const makeSelectPrefecture = (state) => {
  return _.get(state, 'resource.Prefecture');
};

const makeSelectContractType = (state) => {
  return _.get(state, 'resource.ContractType');
};

const makeSelectIncome = (state) => {
  const values = _.get(state, 'resource.Income');
  const options = [];
  values.forEach(value => options.push({ objectId: value.objectId, name: value.range }));
  return options;
};

const makeSelectAnswerForPresident = (state) => {
  const values = _.get(state, 'resource.AnswersForHow');
  return _.filter(values, { forRole: 'PRESIDENT' });
};

const makeSelectNumOfEmployees = (state) => {
  return _.get(state, 'resource.NOFEmployees');
};

const makeSelectBranchOffice = (state) => {
  return _.get(state, 'resource.BranchOffice');
};

const makeSelectIndustry = (state) => {
  return _.get(state, 'resource.Industry');
};

const makeSelectTag = (state) => {
  return _.get(state, 'resource.Tag');
};

const makeAnyResource = (state, resourceName) => {
  return _.get(state, `resource.${resourceName}`, []);
};

const makeSelectDuration = () => {
  return [
    {
      objectId: '60',
      name: '60 分'
    },
    {
      objectId: '90',
      name: '90 分'
    },
    {
      objectId: '120',
      name: '120 分'
    }
  ];
};

const makeSelectRecruitmentNumbers = () => {
  return [
    {
      objectId: '5',
      name: '5 人'
    },
    {
      objectId: '6',
      name: '6 人'
    },
    {
      objectId: '7',
      name: '7 人'
    },
    {
      objectId: '8',
      name: '8 人'
    },
    {
      objectId: '9',
      name: '9 人'
    },
    {
      objectId: '10',
      name: '10 人'
    }
  ];
};

const makeSelectYesNo = () => {
  return [
    {
      value: 'yes',
      name: 'あり'
    },
    {
      value: 'no',
      name: 'なし'
    }
  ];
};

const makeSelectGender = () => {
  return [
    {
      objectId: 'male',
      name: '男性'
    },
    {
      objectId: 'female',
      name: '女性'
    },
    {
      objectId: 'other',
      name: 'その他'
    }
  ];
};

const makeSelectEmploymentType = (state) => {
  return _.get(state, 'resource.employmentType');
};

export {
  makeSelectPosition,
  makeSelectPrefecture,
  makeSelectContractType,
  makeSelectIncome,
  makeSelectAnswerForPresident,
  makeSelectNumOfEmployees,
  makeSelectBranchOffice,
  makeSelectIndustry,
  makeSelectTag,
  makeSelectJob,
  makeAnyResource,
  makeSelectDuration,
  makeSelectRecruitmentNumbers,
  makeSelectYesNo,
  makeSelectGender,
  makeSelectEmploymentType
};