import {
  fetchSeekerListSuccess,
  fetchSeekerProfileSuccess,
  createSeekerSuccess,
  setSearchSeekerLoading
} from './actions';
import {
  FETCH_SEEKER_LIST,
  FETCH_SEEKER_PROFILE,
  CREATE_SEEKER
} from './constants';
import { put, takeLeading, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { push } from 'connected-react-router';
import request from 'utils/request';
import moment from 'moment';

function* fetchSeekerList(action) {
  try {
    yield put(setSearchSeekerLoading({loading: true}));

    const filter = action.payload || {};
    filter.limit = 10;

    if (filter.createdAt) {
      const createdAt = filter.createdAt;
      filter.fromDate = moment(createdAt).startOf('D').toDate();
      filter.toDate = moment(createdAt).endOf('D').toDate();
      delete filter.createdAt;
    }

    const { result } = yield call(request, 'functions/getSeekerList', action.payload);

    yield put(fetchSeekerListSuccess(result));
    yield put(setSearchSeekerLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* fetchSeekerProfile(action) {
  try {
    yield put(setSearchSeekerLoading({loading: true}));
    const response = yield call(request, 'functions/getTSInformation', { userId: action.payload });
    yield put(fetchSeekerProfileSuccess({ profile: response.result }));
    yield put(setSearchSeekerLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* createSeeker(action) {
  try {
    yield put(setSearchSeekerLoading({loading: true}));
    yield call(request, 'functions/createSeeker', action.payload);
    yield put(createSeekerSuccess({}));
    yield put(setSearchSeekerLoading({loading: false}));
    yield put(push('/dashboard/seeker'));
  } catch (e) {
    console.log(e);
    if (e.code === 202) {
      yield put(stopSubmit('createSeeker', { _error: 'このメールアドレスはすでに登録されています' }));
    } else {
      yield put(stopSubmit('createSeeker', { _error: e.message }));
    }
  }
}

export default function* watchSeeker() {
  yield takeLeading(FETCH_SEEKER_LIST, fetchSeekerList);
  yield takeLeading(FETCH_SEEKER_PROFILE, fetchSeekerProfile);
  yield takeLeading(CREATE_SEEKER, createSeeker);
}