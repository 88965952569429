import { connect } from 'react-redux';
import { customWithRouter } from 'components/HOC';
import {
  fetchRequestProfile,
  unsetRequestProfile
} from 'providers/RequestProvider/actions';
import { makeRequestProfile, makeRequestEvent } from 'providers/RequestProvider/selectors';
import RequestDetailPage from './RequestDetailPage';


const mapStateToProps = (state) => ({
  profile: makeRequestProfile(state),
  event: makeRequestEvent(state)
});


const mapDispatchToProps = (dispatch) => ({
  fetchRequestProfile: (params) => dispatch(fetchRequestProfile(params)),
  unsetRequestProfile: () => dispatch(unsetRequestProfile())
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(RequestDetailPage));