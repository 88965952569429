import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';

const { RangePicker } = DatePicker;

// input.value is moment format
class RangePickerField extends React.PureComponent {
  render() {
    const {
      label, placeholder, input,
      meta: { touched, invalid, error }
    } = this.props;
    delete input.onBlur;
    
    return (
      <Form.Item label={label} help={touched && invalid && error}>
        <RangePicker
          {...input}
          placeholder={placeholder}
          value={input.value}
          {...this.props}
        />
      </Form.Item>
    );
  }
}

RangePickerField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default RangePickerField;
