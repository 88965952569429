import { getResourceSuccess } from './actions';
import { GET_RESOURCE } from './constants';
import { put, takeLeading, call } from 'redux-saga/effects';
import request from 'utils/request';

function* handleGetResource() {
  try {
    const { result } = yield call(request, 'functions/getResources', {
      resources: [
        // 'Tag',
        'Prefecture',
        'Position',
        // 'NOFEmployees',
        'Location',
        'Job',
        // 'Industry',
        'Income',
        'ContractType',
        'SearchOption',
        'Menus',
        'EmploymentType'
        // 'BranchOffice',
        // 'AnswersForWhat',
        // 'AnswersForHow'
      ]
    });
    yield put(getResourceSuccess(result));
  } catch (e) {
    console.log(e);
  }
}

export default function* watchResource() {
  yield takeLeading(GET_RESOURCE, handleGetResource);
}
