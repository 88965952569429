import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {
  Card,
  Button
} from 'antd';
import Columns from 'components/Preset/Columns';
import SearchBar from 'components/SearchBar';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';

import './styles.scss';

const { FilterFields } = SearchBar;

class SearchCompanyPage extends React.PureComponent {
  eventStatus = {
    '/dashboard/event/published/search': 'ACTIVE',
    '/dashboard/event/locked/search': 'LOCKED',
    '/dashboard/event/draft/search': 'DRAFT',
    '/dashboard/event/finished/search': 'FINISHED',
    '/dashboard/event/canceled/search': 'CANCELED'
  }

  eventTitle = {
    '/dashboard/event/published/search': '募集中',
    '/dashboard/event/locked/search': '開催決定',
    '/dashboard/event/draft/search': '下書き',
    '/dashboard/event/finished/search': '終了',
    '/dashboard/event/canceled/search': 'キャンセル'
  }

  updateSearchToState = () => {
    this.props.updateSearchToState('searchEvent', this.props, this.props.fetchData, {
      status: this.eventStatus[this.props.pathname]
    });
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  getAction(record) {
    const { pathname, openPopup } = this.props;
    const eventStatus = this.eventStatus[pathname];
    if (eventStatus === 'DRAFT') {
      return (
        <div>
          <Link to={`create/${record}`}>編集</Link>
          <span 
            onClick={() => openPopup({
              title: '下書き中のイベントを削除する',
              content: 'このイベントを削除しますか？',
              okText: '削除',
              cancelText: 'キャンセル',
              okType: 'danger',
              confirm: async() => {
                await this.props.deleteDraftEvent(record);
                this.updateSearchToState();
              }
            })} 
            className='delete-btn second-btn'
          >
            削除する
          </span>
        </div>);
    }
    if (eventStatus === 'ACTIVE') {
      return (
        <div>
          <Link to={`detail/${record}`}>詳細を見る</Link>
          <Link to={`/dashboard/notifications/recommendEvent?eventId=${record}`}>
            <Button className='pr-event-button'>
              PRする
            </Button> 
          </Link>
        </div>
      );
    }
    return (
      <Link to={`detail/${record}`}>詳細を見る</Link>
    );
  }

  render() {
    const { total, loading, list } = this.props.listData;
    const { search, pathname } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;

    const filterFields = [
      FilterFields.eventTitle,
      FilterFields.position,
      FilterFields.companyName,
      FilterFields.eventDate
    ];
    if (pathname === '/dashboard/event/search') {
      filterFields.push(FilterFields.eventStatus);
    }

    const columns = [
      Columns.eventImage,
      Columns.eventTitle,
      Columns.position,
      Columns.eventCompanyName,
      Columns.eventDate,
      Columns.eventStatus,
      {
        title: 'アクション',
        dataIndex: 'objectId',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => this.getAction(record)
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Tenshoku Dashboard | Event | Search</title>
        </Helmet>
        <PageHeader
          title={this.eventTitle[this.props.pathname]}
          extra={(<Link to='../create'><div className='create-event-btn'>イベントを作成する</div></Link>)}
        />
        <div className="page-container" id="event-page-search">
          <Card>
            <div className="search-section">
              <SearchBar filterFields={filterFields}/>
            </div>
            <Table
              search={this.props.search}
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={this.onTableChange}
              pagination={{
                total: total,
                showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
                pageSize: 10,
                current: currentPage
              }}
              loading={loading}
            />
          </Card>
        </div>
        
      </Fragment>
    );
  }
}

SearchCompanyPage.propTypes = {
  fetchData: PropTypes.func.isRequired,
  listData: PropTypes.any,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func,
  openPopup: PropTypes.any,
  deleteDraftEvent: PropTypes.func
};

export default SearchCompanyPage;