import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import Columns from 'components/Preset/Columns';

import { Card, Button } from 'antd';
import SearchBar from 'components/SearchBar';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';

import './styles.scss';

const { FilterFields } = SearchBar;

class SearchCompanyPage extends React.PureComponent {

  updateSearchToState = () => {
    this.props.updateSearchToState('searchCompany', this.props, this.props.fetchCompanyList, this.props.match.params);
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  goToCreateCompanyPage = () => {
    this.props.push('/dashboard/company/create');
  }

  render() {
    const { total, loading, list } = this.props.companyList;
    const { search } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;
    
    const filterFields = [
      FilterFields.companyName,
      FilterFields.email,
      FilterFields.createdAt
    ];

    const columns = [
      {...Columns.roundedAvatar, dataIndex: 'companyImage'},
      Columns.companyName,
      Columns.email,
      Columns.createdAt,
      Columns.lastLogin,
      {
        title: 'アクション',
        dataIndex: 'objectId',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => (
          <Link to={`${this.props.history.location.pathname}/detail/${record}`}>
            プロフィールを見る
          </Link>
        )
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Tenshoku Dashboard | Company | Search</title>
        </Helmet>
        <PageHeader
          //goBack={'/dashboard/'}
          title={'企業一覧'}
          extra={(<Button type='primary' size='large' onClick={this.goToCreateCompanyPage}>アカウントを作成</Button>)}
          //breadcrumb={PageHeader.Bread.nailistSearch}
        />
        <div className="page-container" id="company-page-search">
          <Card>
            <div className="search-section">
              <SearchBar filterFields={filterFields}/>
            </div>
            <Table
              search={this.props.search}
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={this.onTableChange}
              pagination={{
                total: total,
                showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
                pageSize: 10,
                current: currentPage
              }}
              loading={loading}
            />
          </Card>
        </div>
      </Fragment>
    );
  }
}

SearchCompanyPage.propTypes = {
  fetchCompanyList: PropTypes.func.isRequired,
  companyList: PropTypes.any,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func
};

export default SearchCompanyPage;