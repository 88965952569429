import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {Card, Form, Affix} from 'antd';
import _get from 'lodash/get';
import { 
  TextField, TextArea, Select as SelectField, ExtraSelect, DatePicker, MonthPicker, TimePicker, MultiImage, SearchSelect
} from 'components/Form';
import './styles.scss';
import validate from './validate';

class TabDetail extends React.Component {
  render() {
    const { 
      positions, durations, prefectures, recruitmentNumbers, contractTypes, incomes, yesNoOptions, allActiveCompany,
      handleSubmit, error, pristine, submitting, invalid, isEditEvent, changeEditState, showCancelPopup, detail,
      employmentType
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        {!isEditEvent && _get(detail, 'status', '') === 'ACTIVE' && (
          <div className="export-csv-container">
            <div onClick={changeEditState} className="export-csv-button">編集</div>
          </div>
        )}

        <Card className="event-detail-tab">
          <div className="event-detail-tab-title">基本情報</div>

          <Field
            name="images"
            component={MultiImage}
            label="カバー写真"
            required
            disabled={!isEditEvent}
            type='EVENT'
          />
          
          <Field
            name="eventTitle"
            type="text"
            component={TextArea}
            label="イベントタイトル"
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
          />

          <Field
            name='position'
            type='text'
            component={SelectField}
            label='募集職種'
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
            options={positions}
          />

          <Field
            name='companyId'
            type='text'
            placeholder="企業名"
            component={SearchSelect}
            label='企業名'
            style={{ width: 468 }}
            required
            options={allActiveCompany}
            optionName='companyName'
            optionImage='companyImage'
            disabled
          />
        </Card >
        
        <Card className="event-detail-tab">
          <div className="event-detail-tab-title">イベント情報</div>

          <Field
            name='eventDate'
            type='text'
            component={DatePicker}
            label='開催日'
            style={{ width: 468 }}
            required
            disabled
            formatDatePicker='YYYY/MM/DD(ddd)'
          />

          <Field
            name='startTime'
            type='text'
            component={TimePicker}
            label='開始時間'
            style={{ width: 468 }}
            required
            disabled
            formatTimePicker='HH:mm'
          />

          <Field
            name='duration'
            type='text'
            component={SelectField}
            label='開催時間'
            style={{ width: 468 }}
            required
            disabled
            options={durations}
          />
          <Field
            name='eventArea'
            type='text'
            component={SelectField}
            label='開催エリア'
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
            options={prefectures}
          />

          <Field
            name="restaurantName"
            type="text"
            component={TextField}
            label="開催場所（店名)"
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
          />

          <Field
            name='recruitmentNumbers'
            type='text'
            component={SelectField}
            label='募集人数'
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
            options={recruitmentNumbers}
          />
        </Card >

        <Card className="event-detail-tab">
          <div className="event-detail-tab-title">詳細情報 </div>

          <Field
            name="eventDetail"
            type="text"
            component={TextArea}
            label="内容"
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
          />
          <Field
            name="idealCandidate"
            type="text"
            component={TextArea}
            label="求める人物像"
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
          />
          <Field
            name="expectedSkill"
            type="text"
            component={TextArea}
            label="求めるスキル・経験"
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
          />

          <Field
            name='employmentType'
            type='text'
            component={SelectField}
            label='雇用形態'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={employmentType}
          />

          <Field
            name='expectedDate'
            type='text'
            component={MonthPicker}
            label='入社時期'
            style={{ width: 468 }}
            required
            disabled={!isEditEvent}
            formatMonthPicker='YYYY年MM月'
          />

          <Field
            name='contractType'
            type='text'
            component={SelectField}
            label='雇用形態'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={contractTypes}
          />
          <Field
            name='annualIncome'
            type='text'
            component={SelectField}
            label='給与'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={incomes}
          />

          <Field
            name="bonus"
            type="text"
            component={TextArea}
            label="昇給・賞与"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />

          <Field
            name="allowance"
            type="text"
            component={TextField}
            label="諸手当"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />

          <Field
            name="annualLeave"
            type="text"
            component={TextArea}
            label="休日休暇"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />
          <Field
            name="benefit"
            type="text"
            component={TextArea}
            label="福利厚生"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />

          <Field
            name="trainingProgram"
            type="text"
            component={TextField}
            label="研修内容"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />
          <Field
            name="officeHours"
            type="text"
            component={TextField}
            label="勤務時間"
            style={{ width: 468 }}
            disabled={!isEditEvent}
          />

          <Field
            name='workLocation'
            type='text'
            component={SelectField}
            label='勤務地'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={prefectures}
          />

          <Field
            name='canTransfer'
            type='text'
            component={ExtraSelect}
            label='転勤'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={yesNoOptions}
          />
          <Field
            name='housingAllowance'
            type='text'
            component={ExtraSelect}
            label='住宅手当'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={yesNoOptions}
          />
          <Field
            name='companyHousing'
            type='text'
            component={ExtraSelect}
            label='寮、社宅提供'
            style={{ width: 468 }}
            disabled={!isEditEvent}
            options={yesNoOptions}
          />
        </Card >

        {isEditEvent && (<Affix offsetBottom={0}>
          <div className='create-event-button-container'>
            <button 
              type='button' 
              className='ten-ad-btn cancel-button' 
              onClick={() => showCancelPopup()}
            >
              キャンセル
            </button>

            <button 
              type='submit' 
              className='ten-ad-btn primary-button' 
              disabled={error || submitting || invalid || pristine}
            >
              完了
            </button>
          </div>
        </Affix>)}
      </Form>
    );
  }
}

TabDetail.propTypes = {
  positions: PropTypes.array,
  durations: PropTypes.array,
  prefectures: PropTypes.array,
  recruitmentNumbers: PropTypes.array,
  contractTypes: PropTypes.array,
  incomes: PropTypes.array,
  employmentType: PropTypes.array,
  yesNoOptions: PropTypes.array,
  allActiveCompany: PropTypes.array,
  handleSubmit: PropTypes.func,
  changeEditState: PropTypes.func,
  showCancelPopup: PropTypes.func,
  location: PropTypes.any,
  history: PropTypes.any,
  error: PropTypes.any,
  isEditEvent: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  detail: PropTypes.object
};

export default reduxForm({ 
  form: 'createEvent', 
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(TabDetail);