import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSeekerProfile } from 'providers/SeekerProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';


class ButtonRequestManager extends React.Component {

  fetchData = () => {
    this.props.fetchSeekerProfile(this.props.seekerId);
    if (this.props.action === 'ACCEPT') {
      this.props.openPopupAccept();
    }
    if (this.props.action === 'DENY') {
      this.props.openPopupDeny();
    }
  };

  render() {
    return (
      <div>
        {this.props.render(this.fetchData)}
      </div>
    );
  }
}

ButtonRequestManager.propTypes = {
  render: PropTypes.func,
  seekerId: PropTypes.string,
  openPopupDeny: PropTypes.func,
  openPopupAccept: PropTypes.func,
  fetchSeekerProfile: PropTypes.func,
  action: PropTypes.string.isRequired // ['ACCEPT', 'DENY']
};

const mapDispatchToProps = (dispatch) => ({
  fetchSeekerProfile: (seekerId) => dispatch(fetchSeekerProfile(seekerId)),
  openPopupDeny: () => dispatch(SetPopup({name: 'denyJoinEventRequest', show: true})),
  openPopupAccept: () => dispatch(SetPopup({name: 'acceptJoinEventRequest', show: true}))
});

export default connect(null, mapDispatchToProps)(ButtonRequestManager);