import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import sample from 'providers/SampleProvider/reducer';
import layout from 'providers/LayoutProvider/reducer';
import auth from 'providers/AuthProvider/reducer';
import popup from 'providers/PopupProvider/reducer';
import company from 'providers/CompanyProvider/reducer';
import seeker from 'providers/SeekerProvider/reducer';
import event from 'providers/EventProvider/reducer';
import request from 'providers/RequestProvider/reducer';
import resource from 'providers/ResourceProvider/reducer';
import message from 'providers/MessageProvider/reducer';
import notification from 'providers/NotificationProvider/reducer';
import plan from 'providers/PlanProvider/reducer';

export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    layout,
    sample,
    auth,
    popup,
    company,
    seeker,
    event,
    request,
    resource,
    message,
    notification,
    plan
  });
}
