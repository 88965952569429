/* eslint-disable func-style */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
// import Image from 'components/Image';
import moment from 'moment';
import IconAgeSmall from 'assets/icons/age-small.svg';
import Dot from 'assets/icons/dot.svg';
import { Avatar } from 'antd';
import './style.scss';

const TenshokuInfo = function(props) {
  const { tenshokuInfo } = props;
  return (
    <React.Fragment>
      <div className='container-selected-tenshoku'>
        <div className='tenshoku-wrapper-payment'>
          <div>
            <Avatar size={64} icon="picture" src={_.get(tenshokuInfo, 'avatar.thumbSmall', '')} className='avatar'/>
          </div>
          <div className='tenshoku-info-wrapper-payment'>
            <div className='tenshoku-info-payment'>
              <div className='tenshoku-name'>{tenshokuInfo.kanjiFullName}</div>
              <div>
                <img alt='icon-age' src={IconAgeSmall} />
                {moment(new Date()).diff(moment(tenshokuInfo.birthDay), 'years')}
                才
              </div>
            </div>
            <div className='tenshoku-info-payment-below'>
              <div className='info-container'>
                <div className='info-label'>最終学歴</div>
                <div className='text-second-row info'>{_.get(tenshokuInfo, 'latestAcademic')}</div>
              </div>
              {tenshokuInfo.certificate &&
                    (
                      <div className='info-container'>
                        <div className='dot'><img src={Dot} alt='Dot' /></div>
                        <div className='info-label'>資格</div>
                        <div className='text-second-row'>{_.get(tenshokuInfo, 'certificate')}</div>
                      </div>
                    )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TenshokuInfo.propTypes = {
  tenshokuInfo: PropTypes.object
};

export default TenshokuInfo;
