import {
  FETCH_EVENT_LIST,
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_EVENT_DETAIL,
  FETCH_EVENT_DETAIL_SUCCESS,
  FETCH_EVENT_DETAIL_FAILURE,
  FETCH_ALL_ACTIVE_COMPANY,
  FETCH_ALL_ACTIVE_COMPANY_SUCCESS,
  CREATE_EVENT,
  CREATE_EVENT_SUCCESS,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  SET_LOADING_SEARCH_EVENT,
  UNSET_EVENT_DETAIL,
  DELETE_DRAFT_EVENT,
  DELETE_DRAFT_EVENT_SUCCESS,
  LOCK_EVENT_REQUEST,
  LOCK_EVENT_SUCCESS,
  FETCH_REVIEWED_EVENT,
  FETCH_REVIEWED_EVENT_SUCCESS,
  FETCH_MORE_REVIEWED_EVENT_SUCCESS
} from './constants';

export function fetchEventList(payload = {}) {
  return {type: FETCH_EVENT_LIST, payload};
}

export function fetchEventListSuccess(payload = {}) {
  return {type: FETCH_EVENT_LIST_SUCCESS, payload};
}

export function fetchEventDetail(payload = {}) {
  return {
    type: FETCH_EVENT_DETAIL, 
    payload,
    meta: {
      thunk: true
    }
  };
}

export function fetchEventDetailSuccess(payload = {}, meta) {
  return {
    type: FETCH_EVENT_DETAIL_SUCCESS,
    payload,
    meta
  };
}

export function fetchEventDetailError(payload = {}, meta) {
  return {
    type: FETCH_EVENT_DETAIL_FAILURE,
    payload,
    meta
  };
}

export function fetchAllActiveCompany() {
  return {type: FETCH_ALL_ACTIVE_COMPANY};
}

export function fetchAllActiveCompanySuccess(payload = {}) {
  return {type: FETCH_ALL_ACTIVE_COMPANY_SUCCESS, payload};
}

export function createEvent(payload = {}) {
  return {type: CREATE_EVENT, payload, meta: { thunk: true }};
}

export function createEventSuccess(meta) {
  return {type: CREATE_EVENT_SUCCESS, meta};
}

export function updateEvent(payload = {}) {
  return {type: UPDATE_EVENT, payload, meta: { thunk: true }};    
}

export function updateEventSuccess(meta) {
  return {type: UPDATE_EVENT_SUCCESS, meta};
}

export function setSearchEventLoading(payload = {}) {
  return {type: SET_LOADING_SEARCH_EVENT, payload};
}

export function unsetEventDetail() {
  return {type: UNSET_EVENT_DETAIL};
}

export function deleteDraftEvent(payload = {}) {
  return {type: DELETE_DRAFT_EVENT, payload, meta: { thunk: true }};
}

export function deleteDraftEventSusccess(meta) {
  return {type: DELETE_DRAFT_EVENT_SUCCESS, meta};
}

export function lockEventRequest(payload = {}) {
  return {type: LOCK_EVENT_REQUEST, payload, meta: { thunk: true }};
}

export function lockEventSuccess(meta) {
  return {type: LOCK_EVENT_SUCCESS, meta};
}

export function fetchReviewedEvent(payload = {}) {
  return {type: FETCH_REVIEWED_EVENT, payload};
}

export function fetchReviewedEventSuccess(payload = {}) {
  return {type: FETCH_REVIEWED_EVENT_SUCCESS, payload};
}

export function fetchMoreReviewedEventSuccess(payload = {}) {
  return {type: FETCH_MORE_REVIEWED_EVENT_SUCCESS, payload};
}