import { connect } from 'react-redux';

import { fetchCompanyList } from 'providers/CompanyProvider/actions';
import { makeCompanyList } from 'providers/CompanyProvider/selectors';
import { customWithRouter } from 'components/HOC';

import SearchCompanyPage from './SearchCompanyPage';


const mapStateToProps = (state) => ({
  companyList: makeCompanyList(state),
});


const mapDispatchToProps = (dispatch) => ({
  fetchCompanyList: (params) => dispatch(fetchCompanyList(params)),
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(SearchCompanyPage));