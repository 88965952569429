import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import RecommendEvent from './RecommendEvent';

const Notifications = (props) => {
  const { match } = props;

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${match.path}/recommendEvent`} component={RecommendEvent}/>
        <Redirect to={`${match.path}/recommendEvent`} />
      </Switch>        
    </React.Fragment>
  );
};

Notifications.propTypes = {
  match: PropTypes.object
};

export default Notifications;