import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { uploadFile, createImage } from 'utils/helpers';
import ImageIcon from 'assets/icons/image.svg';

class MessageFileInput extends React.Component {
  customRequest = async option => {
    const { file } = option;
    const image = await uploadFile(file);

    if (image) {
      await createImage({
        imageObject: image,
        type: 'MESSAGE',
        name: image.name
      });

      this.props.sendImage(image.url);
    }
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return isJpgOrPng && isLt20M;
  }

  render() {
    return (
      <Upload
        beforeUpload={this.beforeUpload}
        customRequest={this.customRequest}
        showUploadList={false}
      >
        <img alt='icon' src={ImageIcon} />
      </Upload>
    );
  }
}

MessageFileInput.propTypes = {
  sendImage: PropTypes.func
};

export default MessageFileInput;