import {
  fetchEventListSuccess,
  fetchEventDetailSuccess,
  fetchEventDetailError,
  fetchAllActiveCompanySuccess,
  createEventSuccess,
  updateEventSuccess,
  setSearchEventLoading,
  deleteDraftEventSusccess,
  lockEventSuccess,
  fetchReviewedEventSuccess,
  fetchMoreReviewedEventSuccess
} from './actions';
import {
  FETCH_EVENT_LIST,
  FETCH_EVENT_DETAIL,
  FETCH_ALL_ACTIVE_COMPANY,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_DRAFT_EVENT,
  LOCK_EVENT_REQUEST,
  FETCH_REVIEWED_EVENT
} from './constants';
import _get from 'lodash/get';
import { put, takeLeading, call } from 'redux-saga/effects';
import request from 'utils/request';
import moment from 'moment';

function* fetchNalistList(action) {
  try {
    yield put(setSearchEventLoading({loading: true}));

    const filter = action.payload || {};
    filter.limit = 10;

    if (filter.eventDate) {
      const eventDate = filter.eventDate;
      filter.fromDate = moment(eventDate).startOf('D').toDate();
      filter.toDate = moment(eventDate).endOf('D').toDate();
      delete filter.eventDate;
    }

    const { result } = yield call(request, 'functions/getEventList', filter);
    yield put(fetchEventListSuccess(result));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* fetchEventDetail(action) {
  try {
    yield put(setSearchEventLoading({loading: true}));
    const response = yield call(request, 'functions/eventDetailForPublicPage', { eventId: action.payload });
    yield put(fetchEventDetailSuccess({ detail: response.result }, _get(action, 'meta', {})));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    yield put(fetchEventDetailError(e, _get(action, 'meta', {})));
  }
}

function* fetchAllActiveCompany() {
  try {
    yield put(setSearchEventLoading({loading: true}));
    const response = yield call(request, 'functions/getAllActiveCompany', {});
    yield put(fetchAllActiveCompanySuccess({ company: response.result }));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function * handleCreateEvent(action) {
  try {
    yield put(setSearchEventLoading({loading: true}));
    yield call(request, 'functions/createEvent', action.payload);
    yield put(createEventSuccess(action.meta));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function * handleUpdateEvent(action) {
  try {
    yield put(setSearchEventLoading({loading: true}));
    yield call(request, 'functions/updateEvent', action.payload);
    yield put(updateEventSuccess(action.meta));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* deleteDraftEvent(action) {
  try {
    yield put(setSearchEventLoading({loading: true}));
    yield call(request, 'functions/deleteDraftEvent', { eventId: action.payload });
    yield put(deleteDraftEventSusccess(action.meta));
    yield put(setSearchEventLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* lockEvent(action) {
  try {
    yield call(request, 'functions/lockEvent', { eventId: action.payload });
    yield put(lockEventSuccess(action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* fetchReviewedEvent(action) {
  try {
    const { result } = yield call(request, 'functions/getEventReview', action.payload);
    if (action.payload.page !== 1) yield put(fetchMoreReviewedEventSuccess(result));
    else yield put(fetchReviewedEventSuccess(result));
  } catch (e) {
    console.log(e);
  }
}

export default function* watchEvent() {
  yield takeLeading(FETCH_EVENT_LIST, fetchNalistList);
  yield takeLeading(FETCH_EVENT_DETAIL, fetchEventDetail);
  yield takeLeading(FETCH_ALL_ACTIVE_COMPANY, fetchAllActiveCompany);
  yield takeLeading(CREATE_EVENT, handleCreateEvent);
  yield takeLeading(UPDATE_EVENT, handleUpdateEvent);
  yield takeLeading(DELETE_DRAFT_EVENT, deleteDraftEvent);
  yield takeLeading(LOCK_EVENT_REQUEST, lockEvent);
  yield takeLeading(FETCH_REVIEWED_EVENT, fetchReviewedEvent);
}
