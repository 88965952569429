import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { customWithRouter } from 'components/HOC';
import {
  fetchSeekerProfile,
  unsetSeekerProfile
} from 'providers/SeekerProvider/actions';
import { updateUserStatus } from 'providers/AuthProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeSeekerProfile } from 'providers/SeekerProvider/selectors';
import SeekerDetailPage from './SeekerDetailPage';


const mapStateToProps = (state) => ({
  profile: makeSeekerProfile(state)
});


const mapDispatchToProps = (dispatch) => ({
  destroyForm: (form) => dispatch(destroy(form)),
  updateUserStatus: (params) => dispatch(updateUserStatus(params)),
  openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
  fetchSeekerProfile: (params) => dispatch(fetchSeekerProfile(params)),
  unsetSeekerProfile: () => dispatch(unsetSeekerProfile())
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(SeekerDetailPage));