import React from 'react';
import PropTypes from 'prop-types';
import { Upload, Modal, Form, message } from 'antd';
import Label from '../Label';
import Add from 'assets/icons/add.svg';
import LoadingLogo from 'components/LoadingLogo';
import { uploadFile, createImage } from 'utils/helpers';
import { handleFocus } from 'utils/reduxForm';

class MultiImage extends React.Component {
  state = {
    loading: false,
    previewVisible: false,
    previewImage: ''
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    this.setState({
      previewImage: file.url,
      previewVisible: true
    });
  };

  handleChange = image => {
    this.props.input.onChange([...this.props.input.value, image]);
  }

  customRequest = async option => {
    const { file } = option;

    this.setState({ loading: true });

    const image = await uploadFile(file);

    if (image) {
      const newImage = await createImage({
        imageObject: image,
        type: this.props.type,
        name: image.name
      });

      this.setState({ loading: false });
      this.handleChange({ objectId: newImage.objectId, url: image.url });
    }
  }

  handleRemove = file => {
    this.props.input.onChange(this.props.input.value.filter(image => image.objectId !== file.uid));
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return isJpgOrPng && isLt20M;
  }

  render() {
    const { previewVisible, previewImage, loading } = this.state;
    const {
      label, input, required, disabled,
      meta: { touched, invalid, error }
    } = this.props;
    const images = input.value && input.value.map(image => ({ 
      uid: image.objectId,
      url: image.thumbSmall || image.url
    }));

    return (
      <Form.Item 
        colon={false} 
        label={<Label label={label} required={required} />} 
        help={touched && invalid && error} 
        validateStatus={touched && invalid && error && 'error'} 
        onFocus={() => handleFocus(this.props)}
      >
        <Upload
          listType="picture-card"
          fileList={images}
          beforeUpload={this.beforeUpload}
          onPreview={this.handlePreview}
          onRemove={this.handleRemove}
          customRequest={this.customRequest}
          disabled={disabled}
          className={images.length % 2 === 0 ? '' : `odd-list-${images.length}`}
        >
          {images.length < 5 && !disabled && (
            loading ? (<LoadingLogo />) : (<img alt='icon' src={Add} />)
          )}
        </Upload>

        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Form.Item>
    );
  }
}

MultiImage.propTypes = {
  input: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.object.isRequired
};

export default MultiImage;