import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import Label from '../Label';

class TextField extends React.PureComponent {
  render() {
    const {
      label, placeholder, input, type, size, prefix, style, required, disabled, maxLength, autoComplete, className = '',
      meta: { touched, invalid, error }
    } = this.props;

    return (
      <Form.Item 
        colon={false} 
        label={label && (<Label label={label} required={required} />)} 
        help={touched && invalid && error}
        validateStatus={touched && invalid && error ? 'error' : ''}
        className={className}
      >
        <Input
          {...input}
          placeholder={placeholder}
          type={type}
          value={input.value}
          size={size}
          prefix={prefix}
          style={style}
          disabled={disabled}
          maxLength={maxLength}
          autoComplete={autoComplete}
        />
      </Form.Item>
    );
  }
}

TextField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  size: PropTypes.any,
  prefix: PropTypes.any,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default TextField;
