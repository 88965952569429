import { connect } from 'react-redux';
import { customWithRouter } from 'components/HOC';
import { 
  makeSelectParticipant, 
  makeSelectMessageList, 
  makeSelectMessageData, 
  makeSelectLoadMore, 
  makeSelectHaveNewMessage, 
  makeSelectIsLoading
} from 'providers/MessageProvider/selectors';
import { makeMe } from 'providers/AuthProvider/selectors';
import { fetchMoreMessage, readNewMessage } from 'providers/MessageProvider/actions';
import MessageList from './MessageList';

const mapStateToProps = (state) => ({
  participant: makeSelectParticipant(state),
  messageList: makeSelectMessageList(state),
  messageData: makeSelectMessageData(state),
  loadMore: makeSelectLoadMore(state),
  haveNewMessage: makeSelectHaveNewMessage(state),
  isLoading: makeSelectIsLoading(state),
  currentUser: makeMe(state)
});

const mapDispatchToProps = (dispatch) => ({
  getMoreMessage: eventId => dispatch(fetchMoreMessage({ eventId })),
  readNewMessage: () => dispatch(readNewMessage())
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(MessageList));