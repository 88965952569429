import { connect } from 'react-redux';
import { customWithRouter } from 'components/HOC';
import { fetchMessage, sendMessage, resetMessage } from 'providers/MessageProvider/actions';
import Message from './Message';

const mapDispatchToProps = (dispatch) => ({
  getMessage: eventId => dispatch(fetchMessage({ eventId })),
  sendMessage: payload => dispatch(sendMessage(payload)),
  resetMessage: () => dispatch(resetMessage())
});

export default customWithRouter(connect(null, mapDispatchToProps)(Message));