import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
//import './styles.scss';

class CustomBreadcrumb extends React.PureComponent {
  render() {
    let { breadcrumb = [] } = this.props;
    _.isFunction(breadcrumb) && (breadcrumb = breadcrumb());

    return (
      <Breadcrumb style={{ margin: '0 0 8px' }}>
        {
          breadcrumb.map((crumb, i) => (
            crumb.to && (i !== breadcrumb.length - 1) ? 
              <Breadcrumb.Item><Link to={crumb.to}>{crumb.label}</Link></Breadcrumb.Item> :
              <Breadcrumb.Item><span>{crumb.label}</span></Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
    );
  }
}

export default CustomBreadcrumb;