import React from 'react';
import { Card, Button, Modal, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import { makeCompanyPurchangedMenus } from 'providers/CompanyProvider/selectors';
import {
  fetchCompanyProfile
} from 'providers/CompanyProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import Table from 'components/Table';
import {
  addPlanRequest,
  getPlansRequest,
  removePlanRequest
} from 'providers/PlanProvider/actions';
import { customWithRouter, withResource } from 'components/HOC';
import UpdatePlanForm from './UpdatePlanForm';

const PLAN_TEXT_TRANS = {
  'SHACHO-PLAN': '社長プラン',
  'STANDARD-PLAN': 'スタンダードプラン',
  'PREMIUM-PLAN': 'プレミアムプラン'
};

class TabPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: null,
      isOpen: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCancelModal = this.handleCancelModal.bind(this);
    this.handleAfterCloseModal = this.handleAfterCloseModal.bind(this);
    this.handleUpdatePlan = this.handleUpdatePlan.bind(this);
    this.handleRemovePlan = this.handleRemovePlan.bind(this);
    this.planOptions = this.getPlanOptions(props.__searchOption);
  }

  updateSearchToState = () => {
    this.props.updateSearchToState('planTab', this.props, this.props.getPlans, {
      companyId: this.props.companyId
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  componentWillUnmount() {
    this.props.replace(this.props.pathname);
  }

  handleOpenModal(plan = null) {
    this.setState({
      isOpen: true,
      selectedPlan: plan
    });
  }

  handleCancelModal() {
    this.setState({
      isOpen: false
    });
  }

  handleAfterCloseModal() {
    if (this.state.selectedPlan) {
      this.setState({
        selectedPlan: null
      });
    }
  }

  handleUpdatePlan(values) {
    const { companyId, search } = this.props;
    this.props
      .addPlan({
        isEdit: !!this.state.selectedPlan,
        companyId: companyId,
        planId: this.state.selectedPlan ? this.state.selectedPlan._id : '',
        ...values
      })
      .then(() => {
        this.setState({
          isOpen: false
        });
        this.props.getPlans(search);
        this.props.fetchCompanyProfile(this.props.companyId);
      });
  }

  handleRemovePlan(plan = null) {
    if (!plan) return;
    const { companyId, search } = this.props;
    this.props.openPopup({
      title: '',
      content: '本当に削除しますか？',
      okText: '確定',
      cancelText: 'キャンセル',
      confirm: () => {
        this.props.removePlan({
          planId: plan._id,
          companyId
        }).then(() => {
          this.props.getPlans(search);

        });
      }
    });
  }

  getPlanOptions = (list = []) => {
    return list.reduce((acc, curr) => {
      acc[curr._id] = curr.name;
      return acc;
    }, {});
  }

  render() {
    const currentPage = _.get(this.props.search, 'page')
      ? Number(this.props.search.page)
      : 1;
    const initialValues = this.state.selectedPlan
      ? { 
        typeOfPlan: 'SHACHO-PLAN',
        menus: this.props.purchangedMenus || [],
        ...this.state.selectedPlan
      } : {
        name: '',
        total: null,
        startDate: moment().toString(),
        typeOfPlan: 'SHACHO-PLAN'
      };

    const columns = [
      {
        title: 'プランID',
        dataIndex: '_id'
      },
      {
        title: 'プラン名',
        dataIndex: 'name'
      },
      {
        title: 'プラン枠数',
        dataIndex: 'total',
        sorter: true
      },
      {
        title: 'プラン残数',
        dataIndex: 'remain',
        sorter: true
      },
      {
        title: '開始日',
        dataIndex: 'startDate',
        sorter: true,
        render: (startDate) =>
          (startDate ? moment(startDate).format('YYYY/MM/DD HH:mm') : '--')
      },
      {
        title: 'オプション',
        dataIndex: 'availableOptions',
        width: 250,
        render: (availableOptions) => {
          return availableOptions.map((option) => this.planOptions[option] || option).join(', ');
        }
      },
      {
        title: '契約タイプ',
        dataIndex: 'typeOfPlan',
        render: (typeOfPlan) => PLAN_TEXT_TRANS[typeOfPlan]
      },
      {
        title: '終了日',
        dataIndex: 'endDate',
        sorter: true,
        render: (endDate) =>
          (endDate ? moment(endDate).format('YYYY/MM/DD HH:mm') : '--')
      },
      {
        key: 'Action',
        fixed: 'right',
        render: (record) => (
          <Row type="flex" gutter={8}>
            <Col>
              <Button onClick={() => this.handleRemovePlan(record)}>
                削除
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => this.handleOpenModal(record)}
              >
                編集
              </Button>
            </Col>
          </Row>
        )
      }
    ];

    return (
      <Card>
        <Row
          justify="space-between"
          type="flex"
          align="middle"
          style={{ marginBottom: '16px' }}
        >
          <h3 style={{ fontSize: '16px', fontWeight: 'bold' }}>
            登録済スカウトプラン一覧
          </h3>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              if (this.props.list.length < 1) {
                this.handleOpenModal();
              } else {
                message.error('プランは複数で設定できません。');

              }
            }}
          >
            プランを追加
          </Button>
        </Row>
        <Table
          search={this.props.search}
          columns={columns}
          dataSource={this.props.list}
          scroll={{ x: 'max-content' }}
          rowKey="_id"
          onChange={this.onTableChange}
          pagination={{
            total: this.props.total,
            showTotal: (total, range) =>
              `${total}中${range[0]}-${range[1]}項目を表示`,
            pageSize: 20,
            current: currentPage
          }}
          loading={this.props.isLoading}
        />
        <Modal
          title={
            <p style={{ fontWeight: 'bold', margin: 0 }}>
              {this.state.selectedPlan ? 'プラン編集' : 'プラン追加'}
            </p>
          }
          visible={this.state.isOpen}
          afterClose={this.handleAfterCloseModal}
          destroyOnClose
          footer={null}
          onCancel={this.handleCancelModal}
          maskClosable={false}
        >
          <UpdatePlanForm
            isEdit={!!this.state.selectedPlan}
            initialValues={initialValues}
            isLoading={this.props.isLoading}
            purchangedMenus={this.props.purchangedMenus}
            optionMenus={this.props.__menus}
            onCancel={this.handleCancelModal}
            onSubmit={this.handleUpdatePlan}
          />
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.plan.isLoading,
  list: state.plan.plans,
  total: state.plan.totalPlan,
  purchangedMenus: makeCompanyPurchangedMenus(state)

});

const mapDispatchToProps = (dispatch) => ({
  addPlan: (params) => dispatch(addPlanRequest(params)),
  getPlans: (params) => dispatch(getPlansRequest(params)),
  removePlan: (params) => dispatch(removePlanRequest(params)),
  openPopup: (data) => dispatch(SetPopup({ name: 'confirmPopup', show: data })),
  fetchCompanyProfile: (params) => dispatch(fetchCompanyProfile(params))
});

TabPlan.propTypes = {
  total: PropTypes.number,
  companyId: PropTypes.string,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  getPlans: PropTypes.func,
  addPlan: PropTypes.func,
  search: PropTypes.object,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func,
  location: PropTypes.object,
  removePlan: PropTypes.func,
  openPopup: PropTypes.func,
  replace: PropTypes.func,
  pathname: PropTypes.string,
  purchangedMenus: PropTypes.array,
  __searchOption: PropTypes.array,
  __menus: PropTypes.array,
  match: PropTypes.object,
  fetchCompanyProfile: PropTypes.func
};

export default compose(
  withRouter,
  customWithRouter,
  withResource(['searchOption', 'menus']),
  connect(mapStateToProps, mapDispatchToProps)
)(TabPlan);
