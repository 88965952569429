import React from 'react';
import PropTypes from 'prop-types';

class Label extends React.PureComponent {
  render() {
    const { label, required } = this.props;

    if (!label) {
      return null;
    }

    return (
      required ? (
        <span>
          <span className='required-icon'>*</span>
          <span>{label}</span>
        </span>
      ) : label
    );
  }
}

Label.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool
};

export default Label;
