import {
  FETCH_COMPANY_LIST,
  FETCH_COMPANY_LIST_SUCCESS,
  FETCH_COMPANY_PROFILE,
  FETCH_COMPANY_PROFILE_SUCCESS,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  SET_LOADING_SEARCH_COMPANY,
  UNSET_COMPANY_PROFILE,
  APPROVE_COMPANY_ACCOUNT,
  APPROVE_COMPANY_ACCOUNT_SUCCESS,
  APPROVE_COMPANY_ACCOUNT_FAILURE
} from './constants';

export function fetchCompanyList(payload = {}) {
  return { type: FETCH_COMPANY_LIST, payload };
}

export function fetchCompanyListSuccess(payload = {}) {
  return { type: FETCH_COMPANY_LIST_SUCCESS, payload };
}

export function fetchCompanyProfile(payload = {}) {
  return { type: FETCH_COMPANY_PROFILE, payload };
}

export function fetchCompanyProfileSuccess(payload = {}) {
  return { type: FETCH_COMPANY_PROFILE_SUCCESS, payload };
}

export function createCompany(payload = {}) {
  return { type: CREATE_COMPANY, payload };
}

export function createCompanySuccess(payload = {}) {
  return { type: CREATE_COMPANY_SUCCESS, payload };
}

export function setSearchCompanyLoading(payload = {}) {
  return { type: SET_LOADING_SEARCH_COMPANY, payload };
}

export function unsetCompanyProfile() {
  return { type: UNSET_COMPANY_PROFILE };
}

export function requestApproveCompanyAccount(payload = {}) {
  return { type: APPROVE_COMPANY_ACCOUNT, payload, meta: { thunk: true } };
}

export function requestApproveCompanyAccountSuccess({ meta, payload }) {
  return { type: APPROVE_COMPANY_ACCOUNT_SUCCESS, meta, payload };
}

export function requestApproveCompanyAccountFailure({ meta, payload }) {
  return { type: APPROVE_COMPANY_ACCOUNT_FAILURE, meta, payload, error: true };
}