import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TenshokuInfo from 'components/TenshokuInfo';

import { Modal, Button } from 'antd';

export class ModalDeny extends React.Component {
  
  hideModal = () => {
    this.props.closePopup();
    this.props.unsetSeekerProfile();
  };

  denyRequest = async(profile, eventId) => {
    await this.props.denyTenshokusha({ eventId, userId: profile.objectId });
    await this.props.updateStatusOnScreen();
    this.hideModal();
  };

  render() {
    const {
      profile,
      eventId,
      popupState
    } = this.props;

    return (
      <Modal
        title="応募お断り"
        centered
        visible={popupState}
        onOk={this.hideModal}
        onCancel={this.hideModal}
        footer={[
          <Button 
            key="submit"
            onClick={this.hideModal}
          >
            戻る
          </Button>,
          <Button
            key="submit"
            type="danger"
            onClick={() => this.denyRequest(profile, eventId)}
          >
            お断りする
          </Button>
        ]}
      >
        <p>この応募をお断りしますか？</p>
        {_.isEmpty(profile) ?
          <div style={{height: '96px'}}/>
          :
          <TenshokuInfo tenshokuInfo={profile}/>
        }
      </Modal>
    );
  }
}

ModalDeny.propTypes = {
  eventId: PropTypes.string,
  profile: PropTypes.object,
  popupState: PropTypes.bool,
  closePopup: PropTypes.func,
  denyTenshokusha: PropTypes.func,
  unsetSeekerProfile: PropTypes.func,
  updateStatusOnScreen: PropTypes.func
};

export default ModalDeny;
