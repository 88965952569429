import {
  fetchCompanyListSuccess,
  fetchCompanyProfileSuccess,
  setSearchCompanyLoading,
  createCompanySuccess,
  requestApproveCompanyAccountSuccess,
  requestApproveCompanyAccountFailure
} from './actions';
import {
  FETCH_COMPANY_LIST,
  FETCH_COMPANY_PROFILE,
  CREATE_COMPANY,
  APPROVE_COMPANY_ACCOUNT
} from './constants';
import { put, takeLeading, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { push } from 'connected-react-router';
import request from 'utils/request';
import moment from 'moment';

function* fetchCompanyList(action) {
  try {
    yield put(setSearchCompanyLoading({ loading: true }));

    const filter = action.payload || {};
    filter.limit = 10;

    if (filter.createdAt) {
      const createdAt = filter.createdAt;
      filter.fromDate = moment(createdAt).startOf('D').toDate();
      filter.toDate = moment(createdAt).endOf('D').toDate();
      delete filter.createdAt;
    }

    const { result } = yield call(request, 'functions/getCompanyList', action.payload);

    yield put(fetchCompanyListSuccess(result));
    yield put(setSearchCompanyLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCompanyProfile(action) {
  try {
    yield put(setSearchCompanyLoading({ loading: true }));
    const response = yield call(request, 'functions/getCompanyInformation', { userId: action.payload });
    yield put(fetchCompanyProfileSuccess({ profile: response.result }));
    yield put(setSearchCompanyLoading({ loading: false }));
  } catch (e) {
    console.log(e);
  }
}

function* createCompany(action) {
  try {
    yield put(setSearchCompanyLoading({ loading: true }));
    yield call(request, 'functions/createCompany', action.payload);
    yield put(createCompanySuccess({}));
    yield put(setSearchCompanyLoading({ loading: false }));
    yield put(push('/dashboard/company'));
  } catch (e) {
    console.log(e);
    if (e.code === 202) {
      yield put(stopSubmit('createCompany', { _error: 'このメールアドレスはすでに登録されています' }));
    } else {
      yield put(stopSubmit('createCompany', { _error: e.message }));
    }
  }
}

function* approveCompanyAccount(action) {
  const { meta, payload } = action
  try {
    yield call(request, 'functions/approveCompany', payload);
    yield put(requestApproveCompanyAccountSuccess({ meta }))
  } catch (e) {
    console.log(e);
    yield put(requestApproveCompanyAccountFailure({ meta }))
  }
}

export default function* watchCompany() {
  yield takeLeading(FETCH_COMPANY_LIST, fetchCompanyList);
  yield takeLeading(FETCH_COMPANY_PROFILE, fetchCompanyProfile);
  yield takeLeading(CREATE_COMPANY, createCompany);
  yield takeLeading(APPROVE_COMPANY_ACCOUNT, approveCompanyAccount);
}