import produce from 'immer';
import _ from 'lodash';

import {
  LOGIN_FIREBASE_SUCCESS,
  LOGOUT_FIREBASE_SUCCESS,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MORE_MESSAGE_SUCCESS,
  RECEIVE_NEW_MESSAGE,
  READ_NEW_MESSAGE,
  RESET_MESSAGE
} from './constants';

const initialState = {
  participant: {},
  messageList: [],
  messageData: {},
  messageReady: false,
  loadMore: false,
  haveNewMessage: false,
  isLoading: true
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FIREBASE_SUCCESS:
      state.messageReady = true;
      return state;

    case LOGOUT_FIREBASE_SUCCESS:
      state.messageReady = false;
      return state;

    case FETCH_MESSAGE_SUCCESS: {
      const users = _.get(action, 'payload.event.users');
      state.participant = _.keyBy(users, 'objectId');
      const messageList = _.get(action, 'payload.message.messageList');
      state.messageList = messageList;
      state.messageData = _.get(action, 'payload.message.data');
      state.loadMore = messageList.length === 10;
      state.isLoading = false;
      return state;
    }

    case FETCH_MORE_MESSAGE_SUCCESS: {
      const messageList = _.get(action, 'payload.message.messageList');
      const newMessageList = messageList.concat(state.messageList);
      state.messageList = _.uniq(newMessageList);
      const data = _.get(action, 'payload.message.data');
      state.messageData = {...state.messageData, ...data};
      state.loadMore = messageList.length === 10;
      return state;
    }

    case RECEIVE_NEW_MESSAGE: {
      const key = _.get(action, 'payload.key');
      const newMessageList = state.messageList.concat(key);
      state.messageList = _.uniq(newMessageList);
      const data = _.get(action, 'payload.data');
      state.messageData = {...state.messageData, ...data};
      state.haveNewMessage = true;
      return state;
    }

    case READ_NEW_MESSAGE: {
      state.haveNewMessage = false;
      return state;
    }

    case RESET_MESSAGE: {
      return {...initialState, messageReady: true};
    }

    default:
      return state;
  }
});

export default reducer;