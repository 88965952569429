import { connect } from 'react-redux';
import { customWithRouter } from 'components/HOC';

import { unsetSeekerProfile } from 'providers/SeekerProvider/actions';
import { acceptTenshokusha } from 'providers/RequestProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';

import { makeSeekerProfile } from 'providers/SeekerProvider/selectors';
import { makeAcceptJoinEventRequest } from 'providers/PopupProvider/selectors';
import ModalAccept from './ModalAccept';

const mapStateToProps = (state) => ({
  profile: makeSeekerProfile(state),
  popupState: makeAcceptJoinEventRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
  acceptTenshokusha: (params) => dispatch(acceptTenshokusha(params)),
  closePopup: () => dispatch(SetPopup({name: 'acceptJoinEventRequest', show: false})),
  unsetSeekerProfile: () => dispatch(unsetSeekerProfile())
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(ModalAccept));