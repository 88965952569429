import { connect } from 'react-redux';
import { SetPopup } from 'providers/PopupProvider/actions';
import { resetPassword } from 'providers/AuthProvider/actions';
import AccountManagement from './AccountManagement';


const mapDispatchToProps = (dispatch) => ({
  openConfirmPasswordPopup: (params) => dispatch(SetPopup({name: 'confirmPasswordPopup', show: params})),
  resetPassword: (email) => dispatch(resetPassword(email)),
});

export default connect(null, mapDispatchToProps)(AccountManagement);