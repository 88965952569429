import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import IconDot from 'assets/icons/icon-dot.svg';
import moment from 'moment';

class CustomTimeline extends React.Component {
  state = {
    isLong: false,
    isFull: true
  }

  checkTimelineLength = () => {
    const { list } = this.props;
    const { isLong } = this.state;

    if (!isLong && list.length > 3) {
      this.setState({ isLong: true, isFull: false });
    }
    if (isLong && list.length <= 3) {
      this.setState({ isLong: false, isFull: true });
    }
  }

  componentDidMount() {
    this.checkTimelineLength();
  }

  componentDidUpdate() {
    this.checkTimelineLength();
  }

  renderTimelineItem = (item, index, isFull) => !(index >= 3 && !isFull) && (
    <Timeline.Item dot={(<img alt='IconDot' src={IconDot} />)}>
      <div className='timeline-item-container'>
        <div className='timeline-item-label'>
          {moment(item.startYear.toString()).format('YYYY/MM')}-
          {item.endYear ? moment(item.endYear.toString()).format('YYYY/MM') : '現在'}
        </div>

        <div className='timeline-item-event'>
          <div className='timeline-item-company'>{item.company}</div>
          <div className='timeline-item-position'>{item.position}</div>
          <div className='timeline-item-description'>{item.description}</div>
        </div>
      </div>
    </Timeline.Item>
  );

  render() {
    const { list } = this.props;
    const { isLong, isFull } = this.state;

    return (
      <div id='timeline'>
        <Timeline>
          {list.map((item, index) => this.renderTimelineItem(item, index, isFull))}
        </Timeline>

        {isLong && (
          <div
            className='show-button'
            onClick={() => this.setState({ isFull: !isFull })}
          >
            {isFull ? (
              <div className='show-text'>たたむ</div>
            ) : (
              <React.Fragment>
                <div className='three-dot'>...</div>
                <div className='show-text'>もっと見る</div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

CustomTimeline.propTypes = {
  list: PropTypes.array
};

export default CustomTimeline;
