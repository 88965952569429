/* eslint-disable no-control-regex */
const validate = (values) => {
  const errors = {};
  // eslint-disable-next-line max-len
  const regex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

  if (!values.email) {
    errors.email = 'あなたのメールアドレスを入力してください';
  } else if (!regex.test(values.email.trim().toLowerCase())) {
    errors.email = '有効なメールアドレスを入力してください';
  }
  if (!values.companyName) {
    errors.companyName = 'この項目は必須です。';
  }
  return errors;
};

export default validate;
