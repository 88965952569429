import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class LightboxControl extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = nextProps;
    show && this.props.openLightbox(show.gallery[show.index]);
  }

  render() {
    const { show, openLightboxControl } = this.props;
    const { gallery, index } = show;

    if (!show || (show && gallery.length === 1)) return null;

    return (
      <div id="lightbox-control">
        <div 
          id="left-lightbox-control" 
          onClick={() => {
            const prevIndex = index === 0 ? gallery.length - 1 : index - 1;
            openLightboxControl(gallery, prevIndex);
          }}
        />

        <div 
          id="right-lightbox-control" 
          onClick={() => {
            const nextIndex = index === gallery.length - 1 ? 0 : index + 1;
            openLightboxControl(gallery, nextIndex);
          }}
        />
      </div>
    );
  }
}

LightboxControl.propTypes = {
  show: PropTypes.any,
  openLightbox: PropTypes.func,
  openLightboxControl: PropTypes.func
};

export default LightboxControl;