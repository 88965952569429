import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from 'antd';

export class LockEventPopup extends React.Component {

  handleLockEvent = async(eventId) => {
    await this.props.lockEventRequest(eventId);
    await this.props.updateStatusOnScreen();
    this.props.closePopup();
  };

  render() {
    const {
      eventId,
      popupState,
      closePopup
    } = this.props;
    
    return (
      <>
        <Modal
          title="イベントのロック"
          centered
          visible={popupState}
          onOk={closePopup}
          onCancel={closePopup}
          footer={[
            <Button 
              key="submit"
              onClick={closePopup}
            >
              取 消
            </Button>,
            <Button
              key="submit"
              type="danger"
              onClick={() => this.handleLockEvent(eventId)}
            >
              確 定
            </Button>
          ]}
        >
          <p >このイベントをロックしてもよろしいですか?</p>
          <p style={{color: '#c02425'}}>イベントをロックすると未選定の応募者は、自動的に参加が確定になります</p>
        </Modal>
      </>
    );
  }
}

LockEventPopup.propTypes = {
  eventId: PropTypes.string,
  popupState: PropTypes.bool,
  closePopup: PropTypes.func,
  lockEventRequest: PropTypes.func,
  updateStatusOnScreen: PropTypes.func
};

export default LockEventPopup;
