import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Input,
  Select,
  Avatar
} from 'antd';
import Timeline from './Timeline';
import AccountManagement from '../../AccountManagement';
import _ from 'lodash';
import './styles.scss';
import moment from 'moment';

class TabProfile extends React.Component {
  renderGender = (gender) => {
    if (gender === 'male') {
      return '男性';
    }
    if (gender === 'female') {
      return '女性';
    }
    if (gender === 'other') {
      return 'その他';
    }
  }

  render() {
    const { profile } = this.props;
    const birthDay = _.get(profile, 'birthDay');
    
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <>
        <AccountManagement profile={profile} />

        <Form {...formItemLayout}>
          <Card className="seeker-profile-tab">
            <div className="seeker-profile-tab-title">基本情報</div>

            <Form.Item colon={false} label="アバター" className='seeker-profile-avatar'>
              <Avatar size={100} icon="picture" src={_.get(profile, 'avatar.thumbSmall')} />
            </Form.Item>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>姓</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'kanjiLastName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>名</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'kanjiFirstName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>姓（カナ)</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'kataLastName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>名（カナ)</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'kataFirstName')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>性別</span></span>)}>
              <Input style={{ width: 468 }} value={this.renderGender(_.get(profile, 'gender'))} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>生年月日</span></span>)}>
              <Input 
                style={{ width: 468 }} 
                value={birthDay && moment(birthDay).locale('ja').format('YYYY/MM/DD(ddd)')} 
                disabled 
              />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>電話番号</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'phone')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span>現在の就業状況</span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'currentSates')} disabled />
            </Form.Item>
            <Form.Item colon={false} label="現年収">
              <Input style={{ width: 468 }} value={_.get(profile, 'currentIncome.range')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>最終学歴</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'latestAcademic')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>大学名</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'university')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>学部</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'major')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>学科</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'majorDepartment')} disabled />
            </Form.Item>
            <Form.Item colon={false}
              label={(<span><span className='required-icon'>*</span><span>最終学歴卒業年度</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'graduationYear')} disabled />
            </Form.Item>
            <Form.Item colon={false} label="資格">
              <Input style={{ width: 468 }} value={_.get(profile, 'certificate')} disabled />
            </Form.Item>
          </Card >
          
          <Card className="seeker-profile-tab">
            <div className="seeker-profile-tab-title">自己PR</div>
            
            <Form.Item colon={false} label="自己PR">
              <Input.TextArea style={{ width: 468 }} autoSize value={_.get(profile, 'introduction')} disabled />
            </Form.Item>
          </Card >

          <Card className="seeker-profile-tab">
            <div className="seeker-profile-tab-title">職務経歴</div>
            <Timeline list={_.get(profile, 'workExp', [])} />
          </Card >

          <Card className="seeker-profile-tab">
            <div className="seeker-profile-tab-title">希望内容</div>
            
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>業界</span></span>)}>
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'interestingIndustry', []).map(e => e.name)}
                disabled
              />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>職種</span></span>)}>
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'interestingJob', []).map(e => e.name)}
                disabled
              />
            </Form.Item>

            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>希望年収</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'expectedIncome.range')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>転職希望時期</span></span>)}>
              <Input style={{ width: 468 }} value={_.get(profile, 'changeJobTime.name')} disabled />
            </Form.Item>
            <Form.Item colon={false} label={(<span><span className='required-icon'>*</span><span>希望勤務地</span></span>)}>
              <Select
                style={{ width: 468 }}
                mode="multiple"
                value={_.get(profile, 'expectedLocation', []).map(e => e.name)}
                disabled
              />
            </Form.Item>
          </Card >
        </Form>
      </>
    );
  }
}

TabProfile.propTypes = {
  profile: PropTypes.object
};

export default TabProfile;