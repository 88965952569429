export const ADD_PLAN_REQUEST = 'PlanProvider/ADD_PLAN_REQUEST';
export const ADD_PLAN_SUCCESS = 'PlanProvider/ADD_PLAN_SUCCESS';
export const ADD_PLAN_FAILURE = 'PlanProvider/ADD_PLAN_FAILURE';
export const addPlanRequest = (payload) => ({
  type: ADD_PLAN_REQUEST,
  payload,
  meta: { thunk: true }
});
export const addPlanSuccess = (payload, meta) => ({
  type: ADD_PLAN_SUCCESS,
  payload,
  meta
});
export const addPlanFailure = (payload, meta) => ({
  type: ADD_PLAN_FAILURE,
  payload,
  meta,
  error: true
});

export const REMOVE_PLAN_REQUEST = 'PlanProvider/REMOVE_PLAN_REQUEST';
export const REMOVE_PLAN_SUCCESS = 'PlanProvider/REMOVE_PLAN_SUCCESS';
export const REMOVE_PLAN_FAILURE = 'PlanProvider/REMOVE_PLAN_FAILURE';
export const removePlanRequest = (payload) => ({
  type: REMOVE_PLAN_REQUEST,
  payload,
  meta: { thunk: true }
});
export const removePlanSuccess = (payload, meta) => ({
  type: REMOVE_PLAN_SUCCESS,
  payload,
  meta
});
export const removePlanFailure = (payload, meta) => ({
  type: REMOVE_PLAN_FAILURE,
  payload,
  meta,
  error: true
});

export const GET_PLANS_REQUEST = 'PlanProvider/GET_PLANS_REQUEST';
export const GET_PLANS_SUCCESS = 'PlanProvider/GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'PlanProvider/GET_PLANS_FAILURE';
export const getPlansRequest = (payload) => ({
  type: GET_PLANS_REQUEST,
  payload,
  meta: { thunk: true }
});
export const getPlansSuccess = (payload, meta) => ({
  type: GET_PLANS_SUCCESS,
  payload,
  meta
});
export const getPlansFailure = (payload, meta) => ({
  type: GET_PLANS_FAILURE,
  payload,
  meta,
  error: true
});

export const GET_SCOUTS_REQUEST = 'PlanProvider/GET_SCOUTS_REQUEST';
export const GET_SCOUTS_SUCCESS = 'PlanProvider/GET_SCOUTS_SUCCESS';
export const GET_SCOUTS_FAILURE = 'PlanProvider/GET_SCOUTS_FAILURE';
export const getScoutsRequest = (payload) => ({
  type: GET_SCOUTS_REQUEST,
  payload,
  meta: { thunk: true }
});
export const getScoutsSuccess = (payload, meta) => ({
  type: GET_SCOUTS_SUCCESS,
  payload,
  meta
});
export const getScoutsFailure = (payload, meta) => ({
  type: GET_SCOUTS_FAILURE,
  payload,
  meta,
  error: true
});