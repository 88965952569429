import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'antd';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';

import validate from './validate';
import asyncValidate from './asyncValidate';
import { Checkbox, RadioButton, TextField, TextArea } from 'components/Form';

const RecommendEventForm = (props) => {
  const { handleSubmit, jobResource, allSeekerFieldValue, numberOfSeeker, eventDetail, valid, dirty } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="allSeeker"
        label={<span>条件絞り込み<span className="seeker-count">{`(${numberOfSeeker}人が該当します)`}</span></span>}
        required
        defaultValue={true}
        className="radio-button-field"
        options={[{
          label: '「すべてのユーザー」',
          value: true
        }, {
          label: '「職種によって絞り込み」',
          value: false
        }]}
        component={RadioButton}
      />
      <Field
        name="interestingJob"
        className="checkbox-field"
        component={Checkbox}
        disabled={allSeekerFieldValue}
        options={jobResource.map((job) => ({
          label: job.name,
          value: job.objectId
        }))}
      />
      <Field
        name="eventId"
        label={
          <span className="event-label">
            PRするイベントID
            {_get(eventDetail, 'eventTitle', '') && (
              <Link to={`/dashboard/event/published/detail/${_get(eventDetail, 'objectId', '')}`} target="_blank">
                ({eventDetail.eventTitle})
              </Link>
            )}
          </span>
        }
        required
        component={TextField}
        className="text-input-field"
      />
      <Field
        name="content"
        label='通知文面'
        className="text-area-field"
        required
        component={TextArea}
        autoSize={{ minRows: 5, maxRows: 5 }}
      />
      <div className="btn-wrapper">
        <Button htmlType='submit' className="btn-submit" disabled={!valid || !dirty}>送信する</Button>
      </div>
    </Form>
  );
};

RecommendEventForm.propTypes = {
  handleSubmit: PropTypes.func,
  jobResource: PropTypes.array,
  allSeekerFieldValue: PropTypes.bool,
  numberOfSeeker: PropTypes.number,
  eventDetail: PropTypes.object,
  valid: PropTypes.bool,
  dirty: PropTypes.bool
};

export default reduxForm({
  form: 'recommendEvent',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['eventId']
})(RecommendEventForm);
