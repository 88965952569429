import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Switch, Icon, Button
} from 'antd';
import _ from 'lodash';
import Helmet from 'react-helmet';

import BigPageHeader from 'components/PageHeader';

import TabProfile from './TabProfile';
import TabEvent from './TabEvent';
import TabApplicant from './TabApplicant';
import TabPlan from './TabPlan';
import TabScout from './TabScout';
import ApprovingManagement from './ApprovingManagement';

import './styles.scss';

class CompanyDetail extends React.PureComponent {
  componentDidMount() {
    this.getCompanyInfo();
  }

  componentWillUnmount() {
    this.props.unsetCompanyProfile();
    this.props.destroyForm('changeEmail');
    this.props.destroyForm('changePassword');
  }

  getCompanyInfo = () => {
    this.props.fetchCompanyProfile(this.props.match.params.companyId);
  }

  afterApprove = () => {
    const { match, history } = this.props;
    this.getCompanyInfo();
    history.push(`/dashboard/company/status/ACTIVE/detail/${match.params.companyId}`);
  }

  getApproveButton = () => {
    const { profile } = this.props;
    return profile.status === 'WAITING_FOR_APPROVE' ? (
      < ApprovingManagement
        afterHandeSuccessfully={this.afterApprove}
        companyId={profile.objectId}
        render={(requestApproveCompanyAccount, inProccess) => <div id='user-status'>
          <Button type='primary' size='large' onClick={requestApproveCompanyAccount} disabled={inProccess}>
            承認
          </Button>
        </div>
        }
      />
    ) : undefined;
  }

  handleClickSwitch = () => {
    const { profile } = this.props;
    const userId = profile.objectId;
    const role = profile.role;
    const isActive = profile.status === 'ACTIVE';
    const status = isActive ? 'INACTIVE' : 'ACTIVE';
    const statusText = isActive ? '非アクティブ' : 'アクティブ';

    this.props.openPopup({
      title: '',
      content: `本当にこの社長を${statusText}にしますか？`,
      okText: 'はい',
      cancelText: '戻る',
      confirm: () => {
        this.props.updateUserStatus({userId, role, status});
      }
    });
  }

  render() {
    const { TabPane } = Tabs;
    const { profile, match, history } = this.props;
    const pageTitle = 'Tenshoku Dashboard | Company';
    const link = /^(.+)\/detail\/.+$/.exec(history.location.pathname);
    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <BigPageHeader
          title={_.get(profile, 'companyName', '...')}
          goBack={link && link[1] ? link[1] : true}
          extra={this.getApproveButton()}
        />

        <div className="page-container" id="company-detail-page">
          <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            <TabPane tab="会社プロファイル" key="1">
              <TabProfile profile={profile} />
            </TabPane>
            {profile.status !== 'WAITING_FOR_APPROVE' ? (
              <TabPane tab="イベント一覧" key="2">
                <TabEvent companyId={match.params.companyId} />
              </TabPane>
            ) : undefined }
            {profile.status !== 'WAITING_FOR_APPROVE' ? (
              <TabPane tab="応募者一覧" key="3">
                <TabApplicant companyId={match.params.companyId} />
              </TabPane>
            ) : undefined }
            {profile.status !== 'WAITING_FOR_APPROVE' ? (
              <TabPane tab="スカウト" key="4">
                <TabScout companyId={match.params.companyId} />
              </TabPane>
            ) : undefined }
            {profile.status !== 'WAITING_FOR_APPROVE' ? (
              <TabPane tab="スカウトプラン" key="5">
                <TabPlan companyId={match.params.companyId} />
              </TabPane>
            ) : undefined }
          </Tabs>

          {profile.status !== 'WAITING_FOR_APPROVE' ? (
            <div id='user-status'>
              <span>アクティブ</span>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={profile.status === 'ACTIVE'}
                onClick={this.handleClickSwitch}
              />
            </div>
          ) : undefined
          }
        </div>
      </React.Fragment>
    );
  }
}

CompanyDetail.propTypes = {
  destroyForm: PropTypes.func,
  updateUserStatus: PropTypes.func,
  openPopup: PropTypes.func,
  fetchCompanyProfile: PropTypes.func,
  unsetCompanyProfile: PropTypes.func,
  match: PropTypes.any,
  profile: PropTypes.object,
  history: PropTypes.object
};

export default CompanyDetail;