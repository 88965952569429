import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import Label from '../Label';
import moment from 'moment';
import { handleFocus } from 'utils/reduxForm';

// input.value is moment format
class MonthPickerField extends React.PureComponent {
  handleChange = (m) => {
    this.props.input.onChange(m);
  }
  
  render() {
    const {
      label, placeholder, input, required, formatMonthPicker,
      meta: { touched, invalid, error }
    } = this.props;

    delete input.onBlur;

    return (
      <Form.Item 
        colon={false} 
        label={<Label label={label} required={required} />} 
        help={touched && invalid && error} 
        validateStatus={touched && invalid && error ? 'error' : ''}
        onFocus={() => handleFocus(this.props)}
      >
        <DatePicker.MonthPicker 
          {...input}
          placeholder={placeholder}
          value={input.value && moment(input.value)}
          onChange={this.handleChange}
          format={formatMonthPicker}
          {...this.props}
        />
      </Form.Item>
    );
  }
}

MonthPickerField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formatMonthPicker: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object.isRequired
};

export default MonthPickerField;
