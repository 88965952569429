import React, { PureComponent } from 'react';
import { Icon, Tooltip} from 'antd';
//import ConstructionIcon from 'assets/icons/under-construction.svg';
import { ReactComponent as ConstructionIcon } from 'assets/icons/under-construction.svg';

class WIPIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Tooltip placement="bottomRight" title={'feature under construction'}>
        <Icon component={ConstructionIcon} style={{opacity: 0.7, marginLeft: 8}} {...this.props}/>
      </Tooltip>
    );
  }
}

export default WIPIcon;