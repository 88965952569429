import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import SearchSeekerPage from './SearchSeekerPage';
import SeekerDetailPage from './SeekerDetailPage';
import CreateSeekerPage from './CreateSeekerPage';

import './styles.scss';

class UserExample extends React.PureComponent {
  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/status/:status/detail/:seekerId`} component={SeekerDetailPage}/>
          <Route path={`${match.path}/status/:status`} component={SearchSeekerPage}/>
          <Route path={`${match.path}/create`} component={CreateSeekerPage} exact/>
          <Redirect to={`${match.path}/status/ACTIVE`} />
        </Switch>
        
      </React.Fragment>
    );
  }
}

UserExample.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.any
};

export default UserExample;