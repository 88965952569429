import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router-dom';


import DashboardLayout from 'components/DashboardLayout';

import indexRoutes from './indexRoutes';

class PrivateRoute extends React.PureComponent {
  render() {
    //const { component, path } = this.props;
    const routes = [];
    indexRoutes.forEach((route) => {
      if (route.requireLogin) {
        routes.push(
          <Route
            path={'/dashboard' + route.path}
            exact={route.exact}
            render={(props) =>
              <React.Fragment>
                <route.component {...props} />
              </React.Fragment>
            }
            key={route.path}
          />
        );
      }
    });

    return (
      <React.Fragment>
        <DashboardLayout {...this.props} >
          <Switch>
            {routes}
          </Switch>
        </DashboardLayout>
      </React.Fragment>
    );
  }
}

/*const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser()
});*/

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.string
};

export default connect(null, null)(withRouter(PrivateRoute));
