import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Card, Form } from 'antd';
import { 
  TextField
} from 'components/Form';
import validate from './validate';

class CreateCompanyForm extends React.Component {
  render() {
    const { handleSubmit, error, submitting, invalid } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <Card className="create-company-tab">
          <div className="create-company-tab-title">アカウント情報</div>
          
          <Field
            name="companyName"
            type="text"
            placeholder="会社名"
            component={TextField}
            label="会社名"
            style={{ width: 468 }}
            required
            maxLength={30}
          />
            
          <Field
            name="email"
            type="text"
            placeholder="メールアドレス"
            component={TextField}
            label="メールアドレス"
            style={{ width: 468 }}
            required
          />

          {error && (
            <div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-8' />
              <div className='ant-col ant-col-xs-24 ant-col-sm-16'>
                <div className='system-error'>{error}</div>
              </div>
            </div>
          )}

          <div className='create-company-button-container'>
            <button 
              type='button' 
              className='ten-ad-btn cancel-button' 
              onClick={() => this.props.history.goBack()}
            >
              キャンセル
            </button>

            <button 
              type='submit' 
              className='ten-ad-btn primary-button' 
              disabled={error || submitting || invalid}
            >
              作成する
            </button>
          </div>
        </Card>
      </Form>
    );
  }
}

CreateCompanyForm.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

export default reduxForm({ 
  form: 'createCompany', 
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateCompanyForm);