import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TenshokuInfo from 'components/TenshokuInfo';

import { Modal, Button } from 'antd';

export class ModalAccept extends React.Component {

  hideModal = () => {
    this.props.closePopup();
    this.props.unsetSeekerProfile();
  };

  acceptRequest = async(profile, eventDetails) => {
    await this.props.acceptTenshokusha({ eventId: eventDetails.objectId, userId: profile.objectId });
    await this.props.updateStatusOnScreen();
    this.hideModal();
  };

  render() {
    const {
      profile,
      popupState,
      eventDetails
    } = this.props;

    return (
      <Modal
        title="応募者を確定する"
        centered
        visible={popupState}
        onOk={this.hideModal}
        onCancel={this.hideModal}
        footer={[
          <Button 
            key="submit"
            onClick={this.hideModal}
          >
            戻る
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => this.acceptRequest(profile, eventDetails)}
          >
            確定する
          </Button>
        ]}
      >
        <p>この応募者の参加を確定してもよろしいですか?</p>
        {_.isEmpty(profile) ?
          <div style={{height: '96px'}}/>
          :
          <TenshokuInfo tenshokuInfo={profile}/>
        }
      </Modal>
    );
  }
}

ModalAccept.propTypes = {
  push: PropTypes.func,
  history: PropTypes.object,
  profile: PropTypes.object,
  acceptTenshokusha: PropTypes.func,
  hideModal: PropTypes.func,
  popupState: PropTypes.bool,
  closePopup: PropTypes.func,
  eventDetails: PropTypes.object,
  unsetSeekerProfile: PropTypes.func,
  updateStatusOnScreen: PropTypes.func
};

export default ModalAccept;
