import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import SearchEventPage from './SearchEventPage';
import EventDetailPage from './EventDetailPage';
import CreateEventPage from './CreateEventPage';

import './styles.scss';

class EventContainer extends React.PureComponent {
  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/search`} component={SearchEventPage}/>
          <Route path={`${match.path}/detail/:eventId`} component={EventDetailPage}/>
          <Route path={`${match.path}/create`} component={CreateEventPage} exact/>
          <Route path={`${match.path}/create/:eventId`} component={CreateEventPage}/>
          <Route path={`${match.path}/published`} component={EventContainer}/>
          <Route path={`${match.path}/locked`} component={EventContainer}/>
          <Route path={`${match.path}/draft`} component={EventContainer}/>
          <Route path={`${match.path}/finished`} component={EventContainer}/>
          <Route path={`${match.path}/canceled`} component={EventContainer}/>
          <Redirect to={`${match.path}/search`} />
        </Switch>
        
      </React.Fragment>
    );
  }
}

EventContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.any
};

export default EventContainer;