import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Card, Form } from 'antd';
import { 
  TextField, Select as SelectField
} from 'components/Form';
import validate from './validate';

class CreateSeekerForm extends React.Component {
  render() {
    const { gender, handleSubmit, error, submitting, invalid } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <Card className="create-seeker-tab">
          <div className="create-seeker-tab-title">アカウント情報</div>
          
          <Field
            name="email"
            type="text"
            placeholder="メールアドレス"
            component={TextField}
            label="メールアドレス"
            style={{ width: 468 }}
            required
          />

          <Field
            name="kanjiLastName"
            type="text"
            placeholder="姓"
            component={TextField}
            label="姓"
            style={{ width: 468 }}
            required
            maxLength={15}
          />

          <Field
            name="kanjiFirstName"
            type="text"
            placeholder="名"
            component={TextField}
            label="名"
            style={{ width: 468 }}
            required
            maxLength={15}
          />

          <Field
            name="kataLastName"
            type="text"
            placeholder="姓（カナ)"
            component={TextField}
            label="姓（カナ)"
            style={{ width: 468 }}
            required
            maxLength={15}
          />

          <Field
            name="kataFirstName"
            type="text"
            placeholder="名（カナ)"
            component={TextField}
            label="名（カナ)"
            style={{ width: 468 }}
            required
            maxLength={15}
          />

          <Field
            name='gender'
            type='text'
            placeholder="性別"
            component={SelectField}
            label='性別'
            style={{ width: 468 }}
            required
            options={gender}
          />
            
          {error && (
            <div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-8' />
              <div className='ant-col ant-col-xs-24 ant-col-sm-16'>
                <div className='system-error'>{error}</div>
              </div>
            </div>
          )}

          <div className='create-seeker-button-container'>
            <button 
              type='button' 
              className='ten-ad-btn cancel-button' 
              onClick={() => this.props.history.goBack()}
            >
              キャンセル
            </button>

            <button 
              type='submit' 
              className='ten-ad-btn primary-button' 
              disabled={error || submitting || invalid}
            >
              作成する
            </button>
          </div>
        </Card>
      </Form>
    );
  }
}

CreateSeekerForm.propTypes = {
  gender: PropTypes.array,
  handleSubmit: PropTypes.func,
  history: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

export default reduxForm({ 
  form: 'createSeeker', 
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateSeekerForm);