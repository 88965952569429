import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import BigPageHeader from 'components/PageHeader';
import CreateCompanyForm from './CreateCompanyForm';
import './style.scss';

class CreateCompanyPage extends React.PureComponent {
  handleSubmit = async values => {
    this.props.createCompany(values);
  }

  render() {
    const pageTitle = 'Tenshoku Dashboard | Create Company';

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <BigPageHeader
          title={'アカウントを作成する'}
          goBack={true}
        />
        <div id="create-company-page">
          <CreateCompanyForm onSubmit={this.handleSubmit} {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}

CreateCompanyPage.propTypes = {
  push: PropTypes.func,
  createCompany: PropTypes.func
};

export default CreateCompanyPage;