import { connect } from 'react-redux';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeLightboxControl } from 'providers/PopupProvider/selectors';
import LightboxControl from './LightboxControl';

const mapStateToProps = state => {
  return {
    show: makeLightboxControl(state)
  };
};

const mapDispatchToProps = dispatch => ({
  openLightbox: url => dispatch(SetPopup({name: 'lightbox', show: { url }})),
  openLightboxControl: (gallery, index) => dispatch(SetPopup({name: 'lightboxControl', show: { gallery, index }}))
});

export default connect(mapStateToProps, mapDispatchToProps)(LightboxControl);