import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import SearchBar from 'components/SearchBar';
import ButtonRequestManager from 'containers/JoinEventRequestManager/ButtonRequestManager';
import ModalAcceptRequest from 'containers/JoinEventRequestManager/ModalAccept';
import ModalDenyRequest from 'containers/JoinEventRequestManager/ModalDeny';
import AcceptIcon from 'assets/icons/accept.svg';
import RejectIcon from 'assets/icons/reject.svg';
import {
  Card
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import './styles.scss';
import { DOWNLOAD_URL } from 'utils/config';

import { getNewToken } from 'providers/AuthProvider/actions';
import { fetchRequestList } from 'providers/RequestProvider/actions';
import { makeRequestList } from 'providers/RequestProvider/selectors';
import Table from 'components/Table';
import { customWithRouter, withResource } from 'components/HOC';

import Columns from 'components/Preset/Columns';

const { FilterFields } = SearchBar;

class TabApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
  }

  updateSearchToState = () => {
    this.props.updateSearchToState('searchApplicant', this.props, this.props.fetchData, {eventId: this.props.eventId});
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  updateScreenAfterAcceptRequest = (eventId) => {
    this.props.fetchEventDetail(eventId);
    this.updateSearchToState();
  }

  download(href) {
    const holder = this.linkRef.current;
    holder.setAttribute('href', href);
    holder.click();
  }

  clickHandler = (e) => {
    e.preventDefault();
    const { eventId } = this.props;
    const zone = moment().utcOffset();
    
    this.props.getNewToken()
      .then(token => {
        this.download(`${DOWNLOAD_URL}extra/studentRequests?token=${token}&eventId=${eventId}&zone=${zone}`);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { total, loading, list } = this.props.listData;
    const { search, eventDetails } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;

    const filterFields = [
      FilterFields.requestKeyword,
      FilterFields.position,
      FilterFields.requestedDate,
      FilterFields.requestStatus
    ];

    const columns = [
      Columns.requestSeekerAvatar,
      Columns.requestSeekerName,
      Columns.email,
      Columns.requestedDate,
      Columns.requestStatus,
      {
        title: '承諾する / お断りする',
        key: 'status',
        width: 200,
        render: (record) => (
          _.get(record, 'status', '') === 'REQUEST' && 
          <div style={{display: 'flex'}}>
            <ButtonRequestManager
              render={ fetchData => 
                <img
                  alt='Icon Accept'
                  width='32px'
                  height='32px'
                  style={{marginRight: '24px', cursor: 'pointer'}}
                  src={AcceptIcon}
                  onClick={fetchData}
                />
              }
              action='ACCEPT'
              seekerId={record.seekerId}
            />         
            <ButtonRequestManager
              render={ fetchData => 
                <img
                  alt='Icon Reject'
                  width='32px'
                  height='32px'
                  style={{cursor: 'pointer'}}
                  src={RejectIcon}
                  onClick={fetchData}
                />
              }
              action='DENY'
              seekerId={record.seekerId}
            />         
          </div>
        )
      },
      {
        title: 'アクション',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => (
          <Link to={`/dashboard/request/detail/${record.eventId}_${record.seekerId}`}>
            詳細を見る
          </Link>
        )
      }
    ];

    return (
      <>
        <div className="export-csv-container">
          <div>Export to CSV</div>
          <div className="export-csv-button" onClick={this.clickHandler}>Export</div>
        </div>
        <a href="/" ref={this.linkRef} style={{display: 'none'}} target="_blank" rel="noopener noreferrer">{null}</a>

        <Card>
          <div className="search-section">
            <SearchBar filterFields={filterFields}/>
          </div>
          <Table
            search={this.props.search}
            columns={columns}
            dataSource={list}
            scroll={{ x: 1000 }}
            onChange={this.onTableChange}
            pagination={{
              total: total,
              showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
              pageSize: 10,
              current: currentPage
            }}
            loading={loading}
          />
          <ModalAcceptRequest 
            updateStatusOnScreen={() => this.updateScreenAfterAcceptRequest(eventDetails.objectId)}
            eventDetails={eventDetails}
          />
          <ModalDenyRequest 
            updateStatusOnScreen={() => this.updateSearchToState()}
            eventId={eventDetails.objectId}
          />
        </Card>
      </>
    );
  }
}

TabApplicant.propTypes = {
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func,
  updateSearchToState: PropTypes.func,
  getNewToken: PropTypes.func,
  fetchEventDetail: PropTypes.func,
  onTableChange: PropTypes.func,
  eventId: PropTypes.number,
  location: PropTypes.object,
  listData: PropTypes.object,
  eventDetails: PropTypes.object,
  search: PropTypes.any
};

const mapStateToProps = (state) => ({
  listData: makeRequestList(state)
});

const mapDispatchToProps = (dispatch) => ({
  getNewToken: () => dispatch(getNewToken()),
  fetchData: (params) => dispatch(fetchRequestList(params))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  customWithRouter,
  withRouter,
  withResource(['Position'])
)(TabApplicant);