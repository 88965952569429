import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

//import { getAuthToken } from 'providers/AuthProvider/actions';
import indexRoutes from './indexRoutes';

class PublicRoutes extends React.PureComponent {
  render() {
    const routes = [];
    indexRoutes.forEach((route) => {
      if (!route.requireLogin) {
        routes.push(
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        );
      }
    });

    return (
      <React.Fragment>
        <Switch>
          {routes}
        </Switch>
      </React.Fragment>
    );
  }
}

PublicRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired
};

export default PublicRoutes;
