import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LightboxGallery extends Component {
  gallery = []

  recursiveMap = (children) => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;
      
      if (child.props.children) {
        child = React.cloneElement(child, {
          children: this.recursiveMap(child.props.children)
        });
      }
      
      if (child.type === 'img') {
        const { src, thumbLarge = src } = child.props;
        if (this.gallery.includes(thumbLarge)) {
          this.gallery = this.gallery.filter(url => url !== thumbLarge);
        }
        this.gallery.push(thumbLarge);
        
        return React.cloneElement(child, { onClick: () => {
          this.props.openLightboxControl(this.gallery, this.gallery.indexOf(thumbLarge));
        }});
      }

      return child;
    });
  }

  render() {    
    return this.recursiveMap(this.props.children);
  }
}

LightboxGallery.propTypes = {
  children: PropTypes.any,
  openLightboxControl: PropTypes.func
};

export default LightboxGallery;