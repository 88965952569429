import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ME,
  ME_SUCCESS,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_STATUS,
  GET_NEW_TOKEN,
  GET_NEW_TOKEN_SUCCESS
} from './constants';

export function login(payload = {}) {
  return {type: LOGIN, payload};
}

export function me(payload = {}) {
  return {type: ME, payload};
}

export function loginSuccess(payload = {}) {
  return {type: LOGIN_SUCCESS, payload};
}
export function loginFail(payload = {}) {
  return {type: LOGIN_FAIL, payload};
}
export function logout() {
  return {type: LOGIN_FAIL, payload: ''};
}

export function meSuccess(payload = {}) {
  return {type: ME_SUCCESS, payload};
}

export function changeEmail(payload = {}, role) {
  return {type: CHANGE_EMAIL, payload, role};
}

export function changePassword(payload = {}) {
  return {type: CHANGE_PASSWORD, payload};
}

export function resetPassword(payload = {}) {
  return {type: RESET_PASSWORD, payload};
}

export function updateUserStatus(payload = {}) {
  return {type: UPDATE_USER_STATUS, payload};
}

export function getNewToken(payload = {}) {
  return {type: GET_NEW_TOKEN, payload, meta: {thunk: true}};
}

export function getNewTokenSuccess(payload, meta) {
  return {type: GET_NEW_TOKEN_SUCCESS, payload, meta};
}
