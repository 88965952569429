
import { put, takeLeading, call } from 'redux-saga/effects';
import pick from 'lodash/pick';
import { message } from 'antd';

import request from 'utils/request';
import {
  ADD_PLAN_REQUEST,
  addPlanSuccess,
  addPlanFailure,
  GET_PLANS_REQUEST,
  getPlansSuccess,
  getPlansFailure,
  REMOVE_PLAN_REQUEST,
  removePlanSuccess,
  removePlanFailure,
  GET_SCOUTS_REQUEST,
  getScoutsSuccess,
  getScoutsFailure
} from './actions';

function* addPlan(action) {
  const { payload, meta } = action;
  try {
    if (payload.isEdit) {
      const params = pick(payload, [
        'companyId', 'planId', 'name', 'total', 'startDate', 'endDate', 'typeOfPlan', 'menus'
      ]);
      yield call(request, '/functions/editPlan', params, 'POST');
    } else {
      const params = pick(payload, ['companyId', 'name', 'total', 'startDate', 'typeOfPlan']);
      yield call(request, '/functions/addPlan', params, 'POST');
    }
    yield put(addPlanSuccess({}, meta));
  } catch (e) {
    message.error(e.error);
    yield put(addPlanFailure(e, meta));
  }
}

function* getPlans(action) {
  const { payload, meta } = action;
  try {
    const params = { limit: 20, ...payload };
    if (!params.orderBy || !params.order) {
      params.orderBy = 'createdAt';
      params.order = 'descend';
    }
    const { result } = yield call(request, '/functions/companyPlans', params, 'POST');
    yield put(getPlansSuccess(result, meta));
  } catch (e) {
    message.error(e.error);
    yield put(getPlansFailure(e, meta));
  }
}

function* removePlan(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(request, '/functions/removePlan', payload, 'POST');
    yield put(removePlanSuccess(result, meta));
  } catch (e) {
    message.error(e.error);
    yield put(removePlanFailure(e, meta));
  }
}

function* getScouts(action) {
  const { payload, meta } = action;
  try {
    const params = { limit: 20, ...payload };
    if (!params.orderBy || !params.order) {
      params.orderBy = 'createdAt';
      params.order = 'descend';
    }
    const { result } = yield call(request, '/functions/getScouts', params, 'POST');
    yield put(getScoutsSuccess(result, meta));
  } catch (e) {
    message.error(e.error);
    yield put(getScoutsFailure(e, meta));
  }
}

export default function* watchPlan() {
  yield takeLeading(ADD_PLAN_REQUEST, addPlan);
  yield takeLeading(GET_PLANS_REQUEST, getPlans);
  yield takeLeading(REMOVE_PLAN_REQUEST, removePlan);
  yield takeLeading(GET_SCOUTS_REQUEST, getScouts);
}