export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const ME = 'ME';
export const ME_SUCCESS = 'ME_SUCCESS';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const GET_NEW_TOKEN = 'GET_NEW_TOKEN';
export const GET_NEW_TOKEN_SUCCESS = 'GET_NEW_TOKEN_SUCCESS';