import { connect } from 'react-redux';

import { compose } from 'redux';
import { fetchRequestList } from 'providers/RequestProvider/actions';
import { makeRequestList } from 'providers/RequestProvider/selectors';
import { customWithRouter, withResource } from 'components/HOC';

import SearchApplicantPage from './SearchRequestPage';


const mapStateToProps = (state) => ({
  list: makeRequestList(state)
});


const mapDispatchToProps = (dispatch) => ({
  fetchListData: (params) => dispatch(fetchRequestList(params))
});

export default compose(
  withResource(['Position']),
  customWithRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchApplicantPage);