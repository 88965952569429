import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';

const MyMessageCell = ({ message, messageCellStyle, showLess, ordinalMessage, children }) => {
  const { updatedAt, contentType, content } = message;

  return (
    <div className={`my-message-cell ${ordinalMessage}`} style={messageCellStyle}>
      <div className='message-wrapper'>
        <div className='message-time'>
          {!showLess && moment(updatedAt).locale('ja').format('HH:mm')}
        </div>

        <div title={moment(updatedAt).locale('ja').format('YYYY/MM/DD HH:mm')}>
          {contentType === 'text' && (<div className='message-text'>{content.trim()}</div>)}
          {children}
        </div>
      </div>
    </div>
  );
};

MyMessageCell.propTypes = {
  children: PropTypes.any,
  showLess: PropTypes.bool,
  ordinalMessage: PropTypes.string,
  message: PropTypes.object,
  messageCellStyle: PropTypes.object
};

export default MyMessageCell;
