const validate = (values) => {
  const errors = {};

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = '新パスワードと再入力パスワードが一致しません';
  }

  return errors;
};

export default validate;