export const FETCH_COMPANY_LIST = 'FETCH_COMPANY_LIST';
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS';
export const FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const SET_LOADING_SEARCH_COMPANY = 'SET_LOADING_SEARCH_COMPANY';

export const UNSET_COMPANY_PROFILE = 'UNSET_COMPANY_PROFILE';

export const APPROVE_COMPANY_ACCOUNT = 'APPROVE_COMPANY_ACCOUNT';
export const APPROVE_COMPANY_ACCOUNT_SUCCESS = 'APPROVE_COMPANY_ACCOUNT_SUCCESS';
export const APPROVE_COMPANY_ACCOUNT_FAILURE = 'APPROVE_COMPANY_ACCOUNT_FAILURE';