import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { makeSelectLocationSearch, makeSelectLocationPathname } from 'providers/RouterProvider/selectors';
import { onTableChange, updateSearchToState } from 'utils/TableViewMethod';

/**
 * 
 * @param {React.Component} WrappedComponent
 * 
 * [i] - customWithRouter mus be place after withRouter compose
 * 
 */
function customWithRouter(WrappedComponent) {
  const mapStateToProps = (state) => ({
    search: makeSelectLocationSearch(state),
    pathname: makeSelectLocationPathname(state)
  });
  
  
  const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    replace: (path) => dispatch(replace(path)),
    updateSearchToState: updateSearchToState,
    onTableChange: onTableChange
  });
  
  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}

export default customWithRouter;