import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'containers/App/App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router'
import configureStore from 'redux/store';

import 'assets/styles/theme.scss';

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
window.store = store;

const baseDom = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(baseDom, document.getElementById('root'));

history.listen((location, action) => {
  if (action !== 'POP') {
    window.scrollTo(0, 0);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
