import { connect } from 'react-redux';
import { customWithRouter } from 'components/HOC';
import { createSeeker } from 'providers/SeekerProvider/actions';
import { makeSelectGender } from 'providers/ResourceProvider/selectors';
import CreateSeekerPage from './CreateSeekerPage';


const mapStateToProps = () => ({
  gender: makeSelectGender()
});


const mapDispatchToProps = (dispatch) => ({
  createSeeker: (params) => dispatch(createSeeker(params))
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(CreateSeekerPage));