import produce from 'immer';
import _ from 'lodash';

import {  
  GET_NUMBER_OF_SEEKER_SUCCESS,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_ERRORS,
  RESET_NOTIFICATION_STATE
} from './constants';

const initialState = {
  numberOfSeeker: 0,
  sendingNotification: false,
  sendNotificationSucceed: 0
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_NUMBER_OF_SEEKER_SUCCESS:
      state.numberOfSeeker = _.get(action, 'payload.result', 0);
      return state;
    case SEND_NOTIFICATION_REQUEST:
      state.sendingNotification = true;
      state.sendNotificationSucceed = state.numberOfSeeker;
      return state;
    case SEND_NOTIFICATION_SUCCESS:
      state.sendingNotification = false;
      return state;
    case SET_NOTIFICATION_ERRORS:
      state.sendingNotification = false;
      state.sendNotificationSucceed = 0;
      return state;
    case RESET_NOTIFICATION_STATE:
      return initialState;
    default:
      return state;
  }
});

export default reducer;