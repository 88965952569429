import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import Label from '../Label';
import moment from 'moment';
import { handleFocus } from 'utils/reduxForm';

// input.value is moment format
class DatePickerField extends React.PureComponent {
  handleChange = (m) => {
    this.props.input.onChange(m);
  }

  render() {
    const {
      label, placeholder, input, required, formatDatePicker,
      meta: { invalid, error }
    } = this.props;

    delete input.onBlur;

    return (
      <Form.Item
        colon={false}
        label={<Label label={label} required={required} />}
        help={!!input.value && invalid && error}
        validateStatus={!!input.value && invalid && error ? 'error' : ''}
        onFocus={() => handleFocus(this.props)}
      >
        <DatePicker
          {...input}
          placeholder={placeholder}
          value={input.value ? moment(input.value) : undefined }
          onChange={this.handleChange}
          format={formatDatePicker}
          {...this.props}
        />
      </Form.Item>
    );
  }
}

DatePickerField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formatDatePicker: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object.isRequired
};

export default DatePickerField;
