import React from 'react';
import PropTypes from 'prop-types';
import {
  Tag, Badge, Tooltip
} from 'antd';
import _ from 'lodash';
import './style.scss';

const def = {
  ACTIVE: {
    className: 'status-active',
    text: 'アクティブ'
  },
  INACTIVE: {
    className: 'status-inactive',
    text: '非アクティブ'
  },
  ACTIVE_EVENT: {
    className: 'status-active',
    text: '募集中'
  },
  LOCKED: {
    className: 'status-locked',
    text: '確定済み'
  },
  DRAFT: {
    className: 'status-draft',
    text: '下書き'
  },
  FINISHED: {
    className: 'status-finished',
    text: '終了'
  },
  CANCELED: {
    className: 'status-canceled',
    text: 'キャンセル'
  },
  ACCEPTED: {
    className: 'status-active',
    text: '参加決定'
  },
  DENIED: {
    className: 'status-locked',
    text: 'お断り済み'
  },
  MET: {
    className: 'status-met',
    text: 'イベント終了'
  },
  REQUEST: {
    className: 'status-request',
    text: '応募中'
  },
  PENDING: {
    className: 'status-canceled',
    text: '保留中'
  },
  EXAMINING_CANDIDATE: {
    className: 'status-active',
    text: '選考中'
  },
  REJECTED_CANDIDATE: {
    className: 'status-active',
    text: '不採用'
  },
  APPOINTED: {
    className: 'status-active',
    text: '内定'
  }
};

const TENTags = (props) => {
  const { error } = props;
  const status = _.get(def, `[${props.status}]`, {});
  const tag = (
    <Tag
      className={`custom-tag ${status.className}`}
    >
      {status.text}
    </Tag>
  );
  return (
    error ?
      (
        <Tooltip placement="rightTop" title={error} >
          <Badge status="warning" style={{zIndex: 0}}>
            {
              tag
            }
          </Badge>
        </Tooltip >
      ) : (
        tag
      )
  );
};

TENTags.propsTypes = {
  status: PropTypes.any.isRequired,
  error: PropTypes.any.any
};

export default TENTags;