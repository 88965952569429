import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar
} from 'antd';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import _ from 'lodash';
import moment from 'moment';

import Tags from 'components/Preset/Tags';
import PageHeader from 'components/PageHeader';
import ButtonRequestManager from 'containers/JoinEventRequestManager/ButtonRequestManager';
import ModalDenyRequest from 'containers/JoinEventRequestManager/ModalDeny';
import ModalAcceptRequest from 'containers/JoinEventRequestManager/ModalAccept';

import AcceptIconWithText from 'assets/icons/accept-with-text.svg';
import RejectIconWithText from 'assets/icons/reject-with-text.svg';
import Gender from 'assets/icons/gender.svg';
import Age from 'assets/icons/age.svg';
import Dot from 'assets/icons/dot.svg';
import Mail from 'assets/icons/mail.svg';
import Phone from 'assets/icons/phone.svg';
import ContactBorderBottom from 'assets/icons/contact-border-bottom.svg';

import './styles.scss';

class RequestDetail extends React.PureComponent {
  componentDidMount() {
    const { requestId } = this.props.match.params;
    const ids = requestId.split('_');
    const eventId = ids[0];
    const userId = ids[1];
    this.props.fetchRequestProfile({ eventId, userId });
  }

  componentWillUnmount() {
    this.props.unsetRequestProfile();
  }

  renderGender = (gender) => {
    if (gender === 'male') {
      return '男性';
    }
    if (gender === 'female') {
      return '女性';
    }
    if (gender === 'other') {
      return 'その他';
    }
  }

  updateScreenInfo = (eventId, userId) => {
    this.props.fetchRequestProfile({ eventId, userId });
  }

  render() {
    const { profile, event, match } = this.props;
    const kanjiFullname = `${_.get(profile, 'kanjiLastName', '...')} ${_.get(profile, 'kanjiFirstName', '...')}`;
    const kataFullname = `${_.get(profile, 'kataLastName', '...')}${_.get(profile, 'kataFirstName', '...')}`;
    const pageTitle = 'Tenshoku Dashboard | Request';
    const { requestId } = match.params;
    const ids = requestId.split('_');
    const eventId = ids[0];
    const userId = ids[1];
    const valueEx = _.get(profile, 'reasons.reasonEx.valueEx');
    const value = _.get(profile, 'reasons.reasonEx.value', '');
    
    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <div id="request-detail-page">

          <PageHeader title='応募者一覧' goBack={true}/>
          {/* Profile Section */}
          <div className='profile-wrapper'>
            <div className={'subtitle'}>SEEKER PROFILE</div>
            <div className='profile'>
              <div className='contact-wrapper'>
                <div className='avatar-wrapper'>
                  {/* Avatar */}
                  <div>
                    <Avatar size={120} icon="picture" src={_.get(profile, 'avatar.thumbSmall')} />
                  </div>

                  {/* Info */}
                  <div className='info-wrapper'>
                    <div className='fullname-wrapper'>
                      <div className='kanjiFullname'>{kanjiFullname}</div>

                      <div className='kataFullname'>
                        <span className='symbol'>[</span>
                        <span>{` ${kataFullname} `}</span>
                        <span className='symbol'>]</span>
                      </div>
                    </div>

                    <div className='info-row'>
                      <div className='gender'>
                        <div className='icon'><img src={Gender} alt='icon' /></div>
                        <div>{this.renderGender(_.get(profile, 'gender'))}</div>
                      </div>

                      <div className='age'>
                        <div className='icon'><img src={Age} alt='icon' /></div>
                        <div>{`${moment().diff(moment(_.get(profile, 'birthDay')), 'years')}才`}</div>
                      </div>
                    </div>

                    <div className='info-row'>
                      <div className='info-container'>
                        <div className='info-label'>直近の職歴</div>
                        <div className='info'>{_.get(profile, 'currentSates')}</div>
                      </div>

                      {profile.currentIncome &&
                    (
                      <>
                        <div className='dot'><img src={Dot} alt='Dot' /></div>
                        <div className='annual-salary'>
                          <div className='info-container'>
                            <div className='info-label'>現年収</div>
                            <div className='info'>{_.get(profile, 'currentIncome.range')}</div>
                          </div>
                        </div>
                      </>
                    )
                      }
                    </div>

                    <div className='info-row'>
                      <div className='info-container'>
                        <div className='info-label'>最終学歴</div>
                        <div className='info'>{_.get(profile, 'latestAcademic')}</div>
                      </div>

                      {profile.certificate &&
                    (
                      <>
                        <div className='dot'><img src={Dot} alt='Dot' /></div>
                        <div className='annual-salary'>
                          <div className='info-container'>
                            <div className='info-label'>資格</div>
                            <div className='info'>{_.get(profile, 'certificate')}</div>
                          </div>
                        </div>
                      </>
                    )
                      }
                    </div>

                    <div className='info-row'>
                      <Link 
                        to={`/dashboard/seeker/status/${profile.status}/detail/${userId}`} 
                        className='font-weight-medium'
                      >
                        プロフィールを見る
                      </Link>
                    </div>
                  </div>
                </div>

                {/* Contact */}
                <div className='wrapper-request-manager-and-contact'>
                  {_.get(event, 'userStatus') === 'REQUEST' &&
                    <div style={{display: 'flex'}}>
                      <ButtonRequestManager
                        render={ fetchData => 
                          <img
                            alt='Icon Accept'
                            width='48px'
                            height='62px'
                            style={{marginRight: '24px', cursor: 'pointer'}}
                            src={AcceptIconWithText}
                            onClick={fetchData}
                          />
                        }
                        action='ACCEPT'
                        seekerId={userId}
                      />         
                      <ButtonRequestManager
                        render={ fetchData => 
                          <img
                            alt='Icon Reject'
                            width='60px'
                            height='62px'
                            style={{cursor: 'pointer'}}
                            src={RejectIconWithText}
                            onClick={fetchData}
                          />
                        }
                        action='DENY'
                        seekerId={userId}
                      />         
                    </div>
                  }
                  <div className='contact'>
                    <div className='mail'>
                      <div className='icon'><img src={Mail} alt='icon' /></div>
                      <div>{_.get(profile, 'email')}</div>
                    </div>

                    <div className='phone'>
                      <div className='icon'><img src={Phone} alt='icon' /></div>
                      <div>{_.get(profile, 'phone')}</div>
                    </div>

                    <div className='contact-border-bottom'>
                      <img src={ContactBorderBottom} alt='border' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Event Section */}
          <div className='event'>
            <div className={'subtitle'}>応募イベント</div>
            <div className='event-row'>
              <div className='event-row-label'>イベントタイトル</div>
              <div>
                <div className='event-title'>{_.get(event, 'eventTitle')}</div>
                <Link to={`/dashboard/event/detail/${eventId}`} className='font-weight-medium'>イベント詳細</Link>
              </div>
            </div>

            <div className='event-row'>
              <div className='event-row-label'>募集職種</div>
              <div className='font-weight-medium'>{_.get(event, 'eventArea.name')}</div>
            </div>

            <div className='event-row'>
              <div className='event-row-label'>応募日</div>
              <div className='font-weight-medium'>
                {moment(_.get(event, 'requestDate')).locale('ja').format('YYYY/MM/DD(ddd) HH:mm')}
              </div>
            </div>

            <div className='event-row'>
              <div className='event-row-label'>ステータス</div>
              <div><Tags status={_.get(event, 'userStatus', '')} /></div>
            </div>

            <div className='event-row'>
              <div className='event-row-label'>メッセージ</div>
              <div className='font-weight-medium'>{_.get(profile, 'reasons.reason', '')}</div>
            </div>

            <div className='event-row'>
              <div className='event-row-label'>この社長メシに参加する目的</div>
              <div className='font-weight-medium'>{valueEx ? valueEx : value}</div>
            </div>
          </div>
        </div>

        <ModalAcceptRequest
          updateStatusOnScreen={() => this.updateScreenInfo(eventId, userId)}
          eventDetails={event}
        />
        <ModalDenyRequest 
          updateStatusOnScreen={() => this.updateScreenInfo(eventId, userId)}
          eventId={eventId}
        />
      </React.Fragment>
    );
  }
}

RequestDetail.propTypes = {
  match: PropTypes.any,
  profile: PropTypes.object,
  event: PropTypes.object,
  unsetRequestProfile: PropTypes.func,
  fetchRequestProfile: PropTypes.func
};

export default RequestDetail;