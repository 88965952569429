import React from 'react';
import { withRouter } from 'react-router-dom';
import FilterForm from './FilterForm';
import qs from 'querystring';
import { FilterFields as FilterFieldsDefine } from 'components/Preset';
import moment from 'moment';

class SearchBar extends React.PureComponent {
  static FilterFields = FilterFieldsDefine
  state = {}
  onSubmit = ({...valuesOrigin}) => {
    const values = {};
    for (let key in valuesOrigin) {
      values[key] = valuesOrigin[key] && valuesOrigin[key].trim ? valuesOrigin[key].trim() : valuesOrigin[key];
      valuesOrigin[key] instanceof moment && (values[key] = valuesOrigin[key].format('YYYY-MM-DD'));
    }
    const queryString = qs.stringify(values);
    this.props.history.push({
      pathname: this.props.match.pathname,
      search: `${queryString}`
    });
  }

  updateSearchToState = () => {
    let search = this.props.location.search;
    search = search.replace(/^\?/, '');
    search = qs.parse(search);

    this.setState({search});
  } 

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { search } = this.state;
    if (!search) {
      return null;
    }

    delete search.page;
    return (
      <FilterForm
        onSubmit={this.onSubmit}
        filterFields={this.props.filterFields}
        initialValues={search}
      />
    );
  }
}

export default withRouter(SearchBar);
export const FilterFields = FilterFieldsDefine;