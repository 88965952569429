import React from 'react';
import './style.scss';

const LoadingIndicator = () => (
  <div className="page-container" id="login-page-lazyload">
    <div className="login-container">
      <div className="title"></div>
      <div className="title-2"></div>
      <div className="text-field"></div>
      <div className="title-2"></div>
      <div className="text-field"></div>
      <div className="title"></div>
    </div>
  </div>
);

export default LoadingIndicator;
