import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.css';
import './style.scss';
import {Helmet} from 'react-helmet';
import { ConfigProvider } from 'antd';

import { PublicRoutes, PrivateRoutes } from './routes';
import { getResource } from 'providers/ResourceProvider/actions';
import { me } from 'providers/AuthProvider/actions';
import { initFireBase } from 'utils/fireBase';
import LightboxControl from 'components/LightboxGallery/LightboxControl';
import Lightbox from 'components/LightboxGallery/Lightbox';
import Popup from 'components/Popup/ConfirmPopup';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    initFireBase();
  }

  componentDidMount() {
    this.props.getMe();
    this.props.getResource();
  }

  render() {
    return (
      <div className="app-wrapper">
        <Helmet>
          <title>Tenshoku Dashboard</title>
        </Helmet>
        <ConfigProvider autoInsertSpaceInButton={false}>
          <Switch>
            <Route path='/dashboard' component={PrivateRoutes} />
            <Route component={PublicRoutes} />
          </Switch>
        </ConfigProvider>
        
        <LightboxControl />
        <Lightbox />
        <Popup />
      </div>
    );
  }
}

App.propTypes = {
  getMe: PropTypes.func,
  getResource: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  getMe: () => dispatch(me()),
  getResource: () => dispatch(getResource())
});

export default connect(null, mapDispatchToProps)(App);