import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeAnyResource } from 'providers/ResourceProvider/selectors';

const withResource = resourceNames => WrappedComponent => {
  class Outercomponent extends Component {
    render() {
      const innerProps = {...this.props};
      resourceNames.forEach(name => {
        delete innerProps[`__${name}`];
      });
      
      return (
        <WrappedComponent {...this.props}/>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    const propsResource = {};
    resourceNames.forEach(name => {
      propsResource[`__${name}`] = makeAnyResource(state, name);
    });

    return propsResource;
  };

  return connect(mapStateToProps, null)(Outercomponent);
};

export default withResource;