import React from 'react';
import _ from 'lodash';
import {
  Table as AntTable
} from 'antd';

class Table extends React.PureComponent {
  render() {
    const { order, orderBy } = this.props.search || {};

    let columns = [];
    if (order && orderBy && !_.isEmpty(this.props.columns)) {
      columns = this.props.columns.map(col => {
        if (col.key === orderBy) {
          return { ...col, defaultSortOrder: order };
        }
        return col;
      });
    } else {
      columns = this.props.columns;
    }

    // console.log("Table -> render -> columns", columns)
    return (
      <AntTable
        {...this.props}
        columns={columns}
      />
    );
  }
}

export default Table;