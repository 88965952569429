import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { customWithRouter } from 'components/HOC';
import {
  fetchAllActiveCompany,
  updateEvent,
  fetchEventDetail,
  unsetEventDetail,
  fetchReviewedEvent
} from 'providers/EventProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import { 
  makeSelectPosition, makeSelectPrefecture, makeSelectContractType, makeSelectIncome, 
  makeSelectDuration, makeSelectRecruitmentNumbers, makeSelectYesNo, makeSelectEmploymentType
} from 'providers/ResourceProvider/selectors';
import { makeEventDetail, makeAllActiveCompany } from 'providers/EventProvider/selectors';
import EventDetailPage from './EventDetailPage';


const mapStateToProps = (state) => ({
  allActiveCompany: makeAllActiveCompany(state),
  positions: makeSelectPosition(state),
  prefectures: makeSelectPrefecture(state),
  contractTypes: makeSelectContractType(state),
  incomes: makeSelectIncome(state),
  employmentType: makeSelectEmploymentType(state),
  durations: makeSelectDuration(),
  recruitmentNumbers: makeSelectRecruitmentNumbers(),
  yesNoOptions: makeSelectYesNo(),
  detail: makeEventDetail(state)
});


const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(reset('createEvent')),
  openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
  fetchAllActiveCompany: () => dispatch(fetchAllActiveCompany()),
  updateEvent: (params) => dispatch(updateEvent(params)),
  fetchEventDetail: (params) => dispatch(fetchEventDetail(params)),
  unsetEventDetail: () => dispatch(unsetEventDetail()),
  fetchReviewedEvent: (params) => dispatch(fetchReviewedEvent(params))
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(EventDetailPage));