import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Affix, Avatar, Dropdown, Icon } from 'antd';
import { SetPopup } from 'providers/PopupProvider/actions';
import { logout } from 'providers/AuthProvider/actions';
import { makeMe } from 'providers/AuthProvider/selectors';
import { toggleCollapseMenu } from 'providers/LayoutProvider/actions';
import toggleBtn from 'assets/icons/collapse-toggle.svg';
import { connect } from 'react-redux';
const { Header} = Layout;

class CustomNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.menu = this.menu.bind(this);
  }

  menu() {
    return (
      <Menu>
        <Menu.Item onClick={() => this.props.openPopup({
          title: '',
          content: '本当にログアウトしますか？',
          okText: 'はい',
          cancelText: '戻る',
          confirm: this.props.logout
        })}>
          <Icon type="poweroff" />
          {'  ログアウト'}
        </Menu.Item>
      </Menu>
    );
  }
  render() {
    return (
      <Affix offsetTop={0} style={{zIndex: 10}}>
        <Header className="header" >
          <div style={{ height: '64px', width: '100%', backgroundColor: 'white', display: 'flex', flexFlow: 'row-reverse', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0 1px 4px 0 rgba(0, 21, 41, 0.12)' }}>
            
            <Dropdown overlay={this.menu} placement="bottomRight">
              <div style={{marginRight: '24px', height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                オペレーター
                <Avatar shape="circle" size="large" icon="user" style={{marginLeft: '8px'}}/>
              </div>
            </Dropdown>

            <div className="toggle-collapse-btn">
              <img src={toggleBtn} alt='toggle' onClick={this.props.toggleCollapseMenu}/>
            </div>

          </div>
        </Header>
      </Affix>
    );
  }
}

CustomNav.propTypes = {
  openPopup: PropTypes.func,
  logout: PropTypes.func,
  toggleCollapseMenu: PropTypes.func
};

const mapStateToProps = (state) => ({
  me: makeMe(state)
});

const mapDispatchToProps = (dispatch) => ({
  openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
  logout: () => dispatch(logout()),
  toggleCollapseMenu: () => dispatch(toggleCollapseMenu())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNav);