import { connect } from 'react-redux';

import { fetchSeekerList } from 'providers/SeekerProvider/actions';
import { getNewToken } from 'providers/AuthProvider/actions';
import { makeSeekerList } from 'providers/SeekerProvider/selectors';
import { customWithRouter } from 'components/HOC';
import { SetPopup } from 'providers/PopupProvider/actions';

import SearchSeekerPage from './SearchSeekerPage';


const mapStateToProps = (state) => ({
  seekerList: makeSeekerList(state)
});


const mapDispatchToProps = (dispatch) => ({
  fetchSeekerList: (params) => dispatch(fetchSeekerList(params)),
  getNewToken: () => dispatch(getNewToken()),
  openPopupAlert: () => dispatch(SetPopup({name: 'confirmPopup', show: {
    title: '',
    content: '"To date" value have to be larger than "From date" value',
    cancelText: 'OK',
    okButtonProps: { style: { display: 'none' } }
  }})),
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(SearchSeekerPage));