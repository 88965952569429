import produce from 'immer';

import {
  SAMPLE_CONST,
  SAMPLE_CONST_SUCCESS,
  RESET
} from './constants';

const initialState = {
  counter: 0,
  list: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SAMPLE_CONST:
      state.counter += 1;
      return state;

    case SAMPLE_CONST_SUCCESS:
      state.list.push(action.payload);
      return state;

    case RESET:
      return initialState;

    default:
      return state;
  }
});

export default reducer;