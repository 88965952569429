import { connect } from 'react-redux';

import {
  SetPopup
} from 'providers/PopupProvider/actions';
import { makeConfirmPopup } from 'providers/PopupProvider/selectors';

import Popup from './Popup';

const mapStateToProps = (state) => ({
  data: makeConfirmPopup(state)
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(SetPopup({name: 'confirmPopup', show: false}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);