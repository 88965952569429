import {
  loginSuccess,
  loginFail,
  meSuccess,
  getNewTokenSuccess
} from './actions';
import { fetchSeekerProfile } from 'providers/SeekerProvider/actions';
import { fetchCompanyProfile } from 'providers/CompanyProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import {
  ME,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_STATUS,
  GET_NEW_TOKEN
} from './constants';
import { put, takeLeading, call } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';
import { replace } from 'connected-react-router';
import { message } from 'antd';
import _ from 'lodash';
import request from 'utils/request';
import qs from 'query-string';

function * getMe() {
  try {
    const response = yield call(request, '/users/me', {}, 'GET', { requireToken: true });
    yield put(meSuccess(response));
  } catch (e) {
    console.log(e);
  }
}

function* login(action) {
  try {
    const { email, password, state } = action.payload;
    const data = yield call(request, 'login', { email, password }, 'GET');
    if (!data.sessionToken) {
      return;
    }

    localStorage.setItem('__a_token', data.sessionToken);
    yield put(meSuccess(data));
    yield put(loginSuccess({...data, state}));
  } catch (e) {
    yield put(loginFail('Please check your account and login again later'));
  }
}

function* loginFailHandler() {
  localStorage.removeItem('__a_token');
  yield put(replace('/login', window.location.pathname));
}

function* loginSuccessHandler(action) {
  const search = window.location.search || '{}';
  const { href } = qs.parse(search);
  const {state} = action.payload;
  if (href) {
    yield put(replace(decodeURIComponent(href)));
  } else {
    yield put(replace(state === '/login' ? '/dashboard' : state));
  }
}

function * changeEmail(action) {
  try {
    yield call(request, 'functions/changeEmail', action.payload);
    yield put(reset('changeEmail'));
    yield put(SetPopup({name: 'confirmPasswordPopup', show: false}));
    if (action.role === 'COMPANY') {
      yield put(fetchCompanyProfile(action.payload.userId));
    } else {
      yield put(fetchSeekerProfile(action.payload.userId));
    }
    message.success('User email is updated!');
  } catch (e) {
    console.log(e);
    if (e.code === 101) {
      yield put(stopSubmit('changeEmail', { _error: 'パスワードが正しくありません' }));
    } else if (e.code === 141) {
      yield put(stopSubmit('changeEmail', { _error: 'このメールアドレスはすでに登録されています' }));
    } else {
      yield put(stopSubmit('changeEmail', { _error: e.error }));
    }
  }
}

function * changePassword(action) {
  try {
    yield call(request, 'functions/changePassword', action.payload);
    yield put(reset('changePassword'));
    yield put(SetPopup({name: 'confirmPasswordPopup', show: false}));
    message.success('User password is updated!');
  } catch (e) {
    console.log(e);
    if (e.code === 101) {
      yield put(stopSubmit('changePassword', { _error: 'パスワードが正しくありません' }));
    } else {
      yield put(stopSubmit('changePassword', { _error: e.error }));
    }
  }
}

function * resetPassword(action) {
  try {
    yield call(request, 'functions/forgotPassword', {email: action.payload});
    yield put(SetPopup({name: 'confirmPopup', show: {
      title: 'パスワードの更新',
      content: `${action.payload}へパスワードの更新を通知します`,
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { width: '84px' } },
    }}));
  } catch (e) {
    console.log(e);
  }
}

function * updateUserStatus(action) {
  try {
    const { payload } = action;
    yield call(request, 'functions/updateUserStatus', payload);
    if (_.get(payload, 'role', '') === 'COMPANY') {
      yield put(fetchCompanyProfile(payload.userId));
    }
    if (_.get(payload, 'role', '') === 'TENSHOKU-SHA') {
      yield put(fetchSeekerProfile(payload.userId));
    }
  } catch (e) {
    console.log(e);
    yield put(SetPopup({name: 'confirmPopup', show: {
      title: '',
      content: e.error,
      cancelText: 'OK',
      okButtonProps: { style: { display: 'none' } }
    }}));
  }
}

function * getNewToken(action) {
  const {meta} = action;
  try {
    const data = yield call(request, 'functions/getNewToken', {});
    yield put(getNewTokenSuccess(data.result.token, meta));
  } catch (e) {
    console.log(e);
  }
}

export default function* watchNailist() {
  yield takeLeading(ME, getMe);
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGIN_FAIL, loginFailHandler);
  yield takeLeading(LOGIN_SUCCESS, loginSuccessHandler);
  yield takeLeading(CHANGE_EMAIL, changeEmail);
  yield takeLeading(CHANGE_PASSWORD, changePassword);
  yield takeLeading(RESET_PASSWORD, resetPassword);
  yield takeLeading(UPDATE_USER_STATUS, updateUserStatus);
  yield takeLeading(GET_NEW_TOKEN, getNewToken);
}