import moment from 'moment';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'この項目は必須です。';
  }
  if (!values.total) {
    errors.total = 'この項目は必須です。';
  } else if (values.total < 1 || values.total > 1000) {
    errors.total = 'この値は1から1000までの数値で入力してください。';
  }
  if (!values.startDate) {
    errors.startDate = 'この項目は必須です。';
  } else if (moment(values.startDate).diff(moment(), 'days') < 0) {
    errors.startDate = '無効な日付です';
  }
  if (!values.endDate) {
    errors.endDate = 'この項目は必須です。';
  } else if (moment(values.endDate).diff(moment(values.startDate).startOf('day'), 'days') < 1) {
    errors.endDate = '無効な日付です';
  }
  if (!values.typeOfPlan) {
    errors.typeOfPlan = 'この項目は必須です。';
  }

  return errors;
};

export default validate;
