import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Tabs, Avatar
} from 'antd';
import _ from 'lodash';
import Helmet from 'react-helmet';

import BigPageHeader from 'components/PageHeader';

import TabDetail from './TabDetail';
import TabApplicant from './TabApplicant';
import Messages from 'containers/Message';
import TabReview from './TabReview';

import './styles.scss';
import LockEventButton from 'containers/LockEvent/LockEventButton';
import LockEventPopup from 'containers/LockEvent/LockEventPopup';

class EventDetail extends React.PureComponent {
  state = {
    isApplicantTabDisabled: true,
    shouldBlockNavigation: true,
    isEditEvent: false,
    isTabReview: false
  }
  componentDidMount() {
    this.props.fetchEventDetail(this.props.match.params.eventId);
    this.props.fetchAllActiveCompany();
    this.props.fetchReviewedEvent({
      eventId: this.props.match.params.eventId,
      page: 1
    });
  }

  componentDidUpdate() {
    const {shouldBlockNavigation, isEditEvent} = this.state;
    window.onbeforeunload = () => (shouldBlockNavigation && isEditEvent ? true : undefined);
  }

  componentWillUnmount() {
    this.props.unsetEventDetail();
    window.onbeforeunload = () => undefined;
  }

  renderEventStatus = (status) => {
    return status === 'ACTIVE' ? 'ACTIVE_EVENT' : status;
  }

  checkActiveTab = (key) => {
    if (key === '2') {
      this.setState({isApplicantTabDisabled: false});
    } else {
      this.setState({isApplicantTabDisabled: true});   
    }

    if (key === '4') {
      this.setState({isTabReview: true});
    } else {
      this.setState({isTabReview: false});
    }
  }

  updateScreenWhenLocking = () => {
    this.props.fetchEventDetail(this.props.match.params.eventId);
    this.props.history.push(`/dashboard/event/locked/detail/${this.props.match.params.eventId}`);
  }

  renderLockButton = (totalJoined, isApplicantTabDisabled) => {
    if (isApplicantTabDisabled) {
      return <LockEventButton disabled={isApplicantTabDisabled}/>;
    } 
    if (parseInt(totalJoined) <= 0) {
      return <LockEventButton noneJoined={true}/>;
    }
    return <LockEventButton active={true}/>;
  };

  editEvent = async values => {
    this.setState({ shouldBlockNavigation: false });
    const {images, ...params} = values;
    _.unset(params, 'companyId');
    _.unset(params, 'eventDate');
    _.unset(params, 'startTime');
    _.unset(params, 'duration');
    params.image = _.map(images, image => image.objectId);
    await this.props.updateEvent(params);
    await this.props.fetchEventDetail(_.get(params, 'eventId'));
    this.setState({ isEditEvent: false });
  }

  showCancelPopup = (nextLocation) => {
    this.props.openPopup({
      title: '編集の破棄',
      content: 'ページを移動すると現在の編集内容は保存されません。よろしいですか？',
      okText: 'はい',
      cancelText: '戻る',
      okType: 'danger',
      confirm: () => {
        if (nextLocation) {
          this.setState({ shouldBlockNavigation: false }, () => this.props.history.push(nextLocation.pathname));
        } else {
          this.props.reset();
          this.setState({ isEditEvent: false });
        }
      }
    });
  }

  handleBlockedNavigation = nextLocation => {
    const {location} = this.props;
    const {shouldBlockNavigation, isEditEvent} = this.state;
    if (shouldBlockNavigation && isEditEvent 
      && location.pathname !== nextLocation.pathname 
      && nextLocation.pathname !== '/login'
    ) {
      this.showCancelPopup(nextLocation);
      return false;
    }
    return true;
  }

  render() {
    const { TabPane } = Tabs;
    const { detail, match, history } = this.props;
    const pageTitle = 'Tenshoku Dashboard | Event';
    const {isApplicantTabDisabled} = this.state; 
    const link = /^(.+)\/detail\/.+$/.exec(history.location.pathname);
    const initialValues = {
      images: _.get(detail, 'image', []),
      eventTitle: _.get(detail, 'eventTitle'),
      position: _.get(detail, 'position.objectId'),
      eventDate: _.get(detail, 'eventDate'),
      startTime: _.get(detail, 'eventDate'),
      duration: _.get(detail, 'duration'),
      eventArea: _.get(detail, 'eventArea.objectId'),
      restaurantName: _.get(detail, 'restaurantName'),
      recruitmentNumbers: _.get(detail, 'recruitmentNumbers'),
      eventDetail: _.get(detail, 'eventDetail'),
      idealCandidate: _.get(detail, 'idealCandidate'),
      expectedSkill: _.get(detail, 'expectedSkill'),
      employmentType: _.get(detail, 'employmentType.objectId'),
      expectedDate: _.get(detail, 'expectedDate'),
      contractType: _.get(detail, 'contractType.objectId'),
      annualIncome: _.get(detail, 'annualIncome.objectId'),
      bonus: _.get(detail, 'bonus'),
      allowance: _.get(detail, 'allowance'),
      annualLeave: _.get(detail, 'annualLeave'),
      benefit: _.get(detail, 'benefit'),
      trainingProgram: _.get(detail, 'trainingProgram'),
      officeHours: _.get(detail, 'officeHours'),
      workLocation: _.get(detail, 'workLocation.objectId'),
      canTransfer: _.get(detail, 'canTransfer'),
      housingAllowance: _.get(detail, 'housingAllowance'),
      companyHousing: _.get(detail, 'companyHousing')
    };
    initialValues.eventId = _.get(detail, 'objectId');
    initialValues.status = _.get(detail, 'status');
    initialValues.companyId = `${_.get(detail, 'jCreatedBy.objectId')},${_.get(detail, 'jCreatedBy.companyName')}`;

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <div id='header-plus-lock-button-container'>
          <BigPageHeader
            title={_.get(detail, 'eventTitle', '...')}
            goBack={link && link[1] ? link[1] : true}
          >
            <div id='event-created-by'>
              <Avatar size={40} icon="picture" src={_.get(detail, 'jCreatedBy.companyImage.thumbSmall')} />
              <div className='created-by-name'>
                <div className='company-name'>{_.get(detail, 'jCreatedBy.companyName')}</div>
                <div>{_.get(detail, 'jCreatedBy.presidentName')}</div>
              </div>
            </div>
          </BigPageHeader>
          {detail.status === 'ACTIVE' &&
            this.renderLockButton(detail.totalJoinedUser, isApplicantTabDisabled)
          }
        </div>

        <div className="page-container" id="company-detail-page">
          <Tabs defaultActiveKey="1" onTabClick={(key) => this.checkActiveTab(key)}>
            <TabPane tab="イベント概要" key="1">
              <TabDetail 
                onSubmit={this.editEvent} 
                initialValues={initialValues} 
                isEditEvent={this.state.isEditEvent} 
                changeEditState={() => this.setState({ isEditEvent: true })} 
                showCancelPopup={this.showCancelPopup}
                {...this.props} 
              />
            </TabPane>
            <TabPane tab="応募一覧" key="2">
              <TabApplicant 
                eventId={match.params.eventId} 
                eventDetails={detail} 
                fetchEventDetail={this.props.fetchEventDetail}
              />
            </TabPane>
            {(['LOCKED', 'FINISHED'].includes(_.get(detail, 'status')) || 
            (_.get(detail, 'status') === 'ACTIVE' && _.get(detail, 'totalJoinedUser') > 0)) && (
              <TabPane tab="グループチャット" key="3">
                <Messages eventId={match.params.eventId} />
              </TabPane>
            )}
            {(['FINISHED'].includes(_.get(detail, 'status'))) &&
            <TabPane tab="レビュー" key="4" >
              <TabReview isTabReview={this.state.isTabReview} />
            </TabPane>
            }
          </Tabs>
          <LockEventPopup eventId={detail.objectId} updateStatusOnScreen={this.updateScreenWhenLocking}/>
          <Prompt
            when={this.state.shouldBlockNavigation}
            message={this.handleBlockedNavigation}
          />
        </div>
      </React.Fragment>
    );
  }
}

EventDetail.propTypes = {
  push: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  detail: PropTypes.object,
  reset: PropTypes.func,
  openPopup: PropTypes.func,
  updateEvent: PropTypes.func,
  fetchAllActiveCompany: PropTypes.func,
  fetchEventDetail: PropTypes.func,
  unsetEventDetail: PropTypes.func,
  isApplicantTabDisabled: PropTypes.bool,
  fetchReviewedEvent: PropTypes.func
};

export default EventDetail;