import { connect } from 'react-redux';
import {
  unsetSeekerProfile
} from 'providers/SeekerProvider/actions';
import { denyTenshokusha } from 'providers/RequestProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';

import { makeSeekerProfile } from 'providers/SeekerProvider/selectors';
import { makeDenyJoinEventRequest } from 'providers/PopupProvider/selectors';

import ModalDeny from './ModalDeny';


const mapStateToProps = (state) => ({
  profile: makeSeekerProfile(state),
  popupState: makeDenyJoinEventRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
  denyTenshokusha: (params) => dispatch(denyTenshokusha(params)),
  closePopup: () => dispatch(SetPopup({name: 'denyJoinEventRequest', show: false})),
  unsetSeekerProfile: () => dispatch(unsetSeekerProfile())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeny);