export const FETCH_REQUEST_LIST = 'FETCH_REQUEST_LIST';
export const FETCH_REQUEST_LIST_SUCCESS = 'FETCH_REQUEST_LIST_SUCCESS';
export const FETCH_REQUEST_PROFILE = 'FETCH_REQUEST_PROFILE';
export const FETCH_REQUEST_PROFILE_SUCCESS = 'FETCH_REQUEST_PROFILE_SUCCESS';
export const SET_LOADING_SEARCH_REQUEST = 'SET_LOADING_SEARCH_REQUEST';

export const DENY_TENSHOKUSHA = 'DENY_TENSHOKUSHA';
export const DENY_TENSHOKUSHA_SUCCESS = 'DENY_TENSHOKUSHA_SUCCESS';
export const DENY_TENSHOKUSHA_FAILURE = 'DENY_TENSHOKUSHA_FAILURE';

export const ACCEPT_TENSHOKUSHA = 'ACCEPT_TENSHOKUSHA';
export const ACCEPT_TENSHOKUSHA_SUCCESS = 'ACCEPT_TENSHOKUSHA_SUCCESS';
export const ACCEPT_TENSHOKUSHA_FAILURE = 'ACCEPT_TENSHOKUSHA_FAILURE';

export const UNSET_REQUEST_PROFILE = 'UNSET_REQUEST_PROFILE';