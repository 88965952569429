import _ from 'lodash';

export const makeSelectParticipant = (state) => {
  return _.get(state, 'message.participant');
};

export const makeSelectMessageList = (state) => {
  return _.get(state, 'message.messageList');
};

export const makeSelectMessageData = (state) => {
  return _.get(state, 'message.messageData');
};

export const makeSelectMessageReady = (state) => {
  return _.get(state, 'message.messageReady');
};

export const makeSelectLoadMore = (state) => {
  return _.get(state, 'message.loadMore');
};

export const makeSelectHaveNewMessage = (state) => {
  return _.get(state, 'message.haveNewMessage');
};

export const makeSelectIsLoading = (state) => {
  return _.get(state, 'message.isLoading');
};