import { put, takeLeading, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import request from 'utils/request';
import { 
  loginFirebase, logoutFirebase, listenGetNewAddedMessage, getMessageList, getMoreMessageList 
} from 'utils/fireBase';
import { loginFirebaseSuccess, logOutFirebaseSuccess, fetchMessageSuccess, fetchMoreMessageSuccess } from './actions';
import { SEND_MESSAGE, FETCH_MESSAGE, FETCH_MORE_MESSAGE } from './constants';
import { ME_SUCCESS, LOGIN_FAIL } from 'providers/AuthProvider/constants';
import { makeSelectMessageList, makeSelectMessageData, makeSelectMessageReady } from './selectors';

function * initFirebase() {
  try {
    const messageReady = yield select(state => makeSelectMessageReady(state));
    if (!messageReady) {
      yield loginFirebase();
      yield put(loginFirebaseSuccess());
    }
  } catch (err) {
    console.log(err);
  }
}

function * logOutFirebase() {
  try {
    yield logoutFirebase();
    yield put(logOutFirebaseSuccess());
  } catch (err) {
    console.log(err);
  }
}

function * fetchMessage(action) {
  try {
    const { eventId } = action.payload;
    const response = yield call(request, '/functions/eventdetailForChat', action.payload, 'POST');
    const event = _.get(response, 'result');
    yield listenGetNewAddedMessage(eventId);
    const message = yield getMessageList(eventId);
    yield put(fetchMessageSuccess({ event, message }));
  } catch (err) {
    console.log(err);
  }
}

function * fetchMoreMessage(action) {
  try {
    const { eventId } = action.payload;
    const currentMessageList = yield select(state => makeSelectMessageList(state));
    const currentMessageData = yield select(state => makeSelectMessageData(state));
    const lastMessageId = currentMessageList.shift();
    const lastMessageTimeStamp = _.get(currentMessageData[lastMessageId], 'updatedAt');
    const message = yield getMoreMessageList(eventId, lastMessageTimeStamp);
    yield put(fetchMoreMessageSuccess({ message }));
  } catch (err) {
    console.log(err);
  }
}

function * sendMessage(action) {
  try {
    yield call(request, '/functions/sendMessage', action.payload, 'POST');
  } catch (err) {
    console.log(err);
  }
}

export default function* watchMessage() {
  yield takeLeading(ME_SUCCESS, initFirebase);
  yield takeLeading(LOGIN_FAIL, logOutFirebase);
  yield takeLeading(FETCH_MESSAGE, fetchMessage);
  yield takeLeading(FETCH_MORE_MESSAGE, fetchMoreMessage);
  yield takeLeading(SEND_MESSAGE, sendMessage);
}