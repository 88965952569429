import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { customWithRouter } from 'components/HOC';
import {
  fetchCompanyProfile,
  unsetCompanyProfile
} from 'providers/CompanyProvider/actions';
import { updateUserStatus } from 'providers/AuthProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeCompanyProfile } from 'providers/CompanyProvider/selectors';
import CompanyDetailPage from './CompanyDetailPage';

const mapStateToProps = (state) => ({
  profile: makeCompanyProfile(state)
});


const mapDispatchToProps = (dispatch) => ({
  destroyForm: (form) => dispatch(destroy(form)),
  updateUserStatus: (params) => dispatch(updateUserStatus(params)),
  openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
  fetchCompanyProfile: (params) => dispatch(fetchCompanyProfile(params)),
  unsetCompanyProfile: () => dispatch(unsetCompanyProfile())
});

export default customWithRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage));