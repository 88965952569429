import _ from 'lodash';

export const makePayoutPopup = (state) => {
  return _.get(state, 'popup.payout');
};

export const makeCompletePayoutPopup = (state) => {
  return _.get(state, 'popup.completePayout');
};

export const makeConfirmPopup = (state) => {
  return _.get(state, 'popup.confirmPopup');
};

export const makeAcceptJoinEventRequest = (state) => {
  return _.get(state, 'popup.acceptJoinEventRequest');
};

export const makeDenyJoinEventRequest = (state) => {
  return _.get(state, 'popup.denyJoinEventRequest');
};

export const makeLockEventPopup = (state) => {
  return _.get(state, 'popup.lockEventPopup');
};

export const makeLightboxControl = (state) => {
  return _.get(state, 'popup.lightboxControl');
};

export const makeLightbox = (state) => {
  return _.get(state, 'popup.lightbox');
};

export const makeConfirmPasswordPopup = (state) => {
  return _.get(state, 'popup.confirmPasswordPopup');
};