import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from 'antd';
import { TextField } from 'components/Form';

const ConfirmPasswordForm = props => {
  const { error } = props;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
    }
  };

  return (
    <Form onSubmit={props.handleSubmit} {...formItemLayout}>
      <Field
        name="password"
        type="password"
        placeholder="パスワード"
        component={TextField}
        label="パスワード"
        style={{ width: 320 }}
        autoComplete="new-password"
      />

      {error && (
        <div className='system-error-container'>
          <div className='ant-col ant-col-xs-24 ant-col-sm-6' />
          <div className='ant-col ant-col-xs-24 ant-col-sm-18'>
            <div className='system-error'>{error}</div>
          </div>
        </div>
      )}
    </Form>
  );
};

ConfirmPasswordForm.propTypes = {
  error: PropTypes.any,
  handleSubmit: PropTypes.func
};

export default reduxForm({ 
  destroyOnUnmount: false
})(ConfirmPasswordForm);