import { connect } from 'react-redux';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeLightbox, makeLightboxControl } from 'providers/PopupProvider/selectors';
import Lightbox from './Lightbox';

const mapStateToProps = state => {
  return {
    showLightbox: makeLightbox(state),
    showLightboxControl: makeLightboxControl(state)
  };
};

const mapDispatchToProps = dispatch => ({
  closeLightbox: () => dispatch(SetPopup({name: 'lightbox', show: false})),
  closeLightboxControl: () => dispatch(SetPopup({name: 'lightboxControl', show: false}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox);