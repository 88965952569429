import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import moment from 'moment';
import Columns from 'components/Preset/Columns';

import { Card, Button, DatePicker } from 'antd';
import SearchBar from 'components/SearchBar';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';

import './styles.scss';
import { DOWNLOAD_URL } from 'utils/config';

const { FilterFields } = SearchBar;

class SearchSeekerPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
    this.state = {
      fromDate: null,
      toDate: null
    };
  }

  updateSearchToState = () => {
    this.props.updateSearchToState('searchSeeker', this.props, this.props.fetchSeekerList, this.props.match.params);
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  goToCreateSeekerPage = () => {
    this.props.push('/dashboard/seeker/create');
  }

  download(href) {
    const holder = this.linkRef.current;
    holder.setAttribute('href', href);
    holder.click();
  }

  clickHandler = (e) => {
    e.preventDefault();
    if (this.checkDateForDownload()) {
      const { fromDate, toDate } = this.state;
      const zone = moment().utcOffset();
      const dateQuery = `&fromDate=${fromDate ? fromDate.toISOString() : ''}&toDate=${toDate ? toDate.toISOString() : ''}`;

      this.props.getNewToken()
        .then(token => {
          this.download(`${DOWNLOAD_URL}extra/students?token=${token}${dateQuery}&zone=${zone}`);
        })
        .catch(err => {
          console.log(err);
        });
    }

  }

  changeDate = (value, key) => {
    const newState = {}
    newState[key] = value ? (key === 'fromDate' ? value.startOf('day') : value.endOf('day')) : value
    this.setState(newState)
  }

  checkDateForDownload = () => {
    const { fromDate, toDate } = this.state
    if (fromDate && toDate && fromDate > toDate) {
      this.props.openPopupAlert()
      return false
    }
    return true
  }


  render() {
    const { total, loading, list } = this.props.seekerList;
    const { search } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;

    const filterFields = [
      FilterFields.seekerName,
      FilterFields.email,
      FilterFields.createdAt
    ];

    const columns = [
      Columns.roundedAvatar,
      Columns.seekerName,
      Columns.email,
      Columns.createdAt,
      Columns.lastLogin,
      {
        title: 'アクション',
        dataIndex: 'objectId',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => (
          <Link to={`${this.props.history.location.pathname}/detail/${record}`}>
            プロフィールを見る
          </Link>
        )
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Tenshoku Dashboard | Seeker | Search</title>
        </Helmet>
        <PageHeader
          //goBack={'/dashboard/'}
          title={'転職者リスト'}
          extra={(<Button type='primary' size='large' onClick={this.goToCreateSeekerPage}>アカウントを作成</Button>)}
        //breadcrumb={PageHeader.Bread.nailistSearch}
        />
        <div className="page-container" id="seeker-page-search">
          <div className="export-csv-container">
            <div>Export to CSV</div>
            <DatePicker
              style={{ width: 120, marginLeft: 8 }}
              placeholder="From Date"
              onChange={e => this.changeDate(e, 'fromDate')}
            />
            <div style={{ marginLeft: 8 }}>-</div>
            <DatePicker
              style={{ width: 120, marginLeft: 8 }}
              placeholder="To Date"
              onChange={e => this.changeDate(e, 'toDate')}
            />
            <div className="export-csv-button" onClick={this.clickHandler}>Export</div>
          </div>
          <a href="/" ref={this.linkRef} style={{ display: 'none' }} target="_blank" rel="noopener noreferrer">{null}</a>

          <Card>
            <div className="search-section">
              <SearchBar filterFields={filterFields} />
            </div>
            <Table
              search={this.props.search}
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={this.onTableChange}
              pagination={{
                total: total,
                showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
                pageSize: 10,
                current: currentPage
              }}
              loading={loading}
            />
          </Card>
        </div>
      </Fragment>
    );
  }
}

SearchSeekerPage.propTypes = {
  fetchSeekerList: PropTypes.func.isRequired,
  getNewToken: PropTypes.func,
  seekerList: PropTypes.array,
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func,
  openPopupAlert: PropTypes.func
};

export default SearchSeekerPage;