import { connect } from 'react-redux';
import { createCompany } from 'providers/CompanyProvider/actions';
import { customWithRouter } from 'components/HOC';

import CreateCompanyPage from './CreateCompanyPage';

const mapDispatchToProps = (dispatch) => ({
  createCompany: (params) => dispatch(createCompany(params))
});

export default customWithRouter(connect(null, mapDispatchToProps)(CreateCompanyPage));