import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import SearchCompanyPage from './SearchCompanyPage';
import CompanyDetailPage from './CompanyDetailPage';
import CreateCompanyPage from './CreateCompanyPage';

class CompoanyRouter extends React.PureComponent {
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.path}/status/:status/detail/:companyId`} component={CompanyDetailPage}/>
          <Route path={`${match.path}/status/:status`} component={SearchCompanyPage}/>
          <Route path={`${match.path}/create`} component={CreateCompanyPage} exact/>
          <Redirect to={`${match.path}/status/ACTIVE`} />
        </Switch>
        
      </React.Fragment>
    );
  }
}

CompoanyRouter.propTypes = {
  onSubmit: PropTypes.func,
  match: PropTypes.any
};

export default CompoanyRouter;