import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { isToday } from 'utils/helpers';
import './style.scss';

export default class TimeSection extends Component {
  formatDateHeader = (date) => {
    if (isToday(date)) {
      return 'Today';
    }

    return date.format('YYYY/MM/DD');
  }

  // This condition check still possible to group message within differenceMinuteNumber.
  isGreaterThan = (date, prevDate) => {
    const { differenceMinuteNumber } = this.props;
    return date.diff(prevDate, 'minutes') > differenceMinuteNumber;
  }

  isLastMessage = (date, prevDate, senderId, prevSenderId) => {
    return this.isGreaterThan(date, prevDate) || senderId !== prevSenderId;
  }

  groupMessage = () => {
    const { children, timePath, senderIdPath, messageCellProps } = this.props;
    let curDateHeader = '';
    let curSenderId = '';
    let curDate = moment();
    let msgList = [];

    for (let i = 0; i < children.length; i++) {
      const date = moment(_.get(children[i], `props.${timePath}`));
      const dateHeader = date.format('YYYY/MM/DD');

      if (curDateHeader !== dateHeader) {
        msgList.push(
          <div className='date-header' key={`time-sec-dead-${i + Math.random().toString()}`}>
            {this.formatDateHeader(date)}
          </div>
        );
        // Update date header
        curDateHeader = dateHeader;
      }

      const senderId = _.get(children[i], `props.${senderIdPath}`);
      // Last message style section
      const nextDate = moment(_.get(children[i + 1], `props.${timePath}`));
      const nextSenderId = _.get(children[i + 1], `props.${senderIdPath}`);
      const lastMessage = this.isLastMessage(nextDate, date, nextSenderId, senderId) ? 'message-cell-end' : '';

      if (!this.isLastMessage(date, curDate, senderId, curSenderId)) {
        msgList.push(React.cloneElement(children[i], { ...messageCellProps, ordinalMessage: lastMessage }));
      } else {
        msgList.push(React.cloneElement(children[i], { ordinalMessage: `message-cell-begin ${lastMessage}` }));
        // Update sender id
        curSenderId = senderId;
      }
      // Always update date
      curDate = date;
    }

    return msgList;
  }

  render() {
    return this.groupMessage();
  }
}

TimeSection.propTypes = {
  children: PropTypes.any,
  differenceMinuteNumber: PropTypes.number,
  timePath: PropTypes.string,
  senderIdPath: PropTypes.string,
  messageCellProps: PropTypes.object
};