import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_EVENT_DETAIL_SUCCESS,
  FETCH_EVENT_DETAIL_FAILURE,
  FETCH_ALL_ACTIVE_COMPANY_SUCCESS,
  SET_LOADING_SEARCH_EVENT,
  UNSET_EVENT_DETAIL,
  FETCH_REVIEWED_EVENT_SUCCESS,
  FETCH_MORE_REVIEWED_EVENT_SUCCESS
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  detail: {

  },
  company: [

  ],
  review: { 
    hasFetchAllReview: false
  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;
    case FETCH_EVENT_DETAIL_SUCCESS:
      state.detail = _.get(action, 'payload.detail');
      return state;
    case FETCH_EVENT_DETAIL_FAILURE:
      state.detail = {};
      return state;
    case FETCH_ALL_ACTIVE_COMPANY_SUCCESS:
      state.company = _.get(action, 'payload.company');
      return state;
    case FETCH_REVIEWED_EVENT_SUCCESS:
      state.review = _.get(action, 'payload');
      return state;
    case FETCH_MORE_REVIEWED_EVENT_SUCCESS: {
      const seekerReviews = _.get(action, 'payload.seekerReviews');
      if (_.isEmpty(seekerReviews)) state.review.hasFetchAllReview = true;
      else {
        const newReviewList = state.review.seekerReviews.concat(seekerReviews);
        state.review.seekerReviews = _.uniq(newReviewList);
      }
      return state;
    }
    case SET_LOADING_SEARCH_EVENT:
      state.search.loading = _.get(action, 'payload.loading');
      return state;
    case UNSET_EVENT_DETAIL:
      state.detail = initialState.detail;
      return state;
    default:
      return state;
  }
});

export default reducer;