import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Card, Form, Affix } from 'antd';
import { 
  TextField, TextArea, Select as SelectField, ExtraSelect, SearchSelect, DatePicker, MonthPicker, TimePicker, MultiImage
} from 'components/Form';
import validate from './validate';
import moment from 'moment';

class CreateEventForm extends React.Component {
  render() {
    const { 
      positions, durations, prefectures, recruitmentNumbers, contractTypes, incomes, yesNoOptions, allActiveCompany, 
      handleSubmit, error, pristine, submitting, invalid, employmentType
    } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Form onSubmit={handleSubmit} {...formItemLayout}>
        <Card className="create-event-tab">
          <div className="create-event-tab-title">基本情報</div>

          <Field
            name="images"
            component={MultiImage}
            label="カバー写真"
            required
            type='EVENT'
          />
          
          <Field
            name="eventTitle"
            type="text"
            placeholder="イベントタイトル"
            component={TextArea}
            label="イベントタイトル"
            style={{ width: 468 }}
            required
            maxLength={100}
          />

          <Field
            name='position'
            type='text'
            placeholder="募集職種"
            component={SelectField}
            label='募集職種'
            style={{ width: 468 }}
            required
            options={positions}
          />

          <Field
            name='companyId'
            type='text'
            placeholder="企業名"
            component={SearchSelect}
            label='企業名'
            style={{ width: 468 }}
            required
            options={allActiveCompany}
            optionName='companyName'
            optionImage='companyImage'
          />
        </Card >
        
        <Card className="create-event-tab">
          <div className="create-event-tab-title">イベント情報</div>

          <Field
            name='startDate'
            type='text'
            placeholder="yyyy/mm/dd"
            component={DatePicker}
            label='開催日'
            style={{ width: 468 }}
            required
            formatDatePicker={['YYYY/MM/DD(ddd)', 'YYYY/MM/DD', 'YYYY/MM/D', 'YYYY/M/DD', 'YYYY/M/D']}
            disabledDate={currentDate => moment(currentDate).diff(moment(), 'days') < 3}
          />

          <Field
            name='startTime'
            type='text'
            placeholder="開始時間"
            component={TimePicker}
            label='開始時間'
            style={{ width: 468 }}
            required
            formatTimePicker='HH:mm'
            minuteStep={15}
            disabledHours={() => [23, 0, 1, 2, 3, 4, 5, 6]}
            disabledMinutes={(selectedHour) => (selectedHour === 22 ? [15, 30, 45] : [])}
            maxTime={'22:00'}
            hideDisabledOptions
          />

          <Field
            name='duration'
            type='text'
            placeholder="開催時間"
            component={SelectField}
            label='開催時間'
            style={{ width: 468 }}
            required
            options={durations}
          />
          <Field
            name='eventArea'
            type='text'
            placeholder="開催エリア"
            component={SelectField}
            label='開催エリア'
            style={{ width: 468 }}
            required
            options={prefectures}
          />

          <Field
            name="restaurantName"
            type="text"
            placeholder="開催場所（店名)"
            component={TextField}
            label="開催場所（店名)"
            style={{ width: 468 }}
            required
          />

          <Field
            name='recruitmentNumbers'
            type='text'
            placeholder="募集人数"
            component={SelectField}
            label='募集人数'
            style={{ width: 468 }}
            required
            options={recruitmentNumbers}
          />
        </Card >

        <Card className="create-event-tab">
          <div className="create-event-tab-title">詳細情報 </div>

          <Field
            name="eventDetail"
            type="text"
            placeholder="内容"
            component={TextArea}
            label="内容"
            style={{ width: 468 }}
            required
          />
          <Field
            name="idealCandidate"
            type="text"
            placeholder="求める人物像"
            component={TextArea}
            label="求める人物像"
            style={{ width: 468 }}
            required
          />
          <Field
            name="expectedSkill"
            type="text"
            placeholder="求めるスキル・経験"
            component={TextArea}
            label="求めるスキル・経験"
            style={{ width: 468 }}
            required
          />

          <Field
            name='employmentType'
            type='text'
            placeholder="雇用形態"
            component={SelectField}
            label='雇用形態'
            style={{ width: 468 }}
            options={employmentType}
          />

          <Field
            name='expectedDate'
            type='text'
            placeholder="yyyy/mm"
            component={MonthPicker}
            label='入社時期'
            style={{ width: 468 }}
            required
            formatMonthPicker='YYYY年MM月'
            disabledDate={currentDate => moment(currentDate).diff(moment(), 'months') < 0}
          />

          <Field
            name='contractType'
            type='text'
            placeholder="雇用形態"
            component={SelectField}
            label='雇用形態'
            style={{ width: 468 }}
            options={contractTypes}
          />
          <Field
            name='annualIncome'
            type='text'
            placeholder="給与"
            component={SelectField}
            label='給与'
            style={{ width: 468 }}
            options={incomes}
          />

          <Field
            name="bonus"
            type="text"
            placeholder="昇給・賞与"
            component={TextArea}
            label="昇給・賞与"
            style={{ width: 468 }}
          />

          <Field
            name="allowance"
            type="text"
            placeholder="諸手当"
            component={TextField}
            label="諸手当"
            style={{ width: 468 }}
          />

          <Field
            name="annualLeave"
            type="text"
            placeholder="休日休暇"
            component={TextArea}
            label="休日休暇"
            style={{ width: 468 }}
          />
          <Field
            name="benefit"
            type="text"
            placeholder="福利厚生"
            component={TextArea}
            label="福利厚生"
            style={{ width: 468 }}
          />

          <Field
            name="trainingProgram"
            type="text"
            placeholder="研修内容"
            component={TextField}
            label="研修内容"
            style={{ width: 468 }}
          />
          <Field
            name="officeHours"
            type="text"
            placeholder="勤務時間"
            component={TextField}
            label="勤務時間"
            style={{ width: 468 }}
          />

          <Field
            name='workLocation'
            type='text'
            placeholder="勤務地"
            component={SelectField}
            label='勤務地'
            style={{ width: 468 }}
            options={prefectures}
          />

          <Field
            name='canTransfer'
            type='text'
            placeholder="転勤"
            component={ExtraSelect}
            label='転勤'
            style={{ width: 468 }}
            options={yesNoOptions}
            maxLength={100}
          />
          <Field
            name='housingAllowance'
            type='text'
            placeholder="住宅手当"
            component={ExtraSelect}
            label='住宅手当'
            style={{ width: 468 }}
            options={yesNoOptions}
            maxLength={100}
          />
          <Field
            name='companyHousing'
            type='text'
            placeholder="寮、社宅提供"
            component={ExtraSelect}
            label='寮、社宅提供'
            style={{ width: 468 }}
            options={yesNoOptions}
            maxLength={100}
          />
        </Card >

        <Affix offsetBottom={0}>
          <div className='create-event-button-container'>
            <button 
              type='button' 
              className='ten-ad-btn draft-button' 
              onClick={this.props.onDraft} 
              disabled={pristine}
            >
              下書き保存
            </button>
            
            <div className='primary-button-container'>
              <button 
                type='button' 
                className='ten-ad-btn cancel-button' 
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </button>

              <button 
                type='submit' 
                className='ten-ad-btn primary-button' 
                disabled={error || submitting || invalid}
              >
                募集開始
              </button>
            </div>
          </div>
        </Affix>
      </Form>
    );
  }
}

CreateEventForm.propTypes = {
  detail: PropTypes.object,
  positions: PropTypes.array,
  durations: PropTypes.array,
  prefectures: PropTypes.array,
  recruitmentNumbers: PropTypes.array,
  contractTypes: PropTypes.array,
  incomes: PropTypes.array,
  employmentType: PropTypes.array,
  yesNoOptions: PropTypes.array,
  allActiveCompany: PropTypes.array,
  handleSubmit: PropTypes.func,
  onDraft: PropTypes.func,
  history: PropTypes.any,
  error: PropTypes.any,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

export default reduxForm({ 
  form: 'createEvent', 
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateEventForm);