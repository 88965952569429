import produce from 'immer';
import _ from 'lodash';

import { GET_RESOURCE_SUCCESS } from './constants';

export const initialState = {
  Tag: [],
  Prefecture: [],
  Position: [],
  NOFEmployees: [],
  Location: [],
  Job: [],
  Industry: [],
  Income: [],
  ContractType: [],
  BranchOffice: [],
  AnswersForWhat: [],
  AnswersForHow: [],
  employmentType: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCE_SUCCESS: {
      _.forEach(action.payload, (value, key) => {
        state[key] = value;
      });
      return state;
    }
    default:
      return state;
  }
});

export default reducer;
