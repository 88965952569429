import {
  fetchRequestListSuccess,
  fetchRequestProfileSuccess,
  setSearchRequestLoading,
  denyTenshokushaSuccess,
  denyTenshokushaFailure,
  acceptTenshokushaSuccess,
  acceptTenshokushaFailure
} from './actions';

import {
  FETCH_REQUEST_LIST,
  FETCH_REQUEST_PROFILE,
  DENY_TENSHOKUSHA,
  ACCEPT_TENSHOKUSHA
} from './constants';

import { put, takeLeading, call } from 'redux-saga/effects';
import request from 'utils/request';
import moment from 'moment';

function* fetchRequestList(action) {
  try {
    yield put(setSearchRequestLoading({loading: true}));

    const filter = {...action.payload} || {};
    filter.limit = 10;

    if (filter.requestDate) {
      const requestDate = filter.requestDate;
      filter.fromDate = moment(requestDate).startOf('D').toDate();
      filter.toDate = moment(requestDate).endOf('D').toDate();
      delete filter.requestDate;
    }

    delete filter.status;
    if (filter.requestStatus) {
      filter.status = filter.requestStatus;
    }
    
    const { result } = yield call(request, 'functions/getApplicantList', filter);

    yield put(fetchRequestListSuccess(result));
    yield put(setSearchRequestLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function* fetchRequestProfile(action) {
  try {
    yield put(setSearchRequestLoading({loading: true}));
    const response = yield call(request, 'functions/getTSProfileInEvent', action.payload);
    yield put(fetchRequestProfileSuccess({ profile: response.result.TSProfile, event: response.result.event }));
    yield put(setSearchRequestLoading({loading: false}));
  } catch (e) {
    console.log(e);
  }
}

function * handleDenyTenSha(action) {
  const { meta, payload } = action;
  try {
    const response = yield call(request, 'functions/denyRequestJoinEvent', payload);
    yield put(denyTenshokushaSuccess({ meta, payload: response.result }));
  } catch (e) {
    console.log(e);
    yield put(denyTenshokushaFailure({ meta }));
  }
}

function * handleAcceptTenSha(action) {
  const { meta, payload } = action;
  try {
    const response = yield call(request, 'functions/acceptRequestJoinEvent', payload);
    yield put(acceptTenshokushaSuccess({ meta, payload: response.result }));
  } catch (e) {
    console.log(e);
    yield put(acceptTenshokushaFailure({ meta }));
  }
}


export default function* watchRequest() {
  yield takeLeading(FETCH_REQUEST_LIST, fetchRequestList);
  yield takeLeading(FETCH_REQUEST_PROFILE, fetchRequestProfile);
  yield takeLeading(DENY_TENSHOKUSHA, handleDenyTenSha);
  yield takeLeading(ACCEPT_TENSHOKUSHA, handleAcceptTenSha);
}
