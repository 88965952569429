import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import Label from '../Label';

const { Option } = Select;

class SelectField extends React.PureComponent {
  handleChange = (e) => {
    this.props.input.onChange(e || '');
  }
  render() {
    const {
      label,
      placeholder,
      input,
      options,
      style,
      required,
      disabled,
      meta: { touched, invalid, error },
      ...rest
    } = this.props;

    const optionElements = (this.props.children) || (options
    && options.map((option) => (
      <Option key={option.objectId} value={option.objectId}>
        {option.name}
      </Option>
    )));
      
    return (
      <Form.Item 
        colon={false} 
        label={<Label label={label} required={required} />} 
        help={touched && invalid && error} 
        validateStatus={touched && invalid && error ? 'error' : ''}
      >
        <Select
          {...input}
          allowClear
          placeholder={placeholder}
          value={input.value || undefined}
          onChange={this.handleChange}
          style={style}
          disabled={disabled}
          {...rest}
        >
          {optionElements}
        </Select>
      </Form.Item>
    );
  }
}

SelectField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  style: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default SelectField;
