import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import Columns from 'components/Preset/Columns';

import {
  Card
} from 'antd';
import SearchBar from 'components/SearchBar';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';

import './styles.scss';

const { FilterFields } = SearchBar;

class SearchApplicantPage extends React.PureComponent {

  updateSearchToState = () => {
    this.props.updateSearchToState('searchApplicant', this.props, this.props.fetchListData);
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { total, loading, list } = this.props.list;
    const { search } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;

    const filterFields = [
      FilterFields.requestKeyword,
      FilterFields.position,
      FilterFields.requestedDate,
      FilterFields.requestStatus
    ];

    const columns = [
      Columns.requestSeekerAvatar,
      Columns.requestSeekerName,
      Columns.requestEventTitle,
      Columns.position,
      Columns.requestedDate,
      Columns.requestStatus,
      {
        title: 'アクション',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => (
          <Link to={`/dashboard/request/detail/${record.eventId}_${record.seekerId}`}>
            詳細を見る
          </Link>
        )
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Tenshoku Dashboard | Applicant | Search</title>
        </Helmet>
        <PageHeader
          //goBack={'/dashboard/'}
          title={'企業一覧'}
          //breadcrumb={PageHeader.Bread.nailistSearch}
        />
        <div className="page-container" id="applicant-page-search">
          <Card>
            <div className="search-section">
              <SearchBar filterFields={filterFields}/>
            </div>
            <Table
              search={this.props.search}
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={this.onTableChange}
              pagination={{
                total: total,
                showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
                pageSize: 10,
                current: currentPage
              }}
              loading={loading}
            />
          </Card>
        </div>
      </Fragment>
    );
  }
}

SearchApplicantPage.propTypes = {
  fetchListData: PropTypes.func.isRequired,
  list: PropTypes.array,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func
};

export default SearchApplicantPage;