import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Form } from 'antd';

import Label from '../Label';

const RadioButton = (props) => {
  const {
    label, input, options = [], required = false, className = '',
    meta: { touched, invalid, error }
  } = props;
    
  return (
    <Form.Item
      className={className}
      label={<Label label={label} required={required} />}
      colon={false}
      help={touched && invalid && error}
    >
      <Radio.Group {...input} {...props}>
        {options.map((option) => (
          <Radio key={option.value} value={option.value}>{option.label}</Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

RadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.any,
  required: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.array,
  meta: PropTypes.object.isRequired
};

export default RadioButton;
