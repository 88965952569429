import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';

import Table from 'components/Table';
import { getScoutsRequest } from 'providers/PlanProvider/actions';
import { customWithRouter } from 'components/HOC';
import Columns from 'components/Preset/Columns';

class TabScout extends React.Component {
  updateSearchToState = () => {
    this.props.updateSearchToState(
      'scoutEventTab',
      this.props,
      this.props.getScouts,
      {
        companyId: this.props.companyId
      }
    );
  };

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  componentWillUnmount() {
    this.props.replace(this.props.pathname);
  }

  render() {
    const currentPage = _.get(this.props.search, 'page')
      ? Number(this.props.search.page)
      : 1;

    const columns = [
      {
        title: 'スカウトID',
        dataIndex: 'objectId'
      },
      {
        title: 'スカウト転職者',
        dataIndex: 'tenshoku',
        render: (record) => (
          <Link
            to={`/dashboard/seeker/status/ACTIVE/detail/${record.objectId}`}
            className="table-cell-link"
            target="_blank"
          >
            {_.get(record, 'kanjiFullName', '')}
            <br />
            [{_.get(record, 'katafullName', '')}]
          </Link>
        )
      },
      {
        title: 'スカウト送信日時',
        dataIndex: 'createdAt',
        sorter: true,
        render: (startDate) =>
          (startDate ? moment(startDate).format('YYYY/MM/DD HH:mm') : '--')
      },
      {
        title: 'スカウト成立日時',
        dataIndex: 'acceptedAt',
        sorter: true,
        render: (endDate) =>
          (endDate ? moment(endDate).format('YYYY/MM/DD HH:mm') : '--')
      },
      {
        ...Columns.eventStatus,
        key: 'status',
        sorter: true,
        fixed: 'right',
        align: 'center'
      }
    ];

    return (
      <Card>
        <Table
          search={this.props.search}
          columns={columns}
          dataSource={this.props.list}
          scroll={{ x: 'max-content' }}
          onChange={this.onTableChange}
          pagination={{
            total: this.props.total,
            showTotal: (total, range) =>
              `${total}中${range[0]}-${range[1]}項目を表示`,
            pageSize: 20,
            current: currentPage
          }}
          loading={this.props.isLoading}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.plan.isLoading,
  list: state.plan.list,
  total: state.plan.total
});

const mapDispatchToProps = (dispatch) => ({
  getScouts: (params) => dispatch(getScoutsRequest(params))
});

TabScout.propTypes = {
  companyId: PropTypes.string,
  location: PropTypes.object,
  search: PropTypes.object,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.array,
  getScouts: PropTypes.func,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func,
  replace: PropTypes.func,
  pathname: PropTypes.string
};

export default compose(
  withRouter,
  customWithRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TabScout);
