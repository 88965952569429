import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import './style.scss';

class Lightbox extends Component {
  handleClose = () => {
    this.props.closeLightbox();
    this.props.showLightboxControl && this.props.closeLightboxControl();
  }

  render() {
    return (
      <Modal
        visible={!!this.props.showLightbox}
        wrapClassName='lightbox'
        onCancel={this.handleClose}
        destroyOnClose={true}
        closable={false}
        footer={null}
        width='unset'
        style={{ paddingBottom: 0 }}
        bodyStyle={{ padding: 0 }}
        centered
      >  
        <img alt="lightboxImage" src={this.props.showLightbox.url} />
      </Modal>
    );
  }
}

Lightbox.propTypes = {
  showLightbox: PropTypes.any,
  showLightboxControl: PropTypes.any,
  closeLightbox: PropTypes.func,
  closeLightboxControl: PropTypes.func
};

export default Lightbox;