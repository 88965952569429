import { ReactComponent as iconEventMng } from 'assets/icons/event-management.svg';
import { ReactComponent as dotPublish } from 'assets/icons/dot-publish.svg';
import { ReactComponent as dotLock } from 'assets/icons/dot-lock.svg';
import { ReactComponent as dotDraft } from 'assets/icons/dot-draft.svg';
import { ReactComponent as dotFinish } from 'assets/icons/dot-finish.svg';
import { ReactComponent as dotCancel } from 'assets/icons/dot-cancel.svg';
import { ReactComponent as companyManagement } from 'assets/icons/company-management.svg';
import { ReactComponent as seekerManagement } from 'assets/icons/seeker-management.svg';
import { ReactComponent as activeUser } from 'assets/icons/active_user.svg';
import { ReactComponent as inactiveUser } from 'assets/icons/inactive_user.svg';
import { ReactComponent as waitingUser } from 'assets/icons/waiting_user.svg';

export default [
  {
    key: '/company',
    iconCus: companyManagement,
    text: '企業',
    subMenu: [
      {
        _to: '/company/status/WAITING_FOR_APPROVE',
        text: '未承認',
        iconCus: waitingUser,
        smallIcon: true
      },
      {
        _to: '/company/status/ACTIVE',
        text: 'アクティブ',
        iconCus: dotPublish,
        smallIcon: true
      },
      {
        _to: '/company/status/INACTIVE',
        text: '非アクティブ',
        iconCus: inactiveUser,
        smallIcon: true
      }
    ]
  },
  {
    key: '/seeker',
    iconCus: seekerManagement,
    text: '転職者',
    subMenu: [
      {
        _to: '/seeker/status/ACTIVE',
        text: 'アクティブ',
        iconCus: activeUser,
        smallIcon: true
      },
      {
        _to: '/seeker/status/INACTIVE',
        text: '非アクティブ',
        iconCus: inactiveUser,
        smallIcon: true
      },
      {
        _to: '/seeker/status/DELETE_PROCESS',
        text: '削除処理',
        iconCus: dotCancel,
        smallIcon: true
      },
      {
        _to: '/seeker/status/DELETED',
        text: '削除済み',
        iconCus: dotLock,
        smallIcon: true
      }
    ]
  },
  {
    _to: '/event',
    iconCus: iconEventMng,
    text: 'イベント管理',
    subMenu: [
      {
        _to: '/event/published',
        iconCus: dotPublish,
        text: '募集中',
        smallIcon: true
      },
      {
        _to: '/event/locked',
        iconCus: dotLock,
        text: '開催決定',
        smallIcon: true
      },
      {
        _to: '/event/draft',
        iconCus: dotDraft,
        text: '下書き',
        smallIcon: true
      },
      {
        _to: '/event/finished',
        iconCus: dotFinish,
        text: '終了',
        smallIcon: true
      },
      {
        _to: '/event/canceled',
        iconCus: dotCancel,
        text: 'キャンセル',
        smallIcon: true
      }
    ]
  },
  {
    _to: '/notifications',
    icon: 'sound',
    text: 'プッシュ通知',
    subMenu: [
      {
        _to: '/notifications/recommendEvent',
        iconCus: dotFinish,
        text: 'イベントPR',
        smallIcon: true
      }
    ]
  },
  {
    _to: '/request',
    icon: 'profile',
    text: '応募者管理'
  }
];