export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST';
export const FETCH_EVENT_LIST_SUCCESS = 'FETCH_EVENT_LIST_SUCCESS';
export const FETCH_EVENT_DETAIL = 'FETCH_EVENT_DETAIL';
export const FETCH_EVENT_DETAIL_SUCCESS = 'FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_FAILURE = 'FETCH_EVENT_DETAIL_FAILURE';
export const FETCH_ALL_ACTIVE_COMPANY = 'FETCH_ALL_ACTIVE_COMPANY';
export const FETCH_ALL_ACTIVE_COMPANY_SUCCESS = 'FETCH_ALL_ACTIVE_COMPANY_SUCCESS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const SET_LOADING_SEARCH_EVENT = 'SET_LOADING_SEARCH_EVENT';

export const UNSET_EVENT_DETAIL = 'UNSET_EVENT_DETAIL';

export const DELETE_DRAFT_EVENT = 'DELETE_DRAFT_EVENT';
export const DELETE_DRAFT_EVENT_SUCCESS = 'DELETE_DRAFT_EVENT_SUCCESS';

export const LOCK_EVENT_REQUEST = 'LOCK_EVENT_REQUEST';
export const LOCK_EVENT_SUCCESS = 'LOCK_EVENT_SUCCESS';

export const FETCH_REVIEWED_EVENT = 'FETCH_REVIEWED_EVENT';
export const FETCH_REVIEWED_EVENT_SUCCESS = 'FETCH_REVIEWED_EVENT_SUCCESS';
export const FETCH_MORE_REVIEWED_EVENT_SUCCESS = 'FETCH_MORE_REVIEWED_EVENT_SUCCESS';