import React from 'react';
import { Layout } from 'antd';
import NavBar from './NavBar';
import SideMenu from './SideMenu';
import { layout } from 'utils/constants';
//import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import './styles.scss';



class DashboardLayout extends React.PureComponent {
  render() {

    return (
      <Layout>
        <SideMenu width={layout.navBarWidth}/>

        <Layout style={{minHeight: '100vh'}}>
          <NavBar />
          {/* <CommonHeader/> */}
          {this.props.children}
          <Footer {...this.props} />
        </Layout>
      </Layout>
    );
  }
}

export default DashboardLayout;