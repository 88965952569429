import _ from 'lodash';

export const makeRequestList = (state) => {
  return _.get(state, 'request.search');
};

export const makeRequestProfile = (state) => {
  return _.get(state, 'request.profile');
};

export const makeRequestEvent = (state) => {
  return _.get(state, 'request.event');
};