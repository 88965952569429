import { connect } from 'react-redux';
import { changeEmail, changePassword } from 'providers/AuthProvider/actions';
import { SetPopup } from 'providers/PopupProvider/actions';
import { makeConfirmPasswordPopup } from 'providers/PopupProvider/selectors';
import ConfirmPasswordPopup from './ConfirmPasswordPopup';


const mapStateToProps = (state) => ({
  data: makeConfirmPasswordPopup(state)
});


const mapDispatchToProps = (dispatch) => ({
  changeEmail: (params, role) => dispatch(changeEmail(params, role)),
  changePassword: (params) => dispatch(changePassword(params)),
  closeConfirmPasswordPopup: () => dispatch(SetPopup({name: 'confirmPasswordPopup', show: false}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordPopup);