import { connect } from 'react-redux';

import { compose } from 'redux';
import {
  SetPopup
} from 'providers/PopupProvider/actions';
import { fetchEventList, deleteDraftEvent } from 'providers/EventProvider/actions';
import { makeEventList } from 'providers/EventProvider/selectors';
import { customWithRouter, withResource } from 'components/HOC';

import SearchCompanyPage from './SearchEventPage';


const mapStateToProps = (state) => ({
  listData: makeEventList(state)
});


const mapDispatchToProps = (dispatch) => ({
  openPopup: (data) => dispatch(SetPopup({name: 'confirmPopup', show: data})),
  fetchData: (params) => dispatch(fetchEventList(params)),
  deleteDraftEvent: (eventId) => dispatch(deleteDraftEvent(eventId))
});

export default compose(
  withResource(['Position']),
  connect(mapStateToProps, mapDispatchToProps),
  customWithRouter,
)(SearchCompanyPage);