import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import SearchBar from 'components/SearchBar';
import {
  Card
} from 'antd';
import _ from 'lodash';
import './styles.scss';

import { fetchRequestList } from 'providers/RequestProvider/actions';
import { makeRequestList } from 'providers/RequestProvider/selectors';
import Table from 'components/Table';
import { customWithRouter, withResource } from 'components/HOC';

import Columns from 'components/Preset/Columns';

const { FilterFields } = SearchBar;

class TabApplicant extends React.Component {
  updateSearchToState = () => {
    this.props.updateSearchToState('companySearchApplicant', 
      this.props, this.props.fetchData,
      {companyId: this.props.companyId});
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.onTableChange(pagination, filters, sorter, this.props);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  componentWillUnmount() {
    this.props.replace(this.props.pathname);
  }


  render() {
    const { total, loading, list } = this.props.listData;
    const { search } = this.props;
    const currentPage = _.has(search, 'page') ? Number(_.get(search, 'page')) : 1;

    const filterFields = [
      FilterFields.requestKeyword,
      FilterFields.position,
      FilterFields.requestedDate,
      FilterFields.requestStatus
    ];

    const columns = [
      Columns.requestSeekerAvatar,
      Columns.requestSeekerName,
      Columns.requestEventTitle,
      Columns.position,
      Columns.requestedDate,
      Columns.requestStatus,
      {
        title: 'アクション',
        key: 'Action',
        width: 220,
        fixed: 'right',
        render: (record) => (
          <Link to={`/dashboard/request/detail/${record.eventId}_${record.seekerId}`}>
            詳細を見る
          </Link>
        )
      }
    ];

    return (
      <Card>
        <div className="search-section">
          <SearchBar filterFields={filterFields}/>
        </div>
        <Table
          search={this.props.search}
          columns={columns}
          dataSource={list}
          scroll={{ x: 1000 }}
          onChange={this.onTableChange}
          pagination={{
            total: total,
            showTotal: (total, range) => `${total}中${range[0]}-${range[1]}項目を表示`,
            pageSize: 10,
            current: currentPage
          }}
          loading={loading}
        />
      </Card >
    );
  }
}

TabApplicant.propTypes = {
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func,
  updateSearchToState: PropTypes.func,
  onTableChange: PropTypes.func,
  companyId: PropTypes.string,
  location: PropTypes.object,
  listData: PropTypes.object,
  replace: PropTypes.func,
  pathname: PropTypes.string,
  search: PropTypes.object
};

const mapStateToProps = (state) => ({
  listData: makeRequestList(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (params) => dispatch(fetchRequestList(params))
});

export default compose(
  withRouter,
  customWithRouter,
  withResource(['Position']),
  connect(mapStateToProps, mapDispatchToProps),
)(TabApplicant);