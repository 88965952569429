import produce from 'immer';
import _ from 'lodash';

import {
  FETCH_COMPANY_LIST_SUCCESS,
  FETCH_COMPANY_PROFILE_SUCCESS,
  SET_LOADING_SEARCH_COMPANY,
  UNSET_COMPANY_PROFILE
} from './constants';

const initialState = {
  search: {
    list: [],
    total: 0,
    lastPage: 1,
    loading: false
  },
  profile: {

  }
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_LIST_SUCCESS:
      state.search.list = _.get(action, 'payload.data');
      state.search.total = _.get(action, 'payload.total');
      state.search.lastPage = _.get(action, 'payload.lastPage');
      return state;
    case FETCH_COMPANY_PROFILE_SUCCESS:
      state.profile = _.get(action, 'payload.profile');
      return state;
    case SET_LOADING_SEARCH_COMPANY:
      state.search.loading = _.get(action, 'payload.loading');
      return state;
    case UNSET_COMPANY_PROFILE:
      state.profile = initialState.profile;
      return state;
    default:
      return state;
  }
});

export default reducer;