import _ from 'lodash';

export const makeCompanyList = (state) => {
  return _.get(state, 'company.search');
};

export const makeCompanyProfile = (state) => {
  return _.get(state, 'company.profile');
};

export const makeCompanyPurchangedMenus = (state) => {
  return _.get(state, 'company.profile.purchangedMenus', []);
};