import _get from 'lodash/get';
import { fetchEventDetail } from 'providers/EventProvider/actions';

const asyncValidate = async(values, dispatch) => {
  if (values.eventId) {
    return dispatch(fetchEventDetail(values.eventId)).then((data) => {
      if (_get(data, 'detail.status', '') !== 'ACTIVE') {
        // eslint-disable-next-line
        throw { eventId: `${values.eventId}に該当するイベントがありません` };
      }
    });
  }
};

export default asyncValidate;
