import _ from 'lodash';
import qs from 'querystring';
import LocalStore from 'utils/localstore';
const defaultOrderBy = 'createdAt';
const defaultOrder = 'descend';

/**
 * 
 * @param {String} name 
 * @param {Object} props 
 * @param {Function} apiCall 
 * @param {Object} customParameter 
 * 
 * must be use with HOC: customWithRouter
 */
export const updateSearchToState = (name = 'commonTable', props = {}, apiCall, customParameter = {}) => {
  let { pathname, replace, search } = props;
  let reHref = false;

  if (!_.has(search, 'orderBy') || !_.has(search, 'order')) {
    const store = LocalStore.get(name) || {};
    search.orderBy = store.orderBy || defaultOrderBy;
    search.order = store.order || defaultOrder;

    reHref = true;
  }

  if (!_.isEmpty(customParameter)) {
    for (let key in customParameter) {
      if (!_.has(search, key)) {
        search[key] = customParameter[key];
        reHref = true;
      }
    }
  }
  
  if (reHref) {
    replace(`${pathname}?${qs.stringify(search)}`);
    return;
  }
  
  LocalStore.set(name, {
    orderBy: search.orderBy,
    order: search.order
  });
  
  return apiCall(search);
};

export const onTableChange = (pagination, filters, sorter, props) => {
  const { pathname, push, search } = props;
  const orderBy = sorter.columnKey;
  const order = sorter.order;
  const parsed = {
    ...search,
    page: pagination.current,
    // l: pagination.pageSize,
    orderBy,
    order
  };

  push(`${pathname}?${qs.stringify(parsed)}`);
};

export default {
  updateSearchToState,
  onTableChange
};