import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingLogo from 'components/LoadingLogo';
import LightboxGallery from 'components/LightboxGallery';
import TimeSection from './TimeSection';
import MessageCell from './MessageCell';
import MyMessageCell from './MyMessageCell';
import EmptyChat from 'assets/icons/empty-chat.svg';
import './style.scss';

function MessageList({
  eventId,
  participant,
  messageList,
  messageData,
  loadMore,
  haveNewMessage,
  isLoading,
  currentUser,
  getMoreMessage,
  readNewMessage
}) {
  const messageListRef = useRef(null);

  const onScroll = () => {
    const { current = {} } = messageListRef;
    
    if (current) {
      // Scroll bar at top and still have messages
      if (current.scrollTop === 0 && loadMore) {
        getMoreMessage(eventId);
        current.scrollTo(0, 100);
      }
    }
  };

  useEffect(() => {
    const { current = {} } = messageListRef;
    if (current) {
      // First message list
      if (messageList.length <= 10 && !isLoading) {
        current.scrollTo(0, current.scrollHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  useEffect(() => {
    const { current = {} } = messageListRef;
    const lastMessage = _.get(messageData, messageList[messageList.length - 1]);
    
    if (current) {
      if (
        // Send message
        (lastMessage && lastMessage.senderId === currentUser.objectId) || 
        // Scroll bar at near bottom
        (current.scrollHeight - current.scrollTop < 800)
      ) {
        current.scrollTo(0, current.scrollHeight);
      }
    }

    if (haveNewMessage) {
      setTimeout(() => readNewMessage(eventId), 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveNewMessage, messageListRef]);

  const renderMessage = () => {
    if (isLoading) {
      return (<div className='message-list-loading'><LoadingLogo /></div>);
    }

    if (!_.isEmpty(messageList) && !_.isEmpty(participant) && !isLoading) {
      return (
        <LightboxGallery>
          <TimeSection
            timePath='message.updatedAt'
            senderIdPath='message.senderId'
            differenceMinuteNumber={5}
            messageCellProps={{
              messageCellStyle: { paddingTop: 0 }, 
              showLess: true
            }}
          >
            {messageList.map(messageId => {
              const message = messageData[messageId];
              const senderId = _.get(message, 'senderId');
              // For lightbox
              // Children image
              const image = message.contentType === 'image' && (
                <img className='message-image' src={message.content} alt='msgImg' />
              );

              if (currentUser.objectId === senderId) {
                return (<MyMessageCell key={messageId} message={message}>{image}</MyMessageCell>);
              }
              
              return (
                <MessageCell key={messageId} message={message} sender={participant[senderId]}>{image}</MessageCell>
              );
            })}
          </TimeSection>
        </LightboxGallery>
      );
    }

    return (
      <div className='message-list-empty'>
        <img src={EmptyChat} alt='EmptyChat' />

        <div className='message-list-empty-title'>メッセージはありません</div>

        <div className='message-list-empty-text'>
          <div>このグループチャットには、メッセージはまだありません。</div>
          <div>参加者とチャットを始めましょう！</div>
        </div>
      </div>
    );
  };

  return (
    <div onScroll={onScroll} ref={messageListRef} className='message-list'>
      {renderMessage()}
    </div>
  );
}

MessageList.propTypes = {
  loadMore: PropTypes.bool,
  haveNewMessage: PropTypes.bool,
  isLoading: PropTypes.bool,
  eventId: PropTypes.string,
  messageList: PropTypes.array,
  participant: PropTypes.object,
  messageData: PropTypes.object,
  currentUser: PropTypes.object,
  getMoreMessage: PropTypes.func,
  readNewMessage: PropTypes.func
};

export default MessageList;
