import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from 'antd';
import { TextField } from 'components/Form';
import validate from './validate';
import Mail from 'assets/icons/mail.svg';
import Warning from 'assets/icons/warning.svg';

const ChangeEmailForm = props => {
  const { error, submitting, invalid, pristine } = props;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };

  return (
    <Form onSubmit={props.handleSubmit} {...formItemLayout} id='change-email-form'>
      <div className="account-management-title">
        <img src={Mail} alt='Icon' />
        <span>メールアドレスの変更</span>
      </div>

      {(props.newEmail || !props.emailVerified) && (<div className="change-email-message">
        <img src={Warning} alt='Icon' />
        <span>{props.newEmail || props.email} ユーザー未承認のメールアドレスです</span>
      </div>)}

      <Field
        name="currentEmail"
        type="text"
        component={TextField}
        label="現在のメールアドレス"
        style={{ maxWidth: 320 }}
        disabled
      />
      
      <Field
        name="newEmail"
        type="text"
        placeholder="新しいメールアドレス"
        component={TextField}
        label="新しいメールアドレス"
        style={{ maxWidth: 320 }}
      />

      <div className='account-management-button-container'>
        <button 
          type='submit' 
          className='ten-ad-btn primary-button' 
          disabled={error || submitting || invalid || pristine}
        >
          更新
        </button>
      </div>
    </Form>
  );
};

ChangeEmailForm.propTypes = {
  error: PropTypes.any,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  emailVerified: PropTypes.bool,
  email: PropTypes.string,
  handleSubmit: PropTypes.func,
  newEmail: PropTypes.string
};

export default reduxForm({ 
  form: 'changeEmail', 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ChangeEmailForm);
