import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import './style.scss';

const ConfirmPasswordPopup = props => {
  const { data, userId, role } = props;
  const { form } = data;

  const submit = async() => {
    const { values } = window.store.getState().form[form];
    const { password, newEmail, newPassword } = values;

    if (form === 'changeEmail') {
      props.changeEmail({ userId, password, newEmail }, role);
    }
    if (form === 'changePassword') {
      props.changePassword({ userId, password, newPassword });
    }
  };

  return (
    <Modal
      title='管理者パスワードを入力してください'
      visible={!!data}
      destroyOnClose={true}
      wrapClassName='confirm-password-popup'
      cancelText='取 消'
      onCancel={props.closeConfirmPasswordPopup}
      okText='送信'
      onOk={submit}
      okType='primary'
      centered
    >
      <ConfirmPasswordForm form={form} />
    </Modal>
  );
  
};

ConfirmPasswordPopup.propTypes = {
  data: PropTypes.any,
  userId: PropTypes.string,
  role: PropTypes.string,
  changeEmail: PropTypes.func,
  changePassword: PropTypes.func,
  closeConfirmPasswordPopup: PropTypes.func
};

export default ConfirmPasswordPopup;