import _ from 'lodash';

const Crumb = {
  Home: { label: 'Home', to: '/dashboard'},
  Nailist: { label: 'Nailist', to: '/dashboard/nailist'},
  NailistName: (nailistData) => ({
    label: _.get(nailistData, 'username') || 'loading',
    to: '/dashboard/nailist/' + _.get(nailistData, '_id')
  }),
  NailistSearch: { label: 'Search', to: '/dashboard/nailist/search' },
  Customer: { label: 'Customer', to: '/dashboard/customer' },
  CustomerSearch: { label: 'Search', to: '/dashboard/customer/search' },
  Booking: { label: 'Booking', to: '/dashboard/booking' },
  BookingSearch: { label: 'Search', to: '/dashboard/booking/search' },
  Payout: {label: 'Payout', to: '/dashboard/payout/search' },
  PayoutSearch: {label: 'Search', to: '/dashboard/payout/search' }
};

export default {
  dashboard: () => [
  ],
  nailist: () => [
    Crumb.Nailist
  ],
  customer: () => [
    Crumb.Customer
  ],
  customerSearch: () => [
    Crumb.Customer,
    Crumb.CustomerSearch
  ],
  booking: () => [
    Crumb.Booking
  ],
  bookingSearch: () => [
    Crumb.Booking,
    Crumb.BookingSearch
  ],
  nailistProfile: (nailistData) => [
    Crumb.Nailist,
    Crumb.NailistName(nailistData)
  ],
  nailistSearch: () => [
    Crumb.Nailist,
    Crumb.NailistSearch
  ],
  payoutSearch: () => [
    Crumb.Payout,
    Crumb.PayoutSearch
  ]
};