import {
  CLOSE_ALL_POP_UP,
  SET_POP_UP
} from './constants';

export function closeAllPopup() {
  return {type: CLOSE_ALL_POP_UP};
}

export function SetPopup(payload = { name: '', show: {}}) {
  return {type: SET_POP_UP, payload};
}