import produce from 'immer';

import {
  ADD_PLAN_REQUEST,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  REMOVE_PLAN_REQUEST,
  REMOVE_PLAN_SUCCESS,
  REMOVE_PLAN_FAILURE,
  GET_SCOUTS_REQUEST,
  GET_SCOUTS_SUCCESS,
  GET_SCOUTS_FAILURE
} from './actions';

export const initialState = {
  isLoading: false,
  total: 0,
  list: [],
  plans: [],
  totalPlan: 0
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAN_REQUEST:
      state.isLoading = true;
      return state;
    case ADD_PLAN_SUCCESS:
      state.isLoading = false;
      return state;
    case ADD_PLAN_FAILURE:
      state.isLoading = false;
      return state;
    case REMOVE_PLAN_REQUEST:
      state.isLoading = true;
      return state;
    case REMOVE_PLAN_SUCCESS:
      state.isLoading = false;
      return state;
    case REMOVE_PLAN_FAILURE:
      state.isLoading = false;
      return state;
    case GET_PLANS_REQUEST:
      state.isLoading = true;
      return state;
    case GET_PLANS_SUCCESS:
      state.isLoading = false;
      state.plans = action.payload.data;
      state.totalPlan = action.payload.total;
      return state;
    case GET_PLANS_FAILURE:
      state.isLoading = false;
      return state;
    case GET_SCOUTS_REQUEST:
      state.isLoading = true;
      return state;
    case GET_SCOUTS_SUCCESS:
      state.isLoading = false;
      state.list = action.payload.data;
      state.total = action.payload.total;
      return state;
    case GET_SCOUTS_FAILURE:
      state.isLoading = false;
      return state;
    default:
      return state;
  }
});

export default reducer;
